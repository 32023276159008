import React, { Component } from 'react';
import { Button, Table } from 'semantic-ui-react';
import { DateTime } from 'luxon';

import { db } from '../../../firebase';
import ConfirmDeleteReportModalButton from '../ConfirmDeleteReportModal';

class MonthlyQuarterlyReportsContainer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            reportSnapshots: [],
            confirmDeleteModalOpen: false,
            reportToDeleteId: null,
        }
    }

    componentDidMount() {
        this.monthlyQuarterlyReportsRef = db.getAllFromQuarterlyReportsNode();

        this.monthlyQuarterlyReportsRef
            .on('child_added', snapshot => {
                // pushing the entire snapshot into react state! WOO
                const reportSnapshots = [ snapshot, ...this.state.reportSnapshots ];

                this.setState({reportSnapshots});
            });
        this.monthlyQuarterlyReportsRef.on('child_removed', childSnapshot => {
                const reportSnapshots = [ ...this.state.reportSnapshots ].filter(report => {
                    return report.key !== childSnapshot.key;
                });

                this.setState({reportSnapshots});
            });
    }

    componentWillUnmount() {
        this.monthlyQuarterlyReportsRef.off();
    };

    handleModalCancel = () => {
        this.setState({
            confirmDeleteModalOpen: !this.state.confirmDeleteModalOpen,
            reportToDeleteId: null // clearing the report id... just in case...
        });
    };

    generateReportRows = () => {
        if (this.state.reportSnapshots.length === 0) {
            return (
                <Table.Row>
                    <Table.Cell textAlign='center' colSpan={6}>
                        Currently no spreadsheets to show...
                    </Table.Cell>
                </Table.Row>
            )
        } else {
            return this.state.reportSnapshots.map(report => {

                const { createdDateTime, reportName, reportingPeriod, description, createdBy, reportUrl } = report.val();

                return (
                    <Table.Row key={report.key}>
                        <Table.Cell>
                            {createdDateTime && DateTime.fromMillis(createdDateTime).toFormat("EEE',' LLL d y")}
                        </Table.Cell>
                        <Table.Cell>
                            {reportName || 'No Report Name!'}
                        </Table.Cell>
                        <Table.Cell>
                            {reportingPeriod || 'No Reporting Period!'}
                        </Table.Cell>
                        <Table.Cell>
                            {description || 'Description not found!'}
                        </Table.Cell>
                        <Table.Cell>
                            {createdBy || 'Created By not found!'}
                        </Table.Cell>
                        <Table.Cell>
                            <Button.Group
                                floated='right'
                            >
                                <Button
                                    color='green'
                                    icon='file excel outline'
                                    labelPosition='left'
                                    content='View'
                                    onClick={() => {
                                        window.open(reportUrl, "_blank")
                                    }}
                                />
                                <Button
                                    color='red'
                                    icon='trash'
                                    labelPosition='left'
                                    content='Delete'
                                    onClick={() => this.setState({
                                        reportToDeleteId: report.key,
                                        confirmDeleteModalOpen: true
                                    })}
                                />
                            </Button.Group>
                        </Table.Cell>
                    </Table.Row>
                )
            })
        }
    };

    render() {
        return (
            <>
                <Table striped>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Create Date</Table.HeaderCell>
                            <Table.HeaderCell>Report Name</Table.HeaderCell>
                            <Table.HeaderCell>Reporting Period</Table.HeaderCell>
                            <Table.HeaderCell>Description</Table.HeaderCell>
                            <Table.HeaderCell>Created By</Table.HeaderCell>
                            <Table.HeaderCell />
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {this.generateReportRows()}
                    </Table.Body>
                </Table>
                <ConfirmDeleteReportModalButton
                    handleCancel={this.handleModalCancel}
                    modalOpen={this.state.confirmDeleteModalOpen}
                    reportId={this.state.reportToDeleteId}
                    reportsRef={this.monthlyQuarterlyReportsRef} />
            </>
        )
    }

}
export default MonthlyQuarterlyReportsContainer
