// safespace data
const SafeSpaceFormDataModel = {
    assistanceRendered: {
        bus: 0,
        deviceCharge: 0,
        directions: 0,
        familyReconnect: 0,
        taxi: 0,
        train: 0,
    },
    createdBy: "", // id from list of registered users
    createdDate: 0,
    editedBy: "", // id from list of registered users
    editedDate: 0,
    male: {
        lessThan18: 0,
        from18to25: 0,
        from26to39: 0,
        over40: 0
    },
    female: {
        lessThan18: 0,
        from18to25: 0,
        from26to39: 0,
        over40: 0
    },
    nonBinary: {
        lessThan18: 0,
        from18to25: 0,
        from26to39: 0,
        over40: 0
    },
    schemaName: "safespace",
    schemaVersion: 1,
    site: "",
    startTime: 0,
};

export default SafeSpaceFormDataModel;