// Generic Options
import {
  ESCORTED_PHYSICALLY_REFERRED_BY_SERVICES,
  ESCORTED_PHYSICALLY_REFERRED_TO_SERVICES,
  INTOX_DRUG_AFFECTED_BY_CATEGORY,
  PERIOD_MONTHLY,
  PERIOD_QUARTERLY,
  RISK_REDUCED_DUE_TO_SK,
} from './reportConstants';

export const safeSpaceOptions = [
  { value: 'townHall', text: 'Town Hall' },
  { value: 'darlingHarbour', text: 'Darling Harbour' },
  { value: 'kingsCross', text: 'Kings Cross' },
  { value: 'theRocks', text: 'The Rocks' },
  { value: 'wollongongMall', text: 'Wollongong Mall' },
  { value: 'santaFestNGH', text: 'Santa Fest NGH' },
];

export const userLevelOptions = [
  { text: 'Team Member', value: 'teamMember' },
  { text: 'Team Leader', value: 'teamLeader' },
  { text: 'Administrator', value: 'administrator' },
];

// Client Info Options
export const genderOptions = [
  { value: 'male', label: 'Male' },
  { value: 'female', label: 'Female' },
  { value: 'nonBinary', label: 'Non-Binary' },
];

export const ageGroupOptions = [
  { value: 'lessThan18', label: '<18' },
  { value: '18to25', label: '18 - 25' },
  { value: '26to39', label: '26 - 39' },
  { value: 'over40', label: '40+' },
];

export const intoxicationSignsOptions = [
  { name: 'speech', label: 'Speech' },
  { name: 'balance', label: 'Balance' },
  { name: 'coordination', label: 'Co-ordination' },
  { name: 'behaviour', label: 'Behaviour' },
  { name: 'notVisible', label: 'Not Visible' },
];

export const observedVisibleDisclosedNotVisibleOptions = [
  { name: 'observed', label: 'Observed' },
  { name: 'disclosed', label: 'Disclosed' },
  { name: 'visibleSigns', label: 'VisibleSigns' },
  { name: 'notVisible', label: 'Not Visible' },
];

export const offensiveConductOptions = [
  { name: 'offensiveBehaviour', label: 'Offensive Behaviour' },
  { name: 'offensiveLanguage', label: 'Offensive Language' },
  { name: 'obstruction', label: 'Obstruction (Willful or Obscene)' },
  { name: 'publicDrinking', label: 'Public Place Drinking' },
  { name: 'notVisible', label: 'Not Visible' },
];

export const selfHarmSignsOptions = [
  { name: 'visibleSigns', label: 'Visible Signs' },
  { name: 'disclosed', label: 'Disclosed' },
  { name: 'notVisible', label: 'Not Visible' },
];

export const suicidalSignsOptions = [
  { name: 'ideationObserved', label: 'Ideation Observed' },
  { name: 'ideationDisclosed', label: 'Ideation Disclosed' },
  { name: 'attemptObserved', label: 'Attempt Observed' },
  { name: 'attemptDisclosed', label: 'Attempt Disclosed' },
  { name: 'notVisible', label: 'Not Visible' },
];

// Basic Support Options
export const reconnectionOptions = [
  { name: 'person', label: 'In Person' },
  { name: 'socialNetwork', label: 'Via Social Network' },
  { name: 'telephone', label: 'Via Telephone' },
];

export const directionsOptions = [
  { name: 'venue', label: 'Venue' },
  { name: 'accommodation', label: 'Accommodation' },
  { name: 'other', label: 'Other' },
];

export const transportInformationOptions = [
  { name: 'bus', label: 'Bus' },
  { name: 'train', label: 'Train' },
  { name: 'taxi', label: 'Taxi' },
  { name: 'uber', label: 'Uber' },
];

export const escortedToOptions = [
  { name: 'accommodation', label: 'Accommodation' },
  { name: 'transport', label: 'Transport' },
  { name: 'friends', label: 'Friends' },
  { name: 'other', label: 'Other' },
];

export const safeSpaceEscortOptions = [
  { name: 'escortedTo', label: 'Escorted to Safe Base' },
  { name: 'soberedUp', label: 'Sobered Up at Safe Base' },
];

// Health & Emergency Services Options

export const basicAidOptions = [
  { name: 'vomitBag', label: 'Vomit Bag' },
  { name: 'water', label: 'Water' },
  { name: 'footwear', label: 'Footwear' },
];

export const additionalAidOptions = [
  { name: 'firstAid', label: 'First Aid' },
  { name: 'mentalHealthAid', label: 'Mental Health First Aid' },
];

export const emergencyServicesCalledOptions = [
  { name: 'ambulanceServiceCalled', label: 'Ambulance Called' },
  { name: 'policeServiceCalled', label: 'Police Called ' },
  { name: 'fireServiceCalled', label: 'Fire Rescue Called' },
];

// Risk Minimization Options
export const sexualAssaultRiskOptions = [
  { value: 0, label: 'No Risk' },
  { value: 1, label: 'At Risk of Sexual Assault' },
  { value: 2, label: 'Minor Assault Risk' },
  { value: 3, label: 'Major Assault Risk' },
];

export const physicalAssaultRiskOptions = [
  { value: 0, label: 'No Risk' },
  { value: 1, label: 'At Risk of Violent Assault' },
  { value: 2, label: 'Minor Conflict De-escalated' },
  { value: 3, label: 'Major Conflict De-escalated' },
];

export const clientConsciousnessOptions = [
  { value: 0, label: 'Conscious' },
  { value: 1, label: 'Unconscious' },
  { value: 2, label: 'Asleep' },
  { value: 3, label: 'Passed Out' },
];

export const clientValuablesVisibilityOptions = [
  { value: 0, label: 'Not Visible' },
  { value: 1, label: 'Visible' },
];

export const clientLostPropertyOptions = [
  { value: 0, label: 'No Lost Property' },
  { value: 1, label: 'Valuables Lost and Found' },
  { value: 2, label: 'Valuables Lost' },
];

export const injuryRiskOptions = [
  { name: 'roadRelated', label: 'Road Related' },
  { name: 'other', label: 'Other' },
];

// Services Referred
export const clientServiceReferralsRowOne = [
  // we've had to split the Client Service Referrals options into two sets because Semantic doesn't
  // automatically reflow our checkboxes forcing us to generate two Form.Groups if we wanted
  // to show two rows of checkboxes
  {
    name: 'alcoholDrugInfoService',
    label: 'Alcohol & Drugs Information Service',
  },
  { name: 'hospital', label: 'Hospital' },
  { name: 'beyondBlue', label: 'Beyond Blue' },
  {
    name: 'childProtectionServices',
    label: 'Child Protection Services (FACS)',
  },
  { name: 'dvLine', label: 'Domestic Violence Line' },
];

export const clientServiceReferralsRowTwo = [
  { name: 'link2home', label: 'Link 2 Home' },
  { name: 'salvosStreetLevel', label: 'Salvos Street Level' },
  { name: 'streetbeatBus', label: 'StreetBeat Bus' },
  { name: 'traffickingSlaveryAFP', label: 'Trafficking & Slavery AFP' },
  { name: 'lifeline', label: 'LifeLine' },
];

export const serviceInformationOptions = [
  { name: 'contactedService', label: 'Contacted Service' },
  { name: 'infoProvided', label: 'Provided Service Information' },
];

export const otherSupportOptions = [
  { name: 'welfareCheck', label: 'Welfare Check' },
  { name: 'homelessSupport', label: 'Homeless Support' },
];

export const quarterlyReportTypeOptions = [
  {
    value: INTOX_DRUG_AFFECTED_BY_CATEGORY,
    text: 'Intox/Drug Affected by Category',
  },
  {
    value: ESCORTED_PHYSICALLY_REFERRED_TO_SERVICES,
    text: 'Escorted/Physically Referred TO Services',
  },
  {
    value: ESCORTED_PHYSICALLY_REFERRED_BY_SERVICES,
    text: 'Escorted/Physically Referred BY Services',
  },
  {
    value: RISK_REDUCED_DUE_TO_SK,
    text: 'Risk is Reduced as a Result of SK',
  },
];

export const monthsOptions = [
  { value: 1, text: 'January' },
  { value: 2, text: 'February' },
  { value: 3, text: 'March' },
  { value: 4, text: 'April' },
  { value: 5, text: 'May' },
  { value: 6, text: 'June' },
  { value: 7, text: 'July' },
  { value: 8, text: 'August' },
  { value: 9, text: 'September' },
  { value: 10, text: 'October' },
  { value: 11, text: 'November' },
  { value: 12, text: 'December' },
];

export const quarterOptions = [
  { key: 1, value: 1, text: '1st Quarter' },
  { key: 2, value: 2, text: '2nd Quarter' },
  { key: 3, value: 3, text: '3rd Quarter' },
  { key: 4, value: 4, text: '4th Quarter' },
];

export const periodTypeOptions = [
  { value: PERIOD_MONTHLY, text: 'Monthly' },
  { value: PERIOD_QUARTERLY, text: 'Quarterly' },
];

export const yearOptions = [
  { value: 2016, text: 2016 },
  { value: 2017, text: 2017 },
  { value: 2018, text: 2018 },
  { value: 2019, text: 2019 },
];

export const labelOptions =  {
  'attemptObserved': 'Attempt Observed',
  'ideationObserved': 'Ideation Observed',
  'attemptDisclosed': 'Attempt Disclosed',
  'ideationDisclosed': 'Ideation Disclosed',
  'disclosed': 'Disclosed',
  'visibleSigns': 'Signs Visible',
  'observed': 'Observed',
  'notVisible': 'Not Visible',
  'hospital': 'Hospital',
  'link2home': 'Link 2 Home',
  'salvosStreetLevel': 'Salvos Street Level',
  'streetbeatBus': 'Street Beat Bus',
  'childProtectionServices': 'Child Protection',
  'dvLine': 'Dv Line',
  'lifeline': 'Lifeline',
  'traffickingSlaveryAFP': 'Trafficking Slavery AFP',
  'beyondBlue': 'Beyond Blue',
  'alcoholDrugInfoService': 'Alcohol Drug Info',
  'mentalHealthAid': 'Mental Health Aid',
  'firstAid': 'First Aid',
  'ambulanceServiceCalled': 'Ambulance',
  'policeServiceCalled': 'Police',
  'fireServiceCalled': 'Fire',
  'soberedUp': 'Sobered Up',
  'escortedTo': 'Escorted',
  'vomitBag': 'Vomit Bag',
  'socialNetwork': 'Social Network',
  'roadRelated': 'Road Related',
  'footWear': 'Foot Wear',
  'venueSecurity': 'Venue Security',
  'tkAmbassador': 'Street Kind Ambassador',
  'fireRescue': 'Fire Rescue',
  'generalPublic': 'General Public',
  'transportStaff': 'Transport Staff',
}

