import React from 'react';
import PropTypes from 'prop-types';
import {
    Accordion,
    Divider,
    Grid,
    Header,
    Icon,
    List,
    Segment,
} from 'semantic-ui-react';
import _ from 'lodash';

import ResponsiveHorizontalList from './ResponsiveHorizontalList';
import {
    clientConsciousnessOptions,
    clientLostPropertyOptions,
    clientValuablesVisibilityOptions,
    sexualAssaultRiskOptions,
    physicalAssaultRiskOptions,
} from '../../../constants/SelectableListOptions';
import FixedValueDisplay from './FixedValueDisplay';

class ClientSegment extends React.Component {
    render() {
        const {
            accordionIndex,
            handleClick,
            clientData,
            activeIndex,
            clientKey,
        } = this.props;

        return (
            <Accordion as={Segment} color='blue' fluid>
                <Accordion.Title
                    active={activeIndex === accordionIndex}
                    index={accordionIndex}
                    onClick={handleClick}
                >
                    <Header as='h2' floated='left'>
                        <Icon name='id card' />
                        <Header.Content>
                            {_.isEmpty(_.get(clientData, 'firstName', ''))
                                ? 'Unknown'
                                : _.get(clientData, 'firstName', '')}{' '}
                            {_.isEmpty(_.get(clientData, 'lastName', ''))
                                ? 'Unknown'
                                : _.get(clientData, 'lastName', '')}
                            <Header.Subheader>
                                {_.isEmpty(_.get(clientData, 'gender', ''))
                                    ? 'No Data'
                                    : _.startCase(
                                          _.get(clientData, 'gender', '')
                                      )}
                                ,{' '}
                                {_.isEmpty(_.get(clientData, 'ageGroup', ''))
                                    ? 'No Data'
                                    : _.startCase(
                                          _.get(clientData, 'ageGroup', '')
                                      )}
                            </Header.Subheader>
                        </Header.Content>
                    </Header>
                    <Header as='h2' floated='right'>
                        <Header.Content>
                            Client Form Key
                            <Header.Subheader>{clientKey}</Header.Subheader>
                        </Header.Content>
                    </Header>
                </Accordion.Title>
                <Accordion.Content active={activeIndex === accordionIndex}>
                    <Divider clearing hidden />
                    <Grid container stackable>
                        <Grid.Row>
                            <Grid.Column>
                                <Header
                                    as='h3'
                                    icon='user'
                                    content='Client Information'
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row columns='equal'>
                            <Grid.Column>
                                <List size='large'>
                                    <List.Item>
                                        <List.Header>Email</List.Header>
                                        <List.Description>
                                            {_.isEmpty(
                                                _.get(clientData, 'email', '')
                                            )
                                                ? 'No Data'
                                                : _.get(
                                                      clientData,
                                                      'email',
                                                      ''
                                                  )}
                                        </List.Description>
                                    </List.Item>
                                </List>
                            </Grid.Column>
                            <Grid.Column>
                                <List size='large'>
                                    <List.Item>
                                        <List.Header>
                                            Contact Number
                                        </List.Header>
                                        <List.Description>
                                            {_.isEmpty(
                                                _.get(
                                                    clientData,
                                                    'contactNumber',
                                                    ''
                                                )
                                            )
                                                ? 'No Data'
                                                : _.get(
                                                      clientData,
                                                      'contactNumber',
                                                      ''
                                                  )}
                                        </List.Description>
                                    </List.Item>
                                </List>
                            </Grid.Column>
                            <Grid.Column>
                                <List size='large'>
                                    <List.Item>
                                        <List.Header>Suburb</List.Header>
                                        <List.Description>
                                            {_.isEmpty(
                                                _.get(clientData, 'suburb', '')
                                            )
                                                ? 'No Data'
                                                : _.get(
                                                      clientData,
                                                      'suburb',
                                                      ''
                                                  )}
                                        </List.Description>
                                    </List.Item>
                                </List>
                            </Grid.Column>
                            <Grid.Column>
                                <List size='large'>
                                    <List.Item>
                                        <List.Header>Alone</List.Header>
                                        <List.Description>
                                            {_.isBoolean(
                                                _.get(clientData, 'alone', '')
                                            )
                                                ? _.get(clientData, 'alone', '')
                                                    ? 'Yes'
                                                    : 'No'
                                                : 'No Data'}
                                        </List.Description>
                                    </List.Item>
                                </List>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row columns='equal'>
                            <Grid.Column>
                                <ResponsiveHorizontalList
                                    listHeaderName='Intoxication Signs'
                                    listObject={_.get(
                                        clientData,
                                        'intoxicationSigns',
                                        {}
                                    )}
                                />
                            </Grid.Column>
                            <Grid.Column>
                                <ResponsiveHorizontalList
                                    listHeaderName='Drug Use Signs'
                                    listObject={_.get(
                                        clientData,
                                        'drugUseSigns',
                                        {}
                                    )}
                                />
                            </Grid.Column>
                            <Grid.Column>
                                <ResponsiveHorizontalList
                                    listHeaderName='Offensive Conduct'
                                    listObject={_.get(
                                        clientData,
                                        'offensiveConduct',
                                        {}
                                    )}
                                />
                            </Grid.Column>
                            <Grid.Column>
                                <ResponsiveHorizontalList
                                    listHeaderName='Self Harm Signs'
                                    listObject={_.get(
                                        clientData,
                                        'selfHarmSigns',
                                        {}
                                    )}
                                />
                            </Grid.Column>
                            <Grid.Column>
                                <ResponsiveHorizontalList
                                    listHeaderName='Suicidal Signs'
                                    listObject={_.get(
                                        clientData,
                                        'suicidalSigns',
                                        {}
                                    )}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row columns='equal'>
                            <Grid.Column>
                                <ResponsiveHorizontalList
                                    listHeaderName='Sexual Assault'
                                    listObject={_.get(
                                        clientData,
                                        'sexualAssault',
                                        {}
                                    )}
                                />
                            </Grid.Column>
                            <Grid.Column>
                                <ResponsiveHorizontalList
                                    listHeaderName='Physical Assault'
                                    listObject={_.get(
                                        clientData,
                                        'physicalAssault',
                                        {}
                                    )}
                                />
                            </Grid.Column>
                            <Grid.Column>
                                <ResponsiveHorizontalList
                                    listHeaderName='Domestic Violence'
                                    listObject={_.get(
                                        clientData,
                                        'domesticViolence',
                                        {}
                                    )}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Divider />
                        <Grid.Row>
                            <Grid.Column>
                                <Header
                                    as='h3'
                                    icon='question circle'
                                    content='Basic Support'
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row columns='equal'>
                            <Grid.Column>
                                <ResponsiveHorizontalList
                                    listHeaderName='Reconnection'
                                    listObject={_.get(
                                        clientData,
                                        'reconnection',
                                        {}
                                    )}
                                />
                            </Grid.Column>
                            <Grid.Column>
                                <ResponsiveHorizontalList
                                    listHeaderName='Directions'
                                    listObject={_.get(
                                        clientData,
                                        'directions',
                                        {}
                                    )}
                                />
                            </Grid.Column>
                            <Grid.Column>
                                <ResponsiveHorizontalList
                                    listHeaderName='Transport Information'
                                    listObject={_.get(
                                        clientData,
                                        'transportInformation',
                                        {}
                                    )}
                                />
                            </Grid.Column>
                            <Grid.Column>
                                <ResponsiveHorizontalList
                                    listHeaderName='Escort'
                                    listObject={_.get(
                                        clientData,
                                        'escortedTo',
                                        {}
                                    )}
                                />
                            </Grid.Column>
                            <Grid.Column>
                                <ResponsiveHorizontalList
                                    listHeaderName='Safe Base'
                                    listObject={_.get(
                                        clientData,
                                        'safeSpace',
                                        {}
                                    )}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Divider />
                        <Grid.Row>
                            <Grid.Column>
                                <Header
                                    as='h3'
                                    icon='first aid'
                                    content='Health Support'
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row columns='equal'>
                            <Grid.Column>
                                <ResponsiveHorizontalList
                                    listHeaderName='Basic Aid'
                                    listObject={_.get(
                                        clientData,
                                        'basicAid',
                                        {}
                                    )}
                                />
                            </Grid.Column>
                            <Grid.Column>
                                <ResponsiveHorizontalList
                                    listHeaderName='Additional Aid'
                                    listObject={_.get(
                                        clientData,
                                        'additionalAid',
                                        {}
                                    )}
                                />
                            </Grid.Column>
                            <Grid.Column>
                                <ResponsiveHorizontalList
                                    listHeaderName='Emergency Services'
                                    listObject={_.get(
                                        clientData,
                                        'emergencyServicesCalled',
                                        {}
                                    )}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Divider />
                        <Grid.Row>
                            <Grid.Column>
                                <Header
                                    as='h3'
                                    icon='exclamation triangle'
                                    content='Risk Minimization'
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row columns='equal'>
                            <Grid.Column>
                                <FixedValueDisplay
                                    headerElement='Physical Assault Risk'
                                    value={_.get(
                                        clientData,
                                        'physicalAssaultRisk',
                                        {}
                                    )}
                                    valueList={physicalAssaultRiskOptions}
                                />
                            </Grid.Column>
                            <Grid.Column>
                                <FixedValueDisplay
                                    headerElement='Sexual Assault Risk'
                                    value={_.get(
                                        clientData,
                                        'sexualAssaultRisk',
                                        {}
                                    )}
                                    valueList={sexualAssaultRiskOptions}
                                />
                            </Grid.Column>
                            <Grid.Column>
                                <ResponsiveHorizontalList
                                    listHeaderName='Injury Risk'
                                    listObject={_.get(clientData, 'injury', {})}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <Header as='h4' content='Theft Risk' />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row columns='equal'>
                            <Grid.Column>
                                <FixedValueDisplay
                                    headerElement='Client Consciousness'
                                    value={_.get(
                                        clientData,
                                        'clientConsciousness',
                                        {}
                                    )}
                                    valueList={clientConsciousnessOptions}
                                />
                            </Grid.Column>
                            <Grid.Column>
                                <FixedValueDisplay
                                    headerElement='Client Valuables Visibility'
                                    value={_.get(
                                        clientData,
                                        'clientValuablesVisibility',
                                        {}
                                    )}
                                    valueList={clientValuablesVisibilityOptions}
                                />
                            </Grid.Column>
                            <Grid.Column>
                                <FixedValueDisplay
                                    headerElement='Client Lost Property'
                                    value={_.get(
                                        clientData,
                                        'clientLostProperty',
                                        {}
                                    )}
                                    valueList={clientLostPropertyOptions}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Divider />
                        <Grid.Row>
                            <Grid.Column>
                                <Header
                                    as='h3'
                                    icon='call square'
                                    content='Services Referred'
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row columns='equal'>
                            <Grid.Column>
                                <ResponsiveHorizontalList
                                    listHeaderName='Client Service Referrals'
                                    listObject={_.get(
                                        clientData,
                                        'clientServiceReferrals',
                                        {}
                                    )}
                                />
                            </Grid.Column>
                            <Grid.Column>
                                <ResponsiveHorizontalList
                                    listHeaderName='Service Information'
                                    listObject={_.get(
                                        clientData,
                                        'serviceInformation',
                                        {}
                                    )}
                                />
                            </Grid.Column>
                            <Grid.Column>
                                <ResponsiveHorizontalList
                                    listHeaderName='Other Support'
                                    listObject={_.get(
                                        clientData,
                                        'otherSupport',
                                        {}
                                    )}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Accordion.Content>
            </Accordion>
        );
    }
}

ClientSegment.propTypes = {
    clientData: PropTypes.object.isRequired,
    handleClick: PropTypes.func.isRequired,
    accordionIndex: PropTypes.number.isRequired,
    activeIndex: PropTypes.number.isRequired,
};

export default ClientSegment;
