import React, { Component } from 'react';
import { Button, Grid, Header } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import _ from 'lodash';

import { db } from '../../firebase/index';
import * as actions from '../../actions/index';
import AssistanceRenderedComponent from './AssistanceRenderedComponent';
import PeopleCountComponent from './PeopleCountComponent';
import LoadingScreen from 'react-loading-screen';

class SafeSpace extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mergedSafeSpaceFormValues: {},
      isLoading: false,
    };
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    this.safeSpaceFormReference = db.getRefOfSafeSpaceFormsNode();

    // get current time

    // queryStartTime = DateTime.fromObject({day: 5, month: 4, year: 2018, hour: 6, minute: 0}); // custom time setting's for testing!
    // queryEndTime = DateTime.fromObject({day: 12, month: 4, year: 2018, hour: 6, minute: 0}); // custom time setting's for testing!

    // prep an object to hold all merged safeSpaceForm numbers
    let mergedSafeSpaceFormValues = {};
    this.safeSpaceFormReference
      .orderByChild('startTime')
      // ideally it should fetch between 6pm the current day and 6am the following day
      .on('child_added', (snapshot) => {
        // if the returned snapshot is for the relevant site...
        if (_.get(snapshot.val(), 'site', '') === this.props.profile.site) {
          // merge the snapshot with mergedSafeSpaceFormValues
          mergedSafeSpaceFormValues = _.mergeWith(
            mergedSafeSpaceFormValues,
            snapshot.val(),
            this.merger
          );
        }
        this.setState({
          mergedSafeSpaceFormValues: mergedSafeSpaceFormValues,
          isLoading: false,
        });
      });
  }

  componentWillUnmount() {
    this.safeSpaceFormReference.off();
  }

  merger = (a, b) => {
    if (typeof a === typeof b) {
      if (typeof a === 'number') {
        return a + b;
      } else if (typeof a === 'object') {
        return _.mergeWith(Object.assign({}, a), b, this.merger);
      }
    }
    return b;
  };

  render() {
    return (
      <>
        <LoadingScreen
          loading={this.state.isLoading}
          spinnerColor="#9ee5f8"
          textColor="#676767"
          text="Loading..."
        >
          <Grid container>
            <Grid.Row>
              <Header as="h1" className="sectionHeader">
                {this.props.profile && _.startCase(this.props.profile.site)}{' '}
                Safe Base
              </Header>
            </Grid.Row>
            <Header as="h2">People Count</Header>
            <PeopleCountComponent
              headerContent="Male"
              iconColour="blue"
              iconName="mars"
              segmentColour="blue"
              values={_.get(this.state.mergedSafeSpaceFormValues, 'male', {})}
            />
            <PeopleCountComponent
              headerContent="Female"
              iconColour="pink"
              iconName="venus"
              segmentColour="pink"
              values={_.get(this.state.mergedSafeSpaceFormValues, 'female', {})}
            />
            <PeopleCountComponent
              headerContent="Non-Binary"
              iconColour="violet"
              iconName="genderless"
              segmentColour="violet"
              values={_.get(
                this.state.mergedSafeSpaceFormValues,
                'nonBinary',
                {}
              )}
            />
            <Header as="h2">Assistance Rendered</Header>
            <Grid.Row centered>
              <AssistanceRenderedComponent
                values={_.get(
                  this.state.mergedSafeSpaceFormValues,
                  'assistanceRendered',
                  {}
                )}
              />
            </Grid.Row>
            <Grid.Row centered>
              <Button
                size="huge"
                primary
                content="Update SafeBase"
                as={Link}
                to={{ pathname: '/safebase/new' }}
              />
            </Grid.Row>
            <Grid.Row />
          </Grid>
        </LoadingScreen>
      </>
    );
  }
}

const mapStateToProps = ({ auth, profile }) => ({
  auth,
  profile,
});

export default connect(mapStateToProps, actions)(SafeSpace);
