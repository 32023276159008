import React, { Component } from 'react';
import { Divider, Form, Grid, Header } from 'semantic-ui-react';
import { Field as ReduxField } from 'redux-form';
import { NewSemanticReduxFormCheckbox } from '../../../utilities/SemanticReduxFormField';
import ActionButtons from './ActionButtons';
import { withReduxForm } from './with-redux-form';
import {
  clientServiceReferralsRowOne,
  clientServiceReferralsRowTwo,
  otherSupportOptions,
  serviceInformationOptions,
} from '../../../constants/SelectableListOptions';

class ServicesReferredForm extends Component {
  changeFormSection = (bubbleUpValue) => {
    this.props.changeFormSection(bubbleUpValue);
  };

  cancelForm = () => {
    this.props.cancelForm();
  };

  render() {
    const { handleSubmit } = this.props;

    return (
      <Grid container>
        <Grid.Row>
          <Header as="h2" content="Services Referred" />
          <Form
            onSubmit={handleSubmit}
            size="large"
            className="incidentFormSegment"
          >
            <ReduxField
              name="clientServiceReferrals"
              component={NewSemanticReduxFormCheckbox}
              fieldLabel="Client Service Referrals"
              requiredAsterisk={false}
              grouped={false}
              widths={5}
              checkboxOptions={clientServiceReferralsRowOne}
              secondRowOptions={clientServiceReferralsRowTwo}
            />
            <Divider />
            <ReduxField
              name="serviceInformation"
              component={NewSemanticReduxFormCheckbox}
              fieldLabel="Service Information"
              requiredAsterisk={false}
              grouped={false}
              widths={5}
              checkboxOptions={serviceInformationOptions}
            />
            <Divider />
            <ReduxField
              name="otherSupport"
              component={NewSemanticReduxFormCheckbox}
              fieldLabel="Other Support"
              requiredAsterisk={false}
              grouped={false}
              widths={5}
              checkboxOptions={otherSupportOptions}
            />
            <Divider />

            <ActionButtons {...this.props} />
          </Form>
        </Grid.Row>
        <Grid.Row
        // Padding Row othewise bottom elements (buttons, tables) have no padding on mobile responsive mode
        />
      </Grid>
    );
  }
}

export default withReduxForm(ServicesReferredForm);
