import React from 'react';
import { Field as ReduxField, reduxForm, SubmissionError } from 'redux-form';
import {
    Button,
    Grid,
    Segment,
    Form,
    Header,
    Icon,
    Image,
    Message,
} from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import streetKindLogo from '../../assets/street-kind-logo-black.svg';
import * as actions from '../../actions';
import { SemanticReduxFormInputField } from '../../utilities/SemanticReduxFormField';

class Login extends React.Component {
    loginSubmit = async (formValues) => {
        // console.log(formValues);
        try {
            await this.props.signInWithEmailAndPassword(formValues);
        } catch (error) {
            // this error is thrown by the action creator failing to log in
            throw new SubmissionError({
                // SubmissionError object is from redux-form
                // _error is for generic errors, if you want to target specific fields
                // you need to key the SubmissionError object by field names i.e. { email: 'Can't login with this email' }
                _error: error,
            });
        }
    };

    render() {
        const { handleSubmit, error, submitting } = this.props; // comes from reduxForm

        return (
            <Grid
                className='loginGrid'
                container
                centered
                verticalAlign='middle'
            >
                <Grid.Column mobile={16} tablet={12} computer={6}>
                    <Segment padded color='blue' raised>
                        <Image src={streetKindLogo} centered size='medium' />
                        <Header as='h2' textAlign='center'>
                            Safe Base & Incident Report Login
                        </Header>
                        {error && <Message error content={error.message} />}
                        <Form onSubmit={handleSubmit(this.loginSubmit)}>
                            <ReduxField
                                name='email'
                                component={SemanticReduxFormInputField}
                                // validate={[required]}
                                fieldLabel='Email:'
                                placeholder='Email Address'
                                type='text'
                                requiredField={true}
                            />
                            <ReduxField
                                name='password'
                                component={SemanticReduxFormInputField}
                                // validate={[required]}
                                fieldLabel='Password:'
                                placeholder='Password'
                                type='password'
                                requiredField={true}
                            />
                            <Grid>
                                <Grid.Row columns={2}>
                                    <Grid.Column verticalAlign='middle'>
                                        <Link
                                            className='forgotPasswordLink'
                                            to='/forgotPassword'
                                        >
                                            Forgot Password?
                                        </Link>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Button
                                            icon
                                            floated='right'
                                            labelPosition='right'
                                            color='green'
                                            type='submit'
                                            disabled={submitting}
                                            loading={submitting}
                                        >
                                            Login
                                            <Icon name='sign in' />
                                        </Button>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Form>
                    </Segment>
                </Grid.Column>
            </Grid>
        );
    }
}

export default connect(null, actions)(reduxForm({ form: 'loginForm' })(Login));
