import {PROFILE_CLEAR, PROFILE_UPDATED} from '../actions/types';

export default function(state = null, action) {
    switch(action.type) {
        case PROFILE_UPDATED:
            return action.payload || null;
        case PROFILE_CLEAR:
            return null;
        default:
            return state;
    }
}