import React from 'react';
import { connect } from 'react-redux';
import { DateTime } from 'luxon';
import { initialize as initializeFormAction } from 'redux-form';

import { db } from '../../firebase/index';
import SafeSpaceFormDataModel from '../../Models/safespace';
import SafeSpaceForm from './SafeSpaceForm';

class SafeSpaceNew extends React.Component {
    componentDidMount() {
        const initializeSafeSpaceFormDataModel = () => {
            let safeSpaceFormModel = SafeSpaceFormDataModel;
            safeSpaceFormModel = {
                ...safeSpaceFormModel,
                createdBy: this.props.auth.uid,
                createdDate: DateTime.local().valueOf(),
                site: this.props.profile.site,
                startTime: DateTime.local().valueOf(),
            };
            return safeSpaceFormModel;
        };
        this.props.initializeFormAction(
            'safeSpaceForm',
            initializeSafeSpaceFormDataModel()
        );
    }

    submitSafeSpaceForm = async (data) => {
        console.log('Submit Data: ');
        console.log(data);
        try {
            // save safeSpaceForm data to safeSpaceForms/ node
            await db.getRefOfSafeSpaceFormsNode().push(data);
            // update the lastActive of the user
            await db
                .getLastActiveFromUsersNodeWithUserUID(this.props.auth.uid)
                .set(DateTime.local().valueOf());
            // if successful, push browser back to /safebase url
            this.props.history.push('/safebase');
        } catch (error) {
            console.log(
                'An error has occured submitting safebase form!',
                error
            );
        }
    };

    render() {
        return (
            <SafeSpaceForm
                onSubmit={this.submitSafeSpaceForm}
                site={this.props.profile.site}
            />
        );
    }
}

const mapStateToProps = ({ auth, profile }) => {
    return {
        auth,
        profile,
    };
};

export default connect(mapStateToProps, {
    initializeFormAction: initializeFormAction,
})(SafeSpaceNew);
