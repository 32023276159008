export const PAGINATE_UPDATE = 'paginate_update';
export const UPDATE_OLD_ID = 'update_old_id';
export const UPDATE_NEW_ID = 'udpate_new_id';

export const updatePaginate = (updatedPaginateData) => {
  return {
    type: PAGINATE_UPDATE,
    payload: updatedPaginateData,
  };
};
