import React from 'react';
import { Grid, Card, List } from 'semantic-ui-react';
import _ from 'lodash';
import PropTypes from 'prop-types';
class SocialServicesCardList extends React.Component {
  generateSocialServicesCards = (socialServicesList) => {
    return _.map(socialServicesList, (value, key) => {
      const {
        serviceDisplayName,
        serviceLogoUrl,
        serviceDescription,
        serviceContactNumber,
        serviceContactHours,
        serviceAddress,
      } = value;

      return (
        <Card
          key={key}
          image={serviceLogoUrl}
          header={serviceDisplayName}
          meta={serviceContactHours}
          description={serviceDescription}
          color="blue"
          extra={
            <List>
              <List.Item>
                <List.Icon name="call" color="green" />
                {serviceContactNumber}
              </List.Item>
              {!_.isEmpty(serviceAddress) ? (
                <List.Item>
                  <List.Icon name="marker" color="red" />
                  {serviceAddress}
                </List.Item>
              ) : (
                ''
              )}
            </List>
          }
        />
      );
    });
  };

  render() {
    return (
      <Grid.Row as={Card.Group} doubling stackable itemsPerRow={4}>
        {this.generateSocialServicesCards(this.props.socialServicesList)}
      </Grid.Row>
    );
  }
}

SocialServicesCardList.propTypes = {
  socialServicesList: PropTypes.object.isRequired,
};

export default SocialServicesCardList;
