import {
    AUTH_USER_UPDATED,
    AUTH_USER_CLEAR,
    PROFILE_UPDATED,
    PROFILE_CLEAR
} from './types';
import { auth, db } from '../firebase';

export const updateAuthProfile = (authUser) => async dispatch => {
    const profile = await db.getProfileFromUsersNodeWithUid(authUser.uid);
    dispatch({ type: PROFILE_UPDATED, payload: profile });
    dispatch({ type: AUTH_USER_UPDATED, payload: authUser });
};

export const clearAuthProfile = () => dispatch => {
    dispatch({ type: AUTH_USER_CLEAR, payload: null });
    dispatch({ type: PROFILE_CLEAR, payload: null });
};

export const signInWithEmailAndPassword = ({ email, password }) => async dispatch => {
    try {
        await auth.signInWithEmailAndPassword(email, password);

        // dispatch({type: USER_UPDATED, payload: authUser})
    } catch (error) { // if  sign in fails, firebase will return an error in format of { code: '...', message: '...' }
        // console.log("Error signing in!", error);
        throw error; // this thrown error will be caught by the loginSubmit() method in Login/index.js
    }
};

export const logout = () => async dispatch => {
    try {
        await auth.signOut();
    } catch (error) {
        console.log("Unable to sign out!", error);
    }
};

export const updateProfile = (uid) => async dispatch => {
    try {
        const profile = await db.getProfileFromUsersNodeWithUid(uid);

        dispatch({ type: PROFILE_UPDATED, payload: profile })
    } catch (error) {
        console.log("Error loading profile!", error);
    }
};
