import React from 'react';
import { Button, Card, Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { DateTime } from 'luxon';
import * as db from '../../firebase/db';
import _ from 'lodash';

const ClientCard = (props) => {
  const { firstName, lastName, ageGroup, gender } =
    props.clientDetails.clientData;

  const handleCopyClient = async (clientData) => {
    let duplicateClientData = { ...clientData };
    duplicateClientData.createdDate = DateTime.local().valueOf();
    await db.getClientListRefFromClientNode().push(duplicateClientData);
  };

  return (
    <Card style={{ width: 'auto' }}>
      <Card.Content>
        <Card.Header>
          {_.isEmpty(firstName)
            ? gender === 'male'
              ? 'John'
              : 'Jane'
            : firstName}{' '}
          {_.isEmpty(lastName) ? 'Doe' : lastName}
        </Card.Header>
        <Card.Meta>
          {_.startCase(gender)}, {_.startCase(ageGroup)}
        </Card.Meta>
      </Card.Content>
      <Card.Content extra>
        <Button.Group>
          <Button animated='vertical' color='blue'>
            <Button.Content visible>
              <Icon name='eye' />
            </Button.Content>
            <Button.Content hidden>View</Button.Content>
          </Button>
          <Button
            animated='vertical'
            color='yellow'
            as={Link}
            to={{
              pathname: '/client/edit',
              state: {
                clientId: props.clientDetails.clientKey,
                incidentId: props.incidentId,
              },
            }}
          >
            <Button.Content visible>
              <Icon name='pencil' />
            </Button.Content>
            <Button.Content hidden>Edit</Button.Content>
          </Button>
          <Button
            animated='vertical'
            color='red'
            type='button' // needed because react thinks this is a submit if not put on there
            onClick={() => {
              props.openConfirmDeleteModal(props.clientDetails.clientKey);
            }}
          >
            <Button.Content visible>
              <Icon name='trash' />
            </Button.Content>
            <Button.Content hidden>Delete</Button.Content>
          </Button>
          <Button
            animated='vertical'
            color='green'
            type='button'
            onClick={async () =>
              await handleCopyClient(props.clientDetails.clientData)
            }
          >
            <Button.Content visible>
              <Icon name='copy' />
            </Button.Content>
            <Button.Content hidden>Copy</Button.Content>
          </Button>
        </Button.Group>
      </Card.Content>
    </Card>
  );
};

ClientCard.propTypes = {
  clientDetails: PropTypes.object.isRequired,
  incidentId: PropTypes.string.isRequired,
  openConfirmDeleteModal: PropTypes.func.isRequired,
};

export default ClientCard;
