import React from 'react';
import { Divider, Container, Header } from 'semantic-ui-react';

import QuickForm from './QuickForm/QuickFormComponent';
import DraftForms from './DraftForms';

const MyForms = () => {

    return (
        <Container>
            <Header as='h1' className='sectionHeader'>My Forms</Header>
            <QuickForm />
            <Divider hidden clearing/>
            <Divider clearing/>
            <DraftForms />
            <Divider hidden clearing/>
        </Container>
    )
};

export default MyForms
