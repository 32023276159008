import _ from 'lodash'
// these functions are used by redux-form to transform values prior to storing

// here the booleanNormalization function is mainly applied to radio buttons that are "yes/no" with values of
//   "true/false" (strings) will be transformed to "true/false" booleans prior to being submitted
export const booleanNormalization = value => {
    if (value === 'true') {
        return true;
    }
    if (value === 'false') {
        return false;
    }

    return value;
};
export const numberNormalization = value => {
    return _.toNumber(value);
};