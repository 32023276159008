// node_module libraries
import React from 'react'
import {Button, Responsive} from 'semantic-ui-react'

const ActionButtons = ({ nextSection, prevSection, cancelForm, onSubmit, onSubmitDraft, submitting, changeFormSection }) => {
    let buttons = [];

    if (cancelForm) {
        buttons.push(
            <div key={0}>
                <Responsive minWidth={320} maxWidth={767}>
                    <Button
                        type='button'
                        floated='left'
                        color='red'
                        icon='undo alternate'
                        size='large'
                        disable={submitting}
                        onClick={() => cancelForm()}
                    />
                </Responsive>
                <Responsive minWidth={768}>
                    <Button
                        type='button'
                        floated='left'
                        color='red'
                        icon='undo alternate'
                        labelPosition='left'
                        content='Cancel'
                        size='large'
                        disabled={submitting}
                        onClick={() => cancelForm()}
                    />
                </Responsive>
            </div>
        )
    }

    if (prevSection) {
        buttons.push(
            <div key={1}>
                <Responsive minWidth={320} maxWidth={767}>
                    <Button
                        type='button'
                        floated='left'
                        color='blue'
                        icon='left arrow'
                        size='large'
                        disabled={submitting}
                        onClick={() => changeFormSection(prevSection)}
                    />
                </Responsive>
                <Responsive minWidth={768}>
                    <Button
                        type='button'
                        floated='left'
                        color='blue'
                        icon='left arrow'
                        labelPosition='left'
                        content='Previous'
                        size='large'
                        disabled={submitting}
                        onClick={() => changeFormSection(prevSection)}
                        />
                </Responsive>
            </div>
        )
    }
    if (nextSection) {
        buttons.push(
            <div key={2}>
                <Responsive minWidth={320} maxWidth={767}>
                    <Button
                        // 'type' prop required to disable browser form warning:
                        //     'Form submission cancelled because the form is not connected'
                        // See: https://github.com/erikras/redux-form/issues/2679
                        type='button'
                        floated='left'
                        color='blue'
                        icon='right arrow'
                        size='large'
                        disabled={submitting}
                        onClick={() => changeFormSection(nextSection)}
                    />
                </Responsive>
                <Responsive minWidth={768}>
                    <Button
                        // 'type' prop required to disable browser form warning:
                        //     'Form submission cancelled because the form is not connected'
                        // See: https://github.com/erikras/redux-form/issues/2679
                        type='button'
                        floated='left'
                        color='blue'
                        icon='right arrow'
                        labelPosition='right'
                        size='large'
                        content='Next'
                        disabled={submitting}
                        onClick={() => changeFormSection(nextSection)}
                    />
                </Responsive>
            </div>
        )
    }

    if (onSubmit) {
        buttons.push(
            <div key={3}>
                <Responsive minWidth={320} maxWidth={767}>
                    <Button
                        floated='right'
                        color='green'
                        icon='save'
                        size='large'
                        disabled={submitting}
                        loading={submitting}
                    />
                </Responsive>
                <Responsive minWidth={768}>
                    <Button
                        floated='right'
                        color='green'
                        icon='save'
                        labelPosition='left'
                        content='Submit Form'
                        size='large'
                        disabled={submitting}
                        loading={submitting}
                    />
                </Responsive>
            </div>
        )
    }

    if (onSubmitDraft) {
        buttons.push(
            <div key={4}>
                <Responsive minWidth={320} maxWidth={767}>
                    <Button
                        floated='right'
                        color='grey'
                        icon='edit'
                        size='large'
                        disabled={submitting}
                        loading={submitting}
                    />
                </Responsive>
                <Responsive minWidth={768}>
                    <Button
                        floated='right'
                        color='grey'
                        icon='edit'
                        labelPosition='left'
                        content='Save Draft'
                        size='large'
                        disabled={submitting}
                        loading={submitting}
                    />
                </Responsive>
            </div>
        )
    }

    return buttons
};

export default ActionButtons