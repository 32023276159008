import {PERIOD_MONTHLY, PERIOD_QUARTERLY} from "../constants/reportConstants";
import { DateTime } from 'luxon';

export default (values) => {

    // these values are for checking if whether the combined fields of reportingTypes (Monthly/Quarterly)
    // and reportingPeriod (in either months or quarters) are within valid combinations.
    const currentMaxQuarter = DateTime.local().quarter;
    const currentMaxMonth = DateTime.local().month;
    const currentYear = DateTime.local().year;

    const errors = {};

    if (!values.reportType) {
        errors.reportType = 'Required'
    }
    if (!values.periodType) {
        errors.periodType = 'Required'
    }
    if (!values.year) {
        errors.year = 'Required'
    }
    if (!values.reportingPeriod) {
        errors.reportingPeriod = 'Required'
    }
    if (values.periodType === PERIOD_QUARTERLY && values.reportingPeriod > 4) {
        errors.reportingPeriod = "Please Select Valid Reporting Period"
    }
    // if it is the current year and the periodType is "quarterly",
    // ensure that the reportingPeriod field does not exceed the max quarters
    if (values.year === currentYear) {
        if (values.periodType === PERIOD_QUARTERLY && values.reportingPeriod >= currentMaxQuarter) {
            errors.reportingPeriod = "Please Select Valid Reporting Quarter"
        } else if (values.periodType === PERIOD_MONTHLY && values.reportingPeriod >= currentMaxMonth) {
            errors.reportingPeriod = "Please Select Valid Reporting Month"
        }
    }

    return errors;
}