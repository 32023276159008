import React from 'react';
import { Field as ReduxField, reduxForm } from 'redux-form';
import {
    Button,
    Container,
    Form,
    Grid,
    Header,
    Icon,
    Segment,
} from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import _ from 'lodash';

import {
    SemanticReduxFormDateInput,
    SemanticReduxFormNumberField,
} from '../../utilities/SemanticReduxFormField';

class SafeSpaceForm extends React.Component {
    render() {
        const { handleSubmit, pristine, submitting, reset } = this.props;
        return (
            <Container>
                <Header as='h1' textAlign='center'>
                    Update for {_.startCase(this.props.site)} SafeBase
                </Header>
                <Form onSubmit={handleSubmit}>
                    <Header as='h2'>Start Time</Header>
                    <ReduxField
                        name='startTime'
                        component={SemanticReduxFormDateInput}
                        fieldLabel=''
                        enableTime={true}
                        displayFormat='F J, Y - H:i'
                    />
                    <Header as='h2'>Number of People Entering</Header>
                    <Segment color='blue'>
                        <Header as='h3'>
                            <Icon name='mars' color='blue' />
                            <Header.Content>Male</Header.Content>
                        </Header>
                        <Grid divided stackable>
                            <Grid.Row>
                                <ReduxField
                                    name='male.lessThan18'
                                    component={SemanticReduxFormNumberField}
                                    fieldLabel='Less Than 18'
                                    basicSegment={true}
                                />
                                <ReduxField
                                    name='male.from18to25'
                                    component={SemanticReduxFormNumberField}
                                    fieldLabel='18 to 25'
                                    basicSegment={true}
                                />
                                <ReduxField
                                    name='male.from26to39'
                                    component={SemanticReduxFormNumberField}
                                    fieldLabel='26 to 39'
                                    basicSegment={true}
                                />
                                <ReduxField
                                    name='male.over40'
                                    component={SemanticReduxFormNumberField}
                                    fieldLabel='Over 40'
                                    basicSegment={true}
                                />
                            </Grid.Row>
                        </Grid>
                    </Segment>
                    <Segment color='pink'>
                        <Header as='h3'>
                            <Icon name='venus' color='pink' />
                            <Header.Content>Female</Header.Content>
                        </Header>
                        <Grid divided stackable>
                            <Grid.Row>
                                <ReduxField
                                    name='female.lessThan18'
                                    component={SemanticReduxFormNumberField}
                                    fieldLabel='Less Than 18'
                                    basicSegment={true}
                                />
                                <ReduxField
                                    name='female.from18to25'
                                    component={SemanticReduxFormNumberField}
                                    fieldLabel='18 to 25'
                                    basicSegment={true}
                                />
                                <ReduxField
                                    name='female.from26to39'
                                    component={SemanticReduxFormNumberField}
                                    fieldLabel='26 to 39'
                                    basicSegment={true}
                                />
                                <ReduxField
                                    name='female.over40'
                                    component={SemanticReduxFormNumberField}
                                    fieldLabel='Over 40'
                                    basicSegment={true}
                                />
                            </Grid.Row>
                        </Grid>
                    </Segment>
                    <Segment color='purple'>
                        <Header as='h3'>
                            <Icon name='genderless' color='violet' />
                            <Header.Content>Non-Binary</Header.Content>
                        </Header>
                        <Grid divided stackable>
                            <Grid.Row>
                                <ReduxField
                                    name='nonBinary.lessThan18'
                                    component={SemanticReduxFormNumberField}
                                    fieldLabel='Less Than 18'
                                    basicSegment={true}
                                />
                                <ReduxField
                                    name='nonBinary.from18to25'
                                    component={SemanticReduxFormNumberField}
                                    fieldLabel='18 to 25'
                                    basicSegment={true}
                                />
                                <ReduxField
                                    name='nonBinary.from26to39'
                                    component={SemanticReduxFormNumberField}
                                    fieldLabel='26 to 39'
                                    basicSegment={true}
                                />
                                <ReduxField
                                    name='nonBinary.over40'
                                    component={SemanticReduxFormNumberField}
                                    fieldLabel='Over 40'
                                    basicSegment={true}
                                />
                            </Grid.Row>
                        </Grid>
                    </Segment>
                    <Header as='h2'>Amount of Assistance Rendered</Header>
                    <Grid container stackable centered>
                        <ReduxField
                            name='assistanceRendered.directions'
                            component={SemanticReduxFormNumberField}
                            fieldLabel='Requested Directions'
                            color='green'
                            icon='map outline'
                        />
                        <ReduxField
                            name='assistanceRendered.bus'
                            component={SemanticReduxFormNumberField}
                            fieldLabel='Bus Information'
                            color='green'
                            icon='bus'
                        />
                        <ReduxField
                            name='assistanceRendered.train'
                            component={SemanticReduxFormNumberField}
                            fieldLabel='Train Information'
                            color='green'
                            icon='train'
                        />
                        <ReduxField
                            name='assistanceRendered.taxi'
                            component={SemanticReduxFormNumberField}
                            fieldLabel='Taxi Information'
                            color='green'
                            icon='taxi'
                        />
                        <ReduxField
                            name='assistanceRendered.deviceCharge'
                            component={SemanticReduxFormNumberField}
                            fieldLabel='Charged Devices'
                            color='green'
                            icon='lightning'
                        />
                        <ReduxField
                            name='assistanceRendered.familyReconnect'
                            component={SemanticReduxFormNumberField}
                            fieldLabel='Family Reconnect'
                            color='green'
                            icon='phone'
                        />
                    </Grid>
                    <Grid container>
                        <Grid.Row centered>
                            <Button
                                size='huge'
                                color='black'
                                content='Back to My SafeBase'
                                as={Link}
                                to={{ pathname: '/safebase' }}
                                disabled={submitting}
                            />
                            <Button
                                size='huge'
                                type='submit'
                                color='green'
                                content='Add to SafeBase'
                                disabled={pristine || submitting}
                            />
                            <Button
                                size='huge'
                                type='button'
                                color='red'
                                content='Clear'
                                onClick={reset}
                            />
                        </Grid.Row>
                        <Grid.Row />
                    </Grid>
                </Form>
            </Container>
        );
    }
}
export default reduxForm({
    form: 'safeSpaceForm',
})(SafeSpaceForm);
