import React from 'react';
import PropTypes from 'prop-types';
import {Grid, Header} from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import IncidentSectionSwitch from './IncidentSectionSwitch';
import { IncidentSections } from './IncidentSectionsEnum';
import ClientFormComponent from './ClientFormComponent';
import { getFormSyncErrors, initialize as initializeAction } from 'redux-form';

class ClientFormContainer extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            activeSection: IncidentSections.CLIENT
        }
    }

    changeFormSection = (section) => {
        this.setState({
            activeSection: section
        })
    };

    render() {
        const { activeSection } = this.state;

        return(
            <Grid container>
                <Grid.Row>
                    <Header as='h1' className='sectionHeader'>{this.props.isNew ? 'New' : 'Edit'} Client Form</Header>
                </Grid.Row>
                <Grid.Row>
                <IncidentSectionSwitch
                    activeSection={activeSection}
                    changeFormSection={this.changeFormSection}
                    />
                </Grid.Row>
                <Grid.Row>
                    <ClientFormComponent
                        activeSection={activeSection}
                        onSubmit={this.props.onSubmit}
                        changeFormSection={this.changeFormSection}
                        cancelForm={this.props.cancelForm}
                    />
                </Grid.Row>
            </Grid>
        )
    }
}

ClientFormContainer.propTypes = {
    isNew: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    cancelForm: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
    return {
        formValidationErrors: getFormSyncErrors('client')(state)
    }
};

export default withRouter(connect(mapStateToProps, {initializeAction: initializeAction})(ClientFormContainer));