import React, { useEffect, useState } from 'react';
import { Button, Form, Grid } from 'semantic-ui-react';
import { safeSpaceOptions } from '../../constants/SelectableListOptions';
import { DateTime } from 'luxon';
import { reduxForm } from 'redux-form';
import { useSelector } from 'react-redux';
import { db } from '../../firebase/index';

const VolunteerForm = () => {
  const [submitting, setIsSubmitting] = useState(false);
  const auth = useSelector((state) => state.auth);
  const [formData, setFormData] = useState({
    site: 'townHall',
    volunteerHours: 1,
    volunteers: 1,
  });
  const [selectedState, setSelectedState] = useState({
    site: 'Town Hall',
    hours: 0,
    volunteers: 0,
    lastModifiedDateTime: new Date().getTime(),
  });

  useEffect(() => {
    (async () => {
      const volunteerSnap = await db
        .getRefOfVolunteerHoursNode()
        .orderByChild('site')
        .equalTo(formData.site)
        .once('value');
      volunteerSnap.forEach((snap) => {
        const {
          totalNumberOfHours,
          totalNumberOfVolunteers,
          lastModifiedDateTime,
        } = snap.val();
        setSelectedState((prevState) => {
          return {
            ...prevState,
            hours: totalNumberOfHours,
            volunteers: totalNumberOfVolunteers,
            lastModifiedDateTime: lastModifiedDateTime,
          };
        });
      });
    })();
  }, [selectedState.site, formData.site]);

  const handleChange = (e, { name, value }) => {
    setFormData({ ...formData, [name]: value });
    if (name === 'site') {
      const safeBaseSite = safeSpaceOptions.find(
        (obj) => obj.value === value
      ).text;
      setSelectedState({ ...selectedState, site: safeBaseSite });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const data = {
      site: formData.site,
      numberOfVolunteerHours: parseFloat(formData.volunteerHours),
      numberOfVolunteers: parseInt(formData.volunteers),
      createdBy: auth.uid,
      createdDateTime: new Date().getTime(),
    };
    const volunteerHourFormKey = db
      .getRefOfVolunteerHoursFormsNode()
      .push().key;
    await db
      .getRefOfVolunteerHoursFormsNodeWithVolunteerHourFormId(
        volunteerHourFormKey
      )
      .update(data);
    setIsSubmitting(false);
    setSelectedState({
      ...selectedState,
      hours: selectedState.hours + parseFloat(formData.volunteerHours),
      volunteers: selectedState.volunteers + parseInt(formData.volunteers),
      lastModifiedDateTime: new Date().getTime(),
    });
    setFormData({ ...formData, volunteerHours: 1, volunteers: 1 });
  };

  return (
    <div>
      <h3>
        Total number of Volunteer Shifts in {`${selectedState.site} site`} are:{' '}
        {selectedState.volunteers}
      </h3>
      <h3>
        Total number of Volunteer Hours in {`${selectedState.site} site`} are:{' '}
        {selectedState.hours}
      </h3>
      <p>
        (As of{' '}
        {DateTime.fromMillis(selectedState.lastModifiedDateTime).toLocaleString(
          DateTime.DATETIME_MED_WITH_SECONDS
        )}
        )
      </p>
      <Form onSubmit={handleSubmit} id='volunteerForm'>
        <Grid>
          <Grid.Row>
            <Grid.Column mobile={16} tablet={8} computer={8} textAlign='center'>
              <Form.Field>
                <Form.Dropdown
                  placeholder='Site'
                  name='site'
                  selection
                  options={safeSpaceOptions}
                  value={formData.site}
                  onChange={handleChange}
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column mobile={16} tablet={8} computer={8} textAlign='center'>
              <Form.Field>
                <label>
                  Enter the number of Volunteer Shifts in {selectedState.site}
                </label>
                <Form.Input
                  placeholder='Enter number of volunteer shifts'
                  name='volunteers'
                  type='number'
                  min='1'
                  max='500'
                  value={formData.volunteers}
                  onChange={handleChange}
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column mobile={16} tablet={8} computer={8} textAlign='center'>
              <Form.Field>
                <label>
                  Enter the number of Volunteer Hours in {selectedState.site}
                </label>
                <Form.Input
                  placeholder='Enter number of hours'
                  name='volunteerHours'
                  type='text'
                  value={formData.volunteerHours}
                  onChange={handleChange}
                  pattern='[0-9]*\.?[0-9]+'
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column mobile={16} tablet={8} computer={8} textAlign='center'>
              <Button
                negative
                icon='remove'
                labelPosition='right'
                content='Clear'
                type='button'
                disabled={Boolean(
                  submitting ||
                    formData.site === '' ||
                    formData.volunteerHours === '' ||
                    (formData.volunteerHours === 1 && formData.volunteers === 1)
                )}
                loading={submitting}
                onClick={() =>
                  setFormData({
                    site: 'townHall',
                    volunteerHours: 1,
                    volunteers: 1,
                  })
                }
              />
              <Button
                positive
                icon='save'
                labelPosition='right'
                content='Submit'
                type='submit'
                form='volunteerForm'
                disabled={Boolean(
                  submitting ||
                    formData.volunteers === '' ||
                    formData.volunteerHours === ''
                )}
                loading={submitting}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    </div>
  );
};

export default reduxForm({
  form: 'volunteer',
})(VolunteerForm);
