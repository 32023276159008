import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { auth } from '../firebase';

const withAuthorization = (authCondition) => (Component) => {
    class WithAuthorization extends React.Component {
        async componentDidMount() {

            const idTokenResults = await auth.currentUser.getIdTokenResult(true);

            if((authCondition === 'admin' && !idTokenResults.claims.admin)
                || (authCondition !== 'admin' && idTokenResults.claims.admin)) {
                this.props.history.push('/unauthorized');
            }
        }

        render() {
            return <Component />;
        }
    }

    const mapStateToProps = ({ auth }) => ({
        auth
    });

    return compose(
        withRouter,
        connect(mapStateToProps)
    )(WithAuthorization)
};

export default withAuthorization
