import React from 'react';
import { useState } from 'react';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import { Grid, Form, Segment, Message, Button } from 'semantic-ui-react';
import { Field as ReduxField, reduxForm, SubmissionError } from 'redux-form';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import { SemanticReduxFormInputField } from '../../utilities/SemanticReduxFormField';
import { Link } from 'react-router-dom';
const ForgotPassword = (props) => {
    const auth = getAuth();
    const [sent, setSent] = useState(false);
    const triggerResetEmail = async (formValues) => {
        try {
            await sendPasswordResetEmail(auth, formValues.email);
            console.log('Password reset email sent');
            formValues.email = '';
            setSent(true);
        } catch (error) {
            setSent(false);
            throw new SubmissionError({
                _error: error,
            });
        }
    };
    if (sent) {
    }
    const { handleSubmit, error, submitting } = props;
    return (
        <Grid className='loginGrid' container centered verticalAlign='middle'>
            {!sent ? (
                <Grid.Column mobile={16} tablet={12} computer={6}>
                    <Segment padded color='blue' raised>
                        {error && <Message error content={error.message} />}
                        <Form onSubmit={handleSubmit(triggerResetEmail)}>
                            <Grid.Row>
                                <ReduxField
                                    name='email'
                                    component={SemanticReduxFormInputField}
                                    fieldLabel='Email:'
                                    placeholder='Email Address'
                                    type='text'
                                    requiredField={true}
                                />
                                <Button
                                    icon
                                    labelPosition='right'
                                    color='green'
                                    type='submit'
                                    disabled={submitting}
                                    loading={submitting}
                                >
                                    Send Email
                                </Button>
                            </Grid.Row>
                        </Form>
                    </Segment>
                </Grid.Column>
            ) : (
                <Grid.Column>
                    <h1>
                        An email has been successfully sent. Please check your
                        email for the link to reset your password!
                    </h1>
                    <Link
                        className='LoginLink'
                        to='/Login'
                        style={{ fontSize: '25px' }}
                    >
                        Back to Login
                    </Link>
                </Grid.Column>
            )}
        </Grid>
    );
};

export default connect(
    null,
    actions
)(reduxForm({ form: 'resetPasswordForm' })(ForgotPassword));
