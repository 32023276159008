import React from 'react';
import { Button, Container, Divider, Header } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { DateTime } from 'luxon';

import ClientCardList from './ClientCardList';
import * as db from '../../firebase/db';

class ClientsComponent extends React.Component {

    submitIncident = async (formData) => {
        const startTimeDateTime = DateTime.fromMillis(formData.startTime);
        const endTimeDateTime = DateTime.fromMillis(formData.endTime);
        const incidentDurationObject = endTimeDateTime.diff(startTimeDateTime);
        formData.duration = incidentDurationObject.valueOf();

        await db.getIncidentFormRefFromIncidentFormNodeWithIncidentFormId(this.props.incidentId).update(formData);
        await db.getLastActiveFromUsersNodeWithUserUID(this.props.auth.uid).set(DateTime.local().valueOf());

        this.props.history.push('/client/new', { incidentId: this.props.incidentId });
    };

    render() {
        const { handleSubmit, submitting, incidentId } = this.props;

        return (
            <Container fluid>
                <Header as='h2'>
                    Clients
                </Header>
                <ClientCardList incidentId={incidentId}/>
                <Divider hidden/>
                <Button color='blue' type='button' content='Add Client' labelPosition='left' icon='add user' onClick={handleSubmit(this.submitIncident)} loading={submitting} disabled={submitting} />
            </Container>
        )
    }
}

ClientsComponent.propTypes = {
    incidentId: PropTypes.string.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired
};

const mapStateToProps = ({ auth }) => {
    return {
        auth
    }
};

export default withRouter(connect(mapStateToProps)(ClientsComponent));