import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Card, Container } from 'semantic-ui-react';

import { db } from '../../firebase/index';
import ClientCard from './ClientCard';
import ConfirmDeleteClientModal from './ConfirmDeleteClientModal';

class ClientCardList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      clientList: [],
      confirmDeleteModalOpen: false,
      confirmDeleteClientId: '',
    };
  }

  componentWillMount() {
    console.log('Searching for clients by incidentId:', this.props.incidentId);
    this.clientsRef = db.getClientListRefFromClientNode();
    this.clientsRef
      .orderByChild('incidentId')
      .equalTo(this.props.incidentId)
      .on('child_added', (childSnapshot, prevChildKey) => {
        // new way to update arrays! The proper way with an updater function!
        this.setState((prevState, props) => {
          // props is not used but it's there!
          return {
            clientList: [
              ...prevState.clientList,
              {
                clientKey: childSnapshot.key,
                clientData: childSnapshot.val(),
              },
            ],
          };
        });
      });
    this.clientsRef
      .orderByChild('incidentId')
      .equalTo(this.props.incidentId)
      .on('child_removed', (removedChildsnapshot) => {
        console.log('REMOVED CLIENT SNAPSHOT: ' + removedChildsnapshot.val());
        const prevClients = _.clone(this.state.clientList);
        const filteredClientList = prevClients.filter((client) => {
          return client.clientKey !== removedChildsnapshot.key;
        });
        this.setState({
          clientList: filteredClientList,
        });
      });
  }

  openConfirmDeleteModal = (clientToDelete) => {
    this.setState({
      confirmDeleteModalOpen: true,
      confirmDeleteClientId: clientToDelete,
    });
  };

  closeModal = () => {
    this.setState({
      confirmDeleteModalOpen: false,
      confirmDeleteClientId: '',
    });
  };

  generateClientCards = () => {
    if (!_.isEmpty(this.state.clientList)) {
      return _.map(this.state.clientList, (clientKeyValuePair) => {
        return (
          <ClientCard
            // dynamically generated lists need a key prop to make React more efficient
            key={clientKeyValuePair.clientKey}
            clientDetails={clientKeyValuePair}
            incidentId={this.props.incidentId}
            openConfirmDeleteModal={this.openConfirmDeleteModal}
          />
        );
      });
    } else {
      return (
        <Container textAlign='center'>
          <p>This incident has no clients yet.</p>
          <p>Please add some using the button below! :)</p>
        </Container>
      );
    }
  };

  componentWillUnmount() {
    this.clientsRef.off();
  }

  render() {
    return (
      <Card.Group itemsPerRow={5} stackable doubling>
        {this.generateClientCards()}
        <ConfirmDeleteClientModal
          modalOpen={this.state.confirmDeleteModalOpen}
          incidentFormToDelete={this.props.incidentId}
          clientFormToDelete={this.state.confirmDeleteClientId}
          closeConfirmDeleteModal={this.closeModal}
          rerouteToDraftFormsFlag={false}
        />
      </Card.Group>
    );
  }
}

ClientCardList.propTypes = {
  incidentId: PropTypes.string.isRequired,
};

export default ClientCardList;
