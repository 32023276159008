// Helper libraries
import _ from 'lodash'

// Helper validator functions
const maxLength = max => value => (
    value && value.length > max ? `Must be ${max} characters or less` : undefined);
const minLength = min => value => (
    value && value.length < min ? `Must be ${min} characters or more` : undefined);

// validator functions
// used for MOST radio options
export const notEmpty = value => (_.isEmpty(value) ? 'Please provide a value' : undefined);
// used for radio options where FALSE boolean (or 0) needs to be allowed
export const notNull = value => (_.isNull(value) ? 'Please provide a value' : undefined);
export const required = value => ( value ? undefined : 'Required');
export const maxLength15 = maxLength(15);
export const minLength2 = minLength(2);
export const email = value => (
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
        ? 'Invalid email address'
        : undefined);
export const alphabetical = value => (
    value && /[^a-zA-Z ]/i.test(value)
        ? 'Only alphabetical characters'
        : undefined);
export const landlineNumber = value => (
    value && !/^(0|[9][0-9]{7})$/i.test(value)
        ? 'Invalid landline number, must be 8 digits starting with 9.'
        : undefined);
export const mobileNumber = value => (
    value && !/^(0|[0][4][0-9]{8})$/i.test(value)
        ? 'Invalid mobile number, must be 10 digits starting with 04'
        : undefined);
export const phoneNumber = value => (
    value && !/^(0|[0][4][0-9]{8}|[9][0-9]{7})$/i.test(value)
        ? 'Invalid phone number, must start with 04 or 9'
        : undefined);
