import React from 'react';
import { Button, Form, Header} from 'semantic-ui-react';
import {Field as ReduxField, reduxForm} from 'redux-form';
import {
    LocationInput,
    SemanticReduxFormDateInput,
    SemanticReduxFormTextArea,
    SemanticReduxInputField
} from '../../../utilities/SemanticReduxFormField';

const validate = (values) => {
    const errors = {};
    if (!values.startTime) {
        errors.startTime = 'Required'
    }
    if (!values.incidentDescription) {
        errors.incidentDescription = 'Required'
    }
    return errors;
};

class QuickForm extends React.Component {

    render() {
        const { handleSubmit, submitting } = this.props;
        console.log("QuickForm Props: ", this.props);

        return (
            <div>
                <Header as='h2'>QuickForm</Header>
                <Form
                    size='large'
                    onSubmit={handleSubmit}
                >
                    <ReduxField
                        component={SemanticReduxFormDateInput}
                        name='startTime'
                        fieldLabel='Incident Start Time'
                        enableTime={true}
                        displayFormat='F J, Y - H:i'
                    />
                    <ReduxField
                        fieldLabel='Description'
                        name='incidentDescription'
                        placeholder='Quick Description of the Incident'
                        component={SemanticReduxFormTextArea}
                    />
                    <ReduxField
                        component={SemanticReduxInputField}
                        as={LocationInput}
                        label='Address'
                        placeholder='Address of the Incident'
                        name='location'
                    />
                    <Button
                        color='green'
                        floated='right'
                        icon='save'
                        labelPosition='left'
                        content='Save Draft'
                        disabled={submitting}
                        loading={submitting}
                    />
                </Form>
            </div>
        )
    }
}

const config = {
    form: 'incident',
    validate
};

export default reduxForm(config)(QuickForm);