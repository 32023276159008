import React from 'react'
import { List } from 'semantic-ui-react'
import _ from 'lodash'
import PropTypes from 'prop-types'

class ResponsiveHorizontalList extends React.Component {

    static propTypes = {
        listHeaderName: PropTypes.string.isRequired,
        listObject: PropTypes.any.isRequired
    };

    generateListItems = () => {
        return _.map(this.props.listObject, (dataValue, key) => {

            let icon = 'help';
            let color = 'yellow';
            const itemText = _.startCase(key);

            if (dataValue === true) {
                icon = 'checkmark';
                color = 'green';
            } else if (dataValue === false) {
                icon = 'remove';
                color = 'red';
            } else if (_.isEmpty(dataValue)) {
                icon = 'help';
                color = 'yellow'
            }

            return (
                <List.Item key={key}>
                    <List.Icon name={icon} color={color} />
                    <List.Content>
                        <List.Description>{itemText}</List.Description>
                    </List.Content>
                </List.Item>
            )
        })
    };

    render() {
        return (
            <List size='large'>
                <List.Item>
                    <List.Header>{this.props.listHeaderName}</List.Header>
                    {/*<Responsive minWidth={767} as={List} horizontal relaxed divided>*/}
                        {/*{this.generateListItems()}*/}
                    {/*</Responsive>*/}
                    {/*<Responsive maxWidth={768} as={List}>*/}
                        {/*{this.generateListItems()}*/}
                    {/*</Responsive>*/}
                    <List.List>
                        {this.generateListItems()}
                    </List.List>
                </List.Item>
            </List>
        )
    }
}
export default ResponsiveHorizontalList

