import React from 'react';
import { BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import { connect } from 'react-redux';

import * as actions from '../actions';
import Login from './Login';
import MainContainer from './MainContainer';
import withAuthentication from '../hocs/withAuthentication';
import ForgotPassword from './ForgotPassword';

class App extends React.Component {

    render() {
        return (
            <Router>
                <Switch>
                    {/* These routes do not require authorization at all to log in */}
                    <Route path="/forgotPassword" component={ForgotPassword} />
                    {/*<Route component={About} path='/about' />*/}
                    {
                        this.props.auth
                            ? <MainContainer/>
                            : <Login/>
                    }
                </Switch>
            </Router>
        )
    }
}

const mapStateToProps = ({ auth }) => {
    return { auth }
};

export default withAuthentication(connect(
    mapStateToProps,
    actions
)(App));
