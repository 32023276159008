import React from "react";
import {Form} from "semantic-ui-react";
import {Field as ReduxField} from "redux-form";
import {SemanticReduxFormCheckbox} from "../../../utilities/SemanticReduxFormField";

/*
 * This is the OtherServicesInvolved section used by Incident Form Component.
 * This must be wrapped with a redux <FormSection name={parentSectionName} > component in
 * order to use it.
 */
export default class OtherServicesInvolved extends React.Component {
    render() {
        return (
            <Form.Group widths={7}>
                <ReduxField
                    name='police'
                    component={SemanticReduxFormCheckbox}
                    checkboxLabel='Police'
                />
                <ReduxField
                    name='ambulance'
                    component={SemanticReduxFormCheckbox}
                    checkboxLabel='Ambulance'
                />
                <ReduxField
                    name='fireRescue'
                    component={SemanticReduxFormCheckbox}
                    checkboxLabel='Fire Rescue'
                />
                <ReduxField
                    name='cctv'
                    component={SemanticReduxFormCheckbox}
                    checkboxLabel='CCTV'
                />
                <ReduxField
                    name='rangers'
                    component={SemanticReduxFormCheckbox}
                    checkboxLabel='Rangers'
                />
                <ReduxField
                    name='venueSecurity'
                    component={SemanticReduxFormCheckbox}
                    checkboxLabel='Venue Security'
                />
                <ReduxField
                    name='others'
                    component={SemanticReduxFormCheckbox}
                    checkboxLabel='Other Security'
                />
            </Form.Group>
        )
    }
}