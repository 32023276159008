import React from 'react'
import PropTypes from 'prop-types'
import { Modal, Button } from 'semantic-ui-react'

class ServiceCardEditModal extends React.Component {

    static propTypes = {
        socialServiceEditModalOpen: PropTypes.bool.isRequired,
        triggerModal: PropTypes.func.isRequired,
        socialServiceModel: PropTypes.object.isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            socialServiceModel: this.props.socialServiceModel
        }
    }

    updateDetails = (event, data) => {
        event.preventDefault();
        console.log(data);
        let inputName = data.name;
        let inputValue = data.value;
        this.setState({
            socialServiceModel: { ...this.state.socialServiceModel,
                [inputName]: inputValue
            }
        })
    };

    render() {
        return (
            <Modal dimmer open={this.props.socialServiceEditModalOpen}>
                <Modal.Header>Edit Social Service</Modal.Header>
                <Modal.Content>
                    Social Services
                </Modal.Content>
                <Modal.Actions>
                    <Button negative icon='remove' labelPosition='right' content="Cancel" onClick={this.props.triggerModal} />
                    <Button positive icon='checkmark' labelPosition='right' content="Save" onClick={this.props.triggerModal} />
                </Modal.Actions>
            </Modal>
        )
    }
}

export default ServiceCardEditModal