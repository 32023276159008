import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import _ from 'lodash';

import IncidentFormDisplayComponent from './IncidentFormDisplayComponent';
import { db } from '../../../firebase/index';

class IncidentViewer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formData: {},
            incidentClientsData: []
        }
    }

    async componentDidMount() {
        if ( _.has(this.props.location.state, "incidentId") ) {
            const { incidentId } = this.props.location.state;

            const incidentIdExists = await db.incidentFormExists(incidentId);
            if (incidentIdExists) {
                const formReference = db.getIncidentFormRefFromIncidentFormNodeWithIncidentFormId(incidentId);
                formReference.once('value', formData => {
                    // console.log("INCIDENT VIEW: ", formData.val());
                    this.setState({
                        formData: formData.val()
                    });
                    db.getClientListRefWithIncidentFormId(incidentId)
                        .on('child_added', (clientSnapshot) => {
                            // console.log("CHILD SNAPSHOT VAL: ", clientSnapshot.val());
                            this.setState((prevState, props) => {
                                return {
                                    incidentClientsData: [
                                        ...prevState.incidentClientsData,
                                        {clientKey: clientSnapshot.key, clientData: clientSnapshot.val()}
                                    ]
                                }
                            })
                        })
                })
                .catch(error => {
                    console.log("FORM ERROR CODE: " + error.code);
                    console.log("FORM ERROR MESSAGE: " + error.message);
                })
            } else {
                this.props.history.replace("/error", { error: "IncidentId doesn't exist on firebase.", from: this.props.match.url, incidentId: incidentId });
            }
        } else {
            this.props.history.replace("/error", { error: "Expecting Client ID", from: this.props.match.url });
        }

    }

    render() {
        if ( _.has(this.props.location.state, "incidentId") ) {
            return ( <IncidentFormDisplayComponent
                formData={this.state.formData}
                incidentClientsData={this.state.incidentClientsData}
                incidentId={this.props.location.state.incidentId}
            /> )
        } else {
            return <Redirect to={{ pathname: '/error', state: { error: "Expecting Client ID", from: this.props.match.url }} } />
        }
    }
}

export default IncidentViewer
