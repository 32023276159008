// incident form data
const defaultModel = {
    additionalAid: {
        firstAid: false,
        mentalHealthAid: false,
    },
    ageGroup: "",
    alone: null,
    basicAid: {
        vomitBag: false,
        water: false,
        footwear: false,
        lollipop: false
    },
    clientConsciousness: 0,
    clientLostProperty: 0,
    clientValuablesVisibility: 0,
    clientServiceReferrals: {
        alcoholDrugInfoService: false,
        beyondBlue: false,
        childProtectionServices: false,
        dvLine: false,
        hospital: false,
        lifeline: false,
        link2home: false,
        salvosStreetLevel: false,
        streetbeatBus: false,
        traffickingSlaveryAFP: false
    },
    contactNumber: "",
    createdBy: "", // id from list of registered users
    createdDate: "",
    directions: {
        venue: false,
        accommodation: false,
        other: false
    },
    domesticViolence: {
        observed: false,
        visibleSigns: false,
        disclosed: false,
        notVisible: false,
    },
    drugUseSigns: {
        observed: false,
        visibleSigns: false,
        disclosed: false,
        notVisible: false,
    },
    editedBy: "", // id from list of registered users
    editedDate: "",
    email: "",
    emergencyServicesCalled: {
        ambulanceServiceCalled: false,
        policeServiceCalled: false,
        fireServiceCalled: false
    },
    escortedTo: {
        accommodation: false,
        transport: false,
        friends: false,
        other: false
    },
    firstName: "",
    gender: "",
    incidentId: "",
    lastName: "",
    injury: {
        roadRelated: false,
        other: false
    },
    intoxicationSigns: {
        speech: false,
        balance: false,
        coordination: false,
        behaviour: false,
        notVisible: false,
    },
    offensiveConduct: {
        offensiveBehaviour: false,
        offensiveLanguage: false,
        obstruction: false,
        publicDrinking: false,
        notVisible: false
    },
    otherSupport: {
        welfareCheck: false,
        homelessSupport: false
    },
    physicalAssault: {
        observed: false,
        visibleSigns: false,
        disclosed: false,
        notVisible: false,
    },
    physicalAssaultRisk: 0,
    reconnection: {
        person: false,
        socialNetwork: false,
        telephone: false
    },
    safeSpace: {
        escortedTo: false,
        soberedUp: false
    },
    schemaVersion: 1,
    schemaName: "client",
    sexualAssaultRisk: 0,
    sexualAssault: {
        observed: false,
        visibleSigns: false,
        disclosed: false,
        notVisible: false,
    },
    site: "",
    serviceInformation: {
        contactedService: false,
        infoProvided: false,
    },
    suburb: "",
    selfHarmSigns: {
        visibleSigns: false,
        disclosed: false,
        notVisible: false,
    },
    suicidalSigns: {
        ideationObserved: false,
        ideationDisclosed: false,
        attemptObserved: false,
        attemptDisclosed: false,
        notVisible: false,
    },
    transportInformation: {
        bus: false,
        train: false,
        taxi: false,
        uber: false,
        other: false
    },
};

const createModel = () => defaultModel;

export default createModel;
