import React from 'react';
import PropTypes from 'prop-types';
import { Divider, Form, Grid, Header } from 'semantic-ui-react';
import { Field as ReduxField, FormSection, reduxForm } from 'redux-form';

import {
  LocationInput,
  SemanticReduxFormDateInput,
  SemanticReduxFormInputField,
  SemanticReduxFormSelect,
  SemanticReduxFormTextArea,
  SemanticReduxInputField,
} from '../../utilities/SemanticReduxFormField';
import IncidentFormSubmitButtons from './IncidentFormSubmitButtons';
import ClientsComponent from '../Clients/ClientsComponent';
import { safeSpaceOptions } from '../../constants/SelectableListOptions';
import validateIncidentForm from '../../formValidators/incidentForm';
import EncounteredBy from './ReduxFormSections/EncounteredBy';
import OtherServicesInvolved from './ReduxFormSections/OtherServicesInvolved';

class IncidentForm extends React.Component {
  render() {
    const { handleSubmit, submitting, incidentId, isNew, editFrom } =
      this.props;

    return (
      <Grid container>
        <Grid.Row>
          <Header
            as="h2"
            content={isNew ? 'New Incident Details' : 'Edit Incident Details'}
            className="sectionHeader"
          />
          <Form className="incidentFormSegment" size="large">
            <Form.Group widths={3}>
              <ReduxField
                component={SemanticReduxFormInputField}
                fieldLabel="Team Leader Name"
                placeholder="Team Leader Name"
                name="teamLeaderName"
                type="text"
                requiredField={true}
              />
              <ReduxField
                component={SemanticReduxFormSelect}
                requiredField={true}
                fieldLabel="Base Site"
                placeholder="Site Name"
                name="site"
                selectionOptions={safeSpaceOptions}
              />
              <ReduxField
                component={SemanticReduxInputField}
                as={LocationInput}
                label="Address"
                placeholder="Address of the Incident"
                name="location"
              />
            </Form.Group>
            <Form.Group widths={2}>
              <ReduxField
                component={SemanticReduxFormDateInput}
                fieldLabel="Incident Start Time"
                name="startTime"
                enableTime={true}
                displayFormat="F J, Y - H:i"
              />
              <ReduxField
                component={SemanticReduxFormDateInput}
                fieldLabel="Incident End Time"
                name="endTime"
                enableTime={true}
                displayFormat="F J, Y - H:i"
              />
            </Form.Group>
            <Divider />
            <Form.Field label="Incident Referred By" />
            <FormSection name="encounteredBy">
              <EncounteredBy />
            </FormSection>
            <Divider />
            <Form.Field label="Other Services Referred" />
            <FormSection name="otherServicesInvolved">
              <OtherServicesInvolved />
            </FormSection>
            <Divider />
            <ClientsComponent
              incidentId={incidentId}
              handleSubmit={handleSubmit}
              submitting={submitting}
            />
            <Divider />
            <ReduxField
              component={SemanticReduxFormTextArea}
              fieldLabel="Incident Description"
              placeholder="What happened?"
              name="incidentDescription"
              requiredField={true}
              rows={8}
            />
            <ReduxField
              name="incidentOutcome"
              component={SemanticReduxFormTextArea}
              fieldLabel="Incident Outcome"
              placeholder="Description of Outcome"
              requiredField={true}
              rows={8}
            />
            <Divider />

            <IncidentFormSubmitButtons
              incidentId={incidentId}
              handleSubmit={handleSubmit}
              submitting={submitting}
              editFrom={editFrom}
            />
          </Form>
        </Grid.Row>
        <Grid.Row
        // Padding Row othewise bottom elements (buttons, tables) have no padding on mobile responsive mode
        />
      </Grid>
    );
  }
}

IncidentForm.propTypes = {
  incidentId: PropTypes.string.isRequired,
  isNew: PropTypes.bool.isRequired,
  editFrom: PropTypes.string,
};

export default reduxForm({
  form: 'incident',
  validateIncidentForm,
})(IncidentForm);
