/*
 * All endpoint variables should be named here for ease of refactoring and testing
 */
export const CLIENT_FORMS = 'clients/';
export const DAILY_SITE_DATA = 'dailySiteData/';
export const INCIDENT_FORMS = 'incidentForms/';
export const REPORTS = 'reports/';
export const SAFESPACE_FORMS = 'safeSpaceForms/';
export const SOCIAL_SERVICES = 'socialServices/';
export const USERS = 'users/';
export const VOLUNTEER_FORMS = 'volunteerForms/';
export const MONTHLY_QUARTERLY_REPORTS = 'monthlyQuarterlyReports/';
export const MONTHLY_DATA = 'monthlyData/';
export const DAILY_DATA = 'dailyData/';