import _ from 'lodash';

// These Util functions are used mainly by the Incident Viewer, Client Viewer and Share Modal.

// Takes in a data object with keys and values of true/false/null/'' and returns only stuff with true, capitalised, if nothing was ticked true then returns 'None Selected'.
export const listOfTrues = (dataObject) => {
    const resultsArray = [];
    _.forOwn(dataObject, (value, key) => {
        if (value === true) {
            resultsArray.push(_.startCase(key));
        }
    });
    if (resultsArray.length > 0) {
        return resultsArray.join(', ');
    } else {
        return 'None Selected';
    }
};

// Takes value and list of value options and returns label of the value matches. If no matches, return 'No Data'
export const matchValues = (value, valueList) => {
    if (_.isNumber(value) || _.isString(value)) {
        return valueList.map(valueItem => {
            if (value === valueItem.value) {
                return valueItem.label
            }
            return '';
        })
    } else {
        return 'No Data';
    }
};
