// incident form data
const defaultModel = {
    clientList: [],
    createdBy: "", // id from list of registered users
    createdDate: "",
    duration: 0, // in minutes
    editedBy: "", // id from list of registered users
    editedDate: "",
    encounteredBy: { // Incident Referred By
        tkAmbassador: false,
        cctv: false,
        self: false,
        friend: false,
        generalPublic: false,
        venueSecurity: false,
        transportStaff: false,
        police: false,
        fireRescue: false,
        rangers: false,
        ambulance: false,
        other: ""
    },
    endTime: "",
    incidentDescription: "",
    incidentOutcome: "",
    location: {
        address: "",
        latitude: 0,
        longitude: 0,
    },
    majorIncident: false,
    otherServicesInvolved: { // Other Services Referred
        police: false,
        ambulance: false,
        fireRescue: false,
        cctv: false,
        rangers: false,
        venueSecurity: false,
        others: ""
    },
    schemaVersion: 1,
    schemaName: "incident",
    site: "",
    startTime: "",
    status: "draft",
    teamLeaderName: "", // id from list of registered users
    teamMembersInvolved: [], // String list of ids from list of registered users
};

const createModel = () => defaultModel;

export default createModel;
