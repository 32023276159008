import React from 'react';
import { auth } from '../firebase';
import {connect} from 'react-redux';
import * as actions from '../actions';

const withAuthentication = (Component) => {
    class WithAuthentication extends React.Component {
        componentDidMount() {
            auth.onAuthStateChanged(authUser => {
                authUser
                    ? this.props.updateAuthProfile(authUser)
                    : this.props.clearAuthProfile(null);
            });
        }

        render() {
            return(
                <Component/>
            )
        }
    }

    return connect(null, actions)(WithAuthentication)
};

export default withAuthentication;