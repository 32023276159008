import React from 'react'
import { Modal, Button } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import { db } from '../../firebase/index';
import {connect} from 'react-redux'
import _ from 'lodash'
import {withRouter} from "react-router-dom";

class ConfirmDeleteClientModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            deletingStatus: false
        }
    }

    confirmDeleteModal = () => {
        // Insert Firebase delete call here
        this.setState({deletingStatus: true});
        const clientRef = db.getClientRefFromClientNodeWithClientFormId(this.props.clientFormToDelete);
        clientRef.remove()
            .then(() => {
                console.log("" + this.props.clientFormToDelete + " has been removed");
                const incidentClientListRef = db.getClientListRefFromIncidentFormsWithIncidentFormId(this.props.incidentFormToDelete);
                incidentClientListRef.once('value', result => {
                    console.log("DELETE RESULT: ", result.val());
                    const clientList = result.val();
                    const filteredClientList = clientList.filter(client => {
                        return client !== this.props.clientFormToDelete;
                    });
                    console.log("FILTERED CLIENT LIST: ", filteredClientList);
                    incidentClientListRef.set(filteredClientList).then(result => {
                        console.log("CLIENT LIST UPDATED");
                    });
                }).then(() => {
                    if (this.props.rerouteToDraftFormsFlag) {
                        this.props.rerouteToDraftForms();
                    } else {
                        this.props.closeConfirmDeleteModal();
                        this.setState({deletingStatus: false});
                    }
                });
            })
            .catch((error) => {
                console.log("Failed to remove " + this.props.clientFormToDelete);
                console.log("confirmModalDelete Error Code: " + error.code);
                console.log("confirmModalDelete Error Message: " + error.message);
            })
    };

    render() {
        return (
            <Modal
                open={this.props.modalOpen}
                size='small'
            >
                <Modal.Header>Confirm Delete Client?</Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <p>Are you sure you wish to delete this client?</p>
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        negative
                        type={"button"}
                        icon='remove'
                        labelPosition='right'
                        content='Cancel'
                        onClick={() => this.props.closeConfirmDeleteModal()}
                    />
                    <Button
                        positive
                        type={"button"}
                        icon='checkmark'
                        labelPosition='right'
                        content="Confirm"
                        loading={this.state.deletingStatus}
                        onClick={this.confirmDeleteModal}
                    />
                </Modal.Actions>
            </Modal>
        )
    }
}

ConfirmDeleteClientModal.propTypes = {
    modalOpen:PropTypes.bool.isRequired,
    clientFormToDelete: PropTypes.string.isRequired,
    incidentFormToDelete: PropTypes.string.isRequired,
    closeConfirmDeleteModal: PropTypes.func.isRequired,
    rerouteToDraftFormsFlag: PropTypes.bool.isRequired
};

const mapStateToProps = (state, ownProps) => {
    return {
        rerouteToDraftForms: () => {
            const from = _.get(ownProps, "location.state.from", "/");
            ownProps.history.push(from);
        }
    }
};

export default withRouter(connect(mapStateToProps)(ConfirmDeleteClientModal))