import React, { Component } from 'react'
import {Button, Header, Icon, Loader, Table} from 'semantic-ui-react'
import { Link } from 'react-router-dom';
import * as _ from 'lodash';

import { db } from '../../firebase/index';
import AddUserModal from '../Admin/AddUser/AddUserModal';
import { auth } from '../../firebase/index';

const INITIAL_STATE = {
    loading: false,
    usersList: []
};

class UserList extends Component {
    constructor(props) {
        super(props);

        this.state = INITIAL_STATE;
    }

    componentDidMount() {
        this.setState({
            loading: true
        });
        this.usersDatabaseReference = db.getRefOfUsersNode();
        const activeUsers = [];

        // sorts by first name ascending
        this.usersDatabaseReference.orderByChild('firstName').on('child_added', (snapshot) => {
            if (snapshot.exists()) {
                const userData = snapshot.val();
                if (userData.accountStatus === 'active') {
                    activeUsers.push({userKey: snapshot.key, userData: userData});
                }
                this.setState({
                    usersList: activeUsers,
                    loading: false
                }, () => { // function to execute upon async setState() call complete
                    // console.log("USERS LIST", this.state.usersList);
                })
            }
        })
        // maybe add an 'on('child_removed/moved')' function here later to facilitate removed or moved (inactive) users
    }

    componentWillUnmount() {
        this.usersDatabaseReference.off()
    }

    // User not getting their reset email? They need to have logged into the system at least once in order to get it.
    resetPassword = (email) => {
        auth.sendPasswordResetEmail(email)
            .then(() => {
                console.log("Reset email was sent to " + email);
            })
            .catch(error => {
                console.log("Unable to send reset email. Error Code: " + error.code + " Error Message: " + error.message);
            })
    };

    generateDataRows = () => {
        return _.map(this.state.usersList, (user) => {
            return (
                <Table.Row key={user.userKey}
                    // error={incidentForm.isDraft} // paint table row red if form is still draft
                >
                    <Table.Cell>{user.userData.firstName}</Table.Cell>
                    <Table.Cell>{user.userData.lastName}</Table.Cell>
                    <Table.Cell>{_.startCase(user.userData.userLevel)}</Table.Cell>
                    <Table.Cell>{user.userData.email}</Table.Cell>
                    <Table.Cell
                        //need to use textAlign because using the float prop on the button causes the button to 'glitch' out of the row (try it if you don't believe me)
                        textAlign='right'
                    >
                        <Button animated='vertical' color='blue' as={Link} to={{pathname: '/user/view/' + user.userKey}}>
                            <Button.Content visible>View</Button.Content>
                            <Button.Content hidden><Icon name='eye' /></Button.Content>
                        </Button>
                    </Table.Cell>
                </Table.Row>
            )
        })
    };

    render() {
        return (
            <div>
                <Header as='h2'>Manage Users</Header>
                <Table striped>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>First Name</Table.HeaderCell>
                            <Table.HeaderCell>Last Name</Table.HeaderCell>
                            <Table.HeaderCell>User Level</Table.HeaderCell>
                            <Table.HeaderCell>Email</Table.HeaderCell>
                            <Table.HeaderCell width={4}/>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        { this.state.loading ? <Table.Row><Table.Cell colSpan={5} textAlign='center' verticalAlign='middle'><Loader active inline content='Loading...' /></Table.Cell></Table.Row> : this.generateDataRows() }
                    </Table.Body>
                    <Table.Footer fullWidth>
                        <Table.Row>
                            <Table.HeaderCell colSpan={5}>
                                <AddUserModal />
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Footer>
                </Table>
            </div>
        )
    }
}

export default UserList
