import React from 'react'
import { Button, Modal } from 'semantic-ui-react'
import { connect } from 'react-redux';
import { initialize as initializeFormAction, reduxForm } from 'redux-form';

import AddUserForm from './AddUserForm';
import { functions } from '../../../firebase';

class AddUserModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            addNewUserModel: {},
            addUserModalOpen: false
        }
    }

    submitCreateNewUser = async (data) => {

        console.log("CREATE NEW USER WITH DATA", data);

        // firebase callable function that makes a call to a firebase function on firebase
        // the firebase function has access to the admin SDK and will
        // a) create a new user
        // b) assign the user a role
        // c) add newly created user to the profiles list
        const callableFunction = functions.httpsCallable('httpsCreateUser');
        try {
            await callableFunction({userData: data});
        } catch (error) {
            console.log("ERROR CREATING USER!", error);
        }
        this.triggerModal();
    };

    triggerModal = () => {
        this.setState({addUserModalOpen: !this.state.addUserModalOpen})
    };

    render() {

        const { submitting } = this.props; // these come from reduxForm HOC

        return (
            <Modal
                dimmer
                open={this.state.addUserModalOpen}
                trigger={<Button
                    floated='right'
                    icon='add user'
                    labelPosition='left'
                    primary content='Add User'
                    onClick={this.triggerModal} />}
            >
                <Modal.Header>Add a New User</Modal.Header>
                <Modal.Content>
                    <AddUserForm onSubmit={this.submitCreateNewUser} />
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        negative
                        type='button'
                        icon='remove'
                        labelPosition='right'
                        content='Cancel'
                        onClick={this.triggerModal}
                    />
                    <Button
                        positive
                        type='submit'
                        icon='checkmark'
                        labelPosition='right'
                        form='addUserForm'
                        content='Create'
                        loading={submitting}
                    />
                </Modal.Actions>
            </Modal>
        )
    }
}

const mapStateToProps = ({ auth, profile }) => {
    return {
        auth,
        profile
    }
};

export default connect(mapStateToProps, { initializeFormAction: initializeFormAction})(reduxForm({ form: 'addUserForm' })(AddUserModal));