import React, { Component } from 'react';

import { Divider, Form, Grid, Header } from 'semantic-ui-react';
import { Field as ReduxField } from 'redux-form';
import { NewSemanticReduxFormCheckbox } from '../../../utilities/SemanticReduxFormField';
import ActionButtons from './ActionButtons';
import { withReduxForm } from './with-redux-form';
import {
  additionalAidOptions,
  basicAidOptions,
  emergencyServicesCalledOptions,
} from '../../../constants/SelectableListOptions';

class HealthSupportForm extends Component {
  changeFormSection = (bubbleUpValue) => {
    this.props.changeFormSection(bubbleUpValue);
  };

  cancelForm = () => {
    this.props.cancelForm();
  };

  render() {
    const { handleSubmit } = this.props;
    return (
      <Grid container>
        <Grid.Row>
          <Header as="h2" content="Health & Emergency Support" />
          <Form
            onSubmit={handleSubmit}
            size="large"
            className="incidentFormSegment"
          >
            <ReduxField
              name="basicAid"
              component={NewSemanticReduxFormCheckbox}
              fieldLabel="Basic Aid"
              requiredAsterisk={false}
              grouped={false}
              widths={4}
              checkboxOptions={basicAidOptions}
            />
            <Divider />
            <ReduxField
              name="additionalAid"
              component={NewSemanticReduxFormCheckbox}
              fieldLabel="Additional Aid"
              requiredAsterisk={false}
              grouped={false}
              widths={4}
              checkboxOptions={additionalAidOptions}
            />
            <Divider />
            <ReduxField
              name="emergencyServicesCalled"
              component={NewSemanticReduxFormCheckbox}
              fieldLabel="Emergency Services"
              requiredAsterisk={false}
              grouped={false}
              widths={4}
              checkboxOptions={emergencyServicesCalledOptions}
            />
            <Divider />
            <ActionButtons {...this.props} />
          </Form>
        </Grid.Row>
        <Grid.Row
        // Padding Row othewise bottom elements (buttons, tables) have no padding on mobile responsive mode
        />
      </Grid>
    );
  }
}

export default withReduxForm(HealthSupportForm);
