import React from 'react';
import { Container, Header } from 'semantic-ui-react';
import QuarterlyReportsGenerator from "./QuarterlyReportsGenerator";
import MonthlyQuarterlyReportsContainer from "./MonthlyQuarterlyReportsContainer";
import withAuthorization from "../../../hocs/withAuthorization";

const MonthlyQuarterlyTotals = () => (
    <Container>
        <Header as='h1'>Quarterly Reports</Header>
        <QuarterlyReportsGenerator />
        <Header as='h2'>Quarterly Reports List</Header>
        <MonthlyQuarterlyReportsContainer />
    </Container>
);

export default withAuthorization('admin')(MonthlyQuarterlyTotals);