import { DateTime } from 'luxon';
import { PERIOD_MONTHLY, PERIOD_QUARTERLY } from "../constants/reportConstants";
import { monthsOptions, quarterOptions } from "../constants/SelectableListOptions";

export const getYearOptions = () => {
    const startOfAvailableReporting = DateTime.local(2019).startOf("year");
    const currentDateTime = DateTime.local();

    const numberOfYears = currentDateTime
        .diff(startOfAvailableReporting)
        .shiftTo("years").years;

    const yearsArray = [];

    for (let i = 0; i <= numberOfYears; i++) {
        const year = startOfAvailableReporting.plus({ years: i }).year;

        yearsArray.push({ key: year, text: year, value: year });
    }

    return yearsArray;
};

export const getReportingPeriod = (periodType, year) => {

    const currentYear = DateTime.local().year;
    const startOfThisYear = DateTime.local().startOf("year");
    const currentDateTime = DateTime.local();

    if (!!year && periodType === PERIOD_MONTHLY) {
        // TODO: Only give them from March onwards for 2019! There's no data for the time before that!
        if (year === 2019) {
            return monthsOptions.slice(2) // only return March onwards for 2019 because that's what we have data for
        } else if (year === currentYear) {
            // find all valid months
            const numberOfMonths = currentDateTime
                .diff(startOfThisYear)
                .shiftTo("months").months; // Luxon returns decimal number of months in elapsed starting with 0 as Jan

            const calculatedMonthsArray = [];

            for (let i = 0; i < Math.floor(numberOfMonths); i++) {
                calculatedMonthsArray.push(monthsOptions[i]);
            }

            return calculatedMonthsArray;
        } else {
            return monthsOptions;
        }
    } else if (!!year && periodType === PERIOD_QUARTERLY) {
        if (year === currentYear) {
            // find all valid months
            const numberOfQuarters = currentDateTime
                .diff(startOfThisYear)
                .shiftTo("quarters").quarters;

            const calculatedQuartersArray = [];

            for (let i = 0; i < Math.floor(numberOfQuarters); i++) {
                calculatedQuartersArray.push(quarterOptions[i]);
            }

            return calculatedQuartersArray;
        } else {
            return quarterOptions;
        }
    }
    else {
        console.log("null everything!");
        return [
            {
                key: null,
                text: "Please Select a Reporting Type and Year!",
                value: null
            }
        ];
    }
};