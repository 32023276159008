import * as _ from 'lodash';

// Actually Client Validation
const validate = (values) => {
  // console.log("VALIDATE VALUES", values);

  // ----- Client Info Validation
  const errors = {};
  if (!values.ageGroup) {
    errors.ageGroup = 'Required';
  }

  if (values.alone === null) {
    errors.alone = 'Required';
    errors._error = 'Missing Alone';
  }

  if (!values.gender) {
    errors.gender = 'Required';
  }

  if (!values.intoxicationSigns) {
    errors.intoxicationSigns = 'Required';
  } else {
    if (
      values.intoxicationSigns.notVisible &&
      (values.intoxicationSigns.speech ||
        values.intoxicationSigns.balance ||
        values.intoxicationSigns.behaviour ||
        values.intoxicationSigns.coordination)
    ) {
      errors.intoxicationSigns = "Tick either 'Not Visible' or the others.";
    } else if (
      !values.intoxicationSigns.notVisible &&
      !values.intoxicationSigns.speech &&
      !values.intoxicationSigns.balance &&
      !values.intoxicationSigns.behaviour &&
      !values.intoxicationSigns.coordination
    ) {
      errors.intoxicationSigns = 'Required';
    }
  }

  if (!values.drugUseSigns) {
    errors.drugUseSigns = 'Required';
  } else {
    if (
      values.drugUseSigns.notVisible &&
      (values.drugUseSigns.observed ||
        values.drugUseSigns.disclosed ||
        values.drugUseSigns.visibleSigns)
    ) {
      errors.drugUseSigns = "Tick either 'Not Visible' or the others.";
    } else if (
      !values.drugUseSigns.notVisible &&
      !values.drugUseSigns.observed &&
      !values.drugUseSigns.disclosed &&
      !values.drugUseSigns.visibleSigns
    ) {
      errors.drugUseSigns = 'Required';
    }
  }

  if (!values.offensiveConduct) {
    errors.offensiveConduct = 'Required';
  } else {
    if (
      values.offensiveConduct.notVisible &&
      (values.offensiveConduct.offensiveBehaviour ||
        values.offensiveConduct.offensiveLanguage ||
        values.offensiveConduct.obstruction ||
        values.offensiveConduct.publicDrinking)
    ) {
      errors.offensiveConduct = "Tick either 'Not Visible' or the others.";
    } else if (
      !values.offensiveConduct.notVisible &&
      !values.offensiveConduct.offensiveBehaviour &&
      !values.offensiveConduct.offensiveLanguage &&
      !values.offensiveConduct.obstruction &&
      !values.offensiveConduct.publicDrinking
    ) {
      errors.offensiveConduct = 'Required';
    }
  }

  if (!values.selfHarmSigns) {
    errors.selfHarmSigns = 'Required';
  } else {
    if (
      values.selfHarmSigns.notVisible &&
      (values.selfHarmSigns.visibleSigns || values.selfHarmSigns.disclosed)
    ) {
      errors.selfHarmSigns = "Tick either 'Not Visible' or the others.";
    } else if (
      !values.selfHarmSigns.notVisible &&
      !values.selfHarmSigns.visibleSigns &&
      !values.selfHarmSigns.disclosed
    ) {
      errors.selfHarmSigns = 'Required';
    }
  }

  if (!values.suicidalSigns) {
    errors.suicidalSigns = 'Required';
  } else {
    if (
      values.suicidalSigns.notVisible &&
      (values.suicidalSigns.ideationObserved ||
        values.suicidalSigns.ideationDisclosed ||
        values.suicidalSigns.attemptObserved ||
        values.suicidalSigns.attemptDisclosed)
    ) {
      errors.suicidalSigns = "Tick either 'Not Visible' or the others.";
    } else if (
      !values.suicidalSigns.notVisible &&
      !values.suicidalSigns.ideationObserved &&
      !values.suicidalSigns.ideationDisclosed &&
      !values.suicidalSigns.attemptObserved &&
      !values.suicidalSigns.attemptDisclosed
    ) {
      errors.suicidalSigns = 'Required';
    }
  }

  if (!values.sexualAssault) {
    errors.sexualAssault = 'Required';
  } else {
    if (
      values.sexualAssault.notVisible &&
      (values.sexualAssault.observed ||
        values.sexualAssault.disclosed ||
        values.sexualAssault.visibleSigns)
    ) {
      errors.sexualAssault = "Tick either 'Not Visible' or the others.";
    } else if (
      !values.sexualAssault.notVisible &&
      !values.sexualAssault.observed &&
      !values.sexualAssault.disclosed &&
      !values.sexualAssault.visibleSigns
    ) {
      errors.sexualAssault = 'Required';
    }
  }

  if (!values.physicalAssault) {
    errors.physicalAssault = 'Required';
  } else {
    if (
      values.physicalAssault.notVisible &&
      (values.physicalAssault.observed ||
        values.physicalAssault.disclosed ||
        values.physicalAssault.visibleSigns)
    ) {
      errors.physicalAssault = "Tick either 'Not Visible' or the others.";
    } else if (
      !values.physicalAssault.notVisible &&
      !values.physicalAssault.observed &&
      !values.physicalAssault.disclosed &&
      !values.physicalAssault.visibleSigns
    ) {
      errors.physicalAssault = 'Required';
    }
  }

  if (!values.domesticViolence) {
    errors.domesticViolence = 'Required';
  } else {
    if (
      values.domesticViolence.notVisible &&
      (values.domesticViolence.observed ||
        values.domesticViolence.disclosed ||
        values.domesticViolence.visibleSigns)
    ) {
      errors.domesticViolence = "Tick either 'Not Visible' or the others.";
    } else if (
      !values.domesticViolence.notVisible &&
      !values.domesticViolence.observed &&
      !values.domesticViolence.disclosed &&
      !values.domesticViolence.visibleSigns
    ) {
      errors.domesticViolence = 'Required';
    }
  }

  return errors;
};

export default validate;
