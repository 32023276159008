import React from 'react';
import {Button, Icon, Message, Modal} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import {functions} from '../../../firebase';

const INITIAL_STATE = {
    modalOpen: false,
    loading: false
};

class ToggleAdminModal extends React.Component {

    static propTypes = {
        userId: PropTypes.string.isRequired,
        userLevel: PropTypes.string.isRequired,
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired
    };

    constructor(props) {
        super(props);

        this.state = INITIAL_STATE;
    }

    toggleModal = () => {
        this.setState({
            modalOpen: !this.state.modalOpen,
            error: false
        })
    };

    toggleAdmin = async () => {
        // setup callable firebase function to make a https request to the firebase backend
        this.setState({
            loading: true
        });
        const callableFunction = functions.httpsCallable('httpsUpdateUserLevel');
        try {
            await callableFunction({ uid: this.props.userId });
            this.setState({
                loading: false,
                error: false
            });
            this.toggleModal();
        } catch (error) {
            console.log("ERROR UPDATING USER LEVEL!", error.code, error.message);
            this.setState({
                loading: false,
                error: error.message
            });
        }
    };

    // Returns a Button component. Better than stuffing this crap inside the trigger prop of the rendered Modal
    triggerButton = () => {
        const { userLevel } = this.props;

        return (
            <Button
                animated='vertical' color={ userLevel === 'administrator' ? 'red' : 'green' }
                onClick={this.toggleModal}
            >
                <Button.Content visible>{ userLevel === 'administrator' ? 'Remove' : 'Give' } Admin</Button.Content>
                <Button.Content hidden><Icon name={ userLevel === 'administrator' ? 'lock' : 'unlock' } /></Button.Content>
            </Button>
        )
    };

    render() {
        const { firstName, lastName, userLevel } = this.props;
        const { loading } = this.state;

        return (
            <Modal
                trigger={this.triggerButton()}
                open={this.state.modalOpen}
            >
                <Modal.Header>{userLevel === 'administrator' ? 'Revoke' : 'Grant'} Administrator Privileges?</Modal.Header>
                <Modal.Content>
                    <Message negative icon='exclamation circle' hidden={_.isEmpty(this.state.error)} content={this.state.error} />
                    <p>Are you sure you want to {userLevel === 'administrator' ? 'revoke' : 'grant'} {firstName} {lastName} Administrative privileges?</p>
                </Modal.Content>
                <Modal.Actions>
                    <Button negative type='button' content='No' icon='remove' labelPosition='right' onClick={this.toggleModal} />
                    <Button positive type='button' content='Yes' icon='checkmark' labelPosition='right' onClick={this.toggleAdmin} loading={loading} disabled={loading}/>
                </Modal.Actions>
            </Modal>
        )
    }
}

export default ToggleAdminModal;