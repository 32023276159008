import React, { Component } from 'react';
import { withReduxForm } from './with-redux-form';
import { Field as ReduxField } from 'redux-form';
import { Divider, Form, Grid, Header } from 'semantic-ui-react';

import {
  SemanticReduxFormInputField,
  SemanticReduxFormRadioGroup,
  NewSemanticReduxFormCheckbox,
} from '../../../utilities/SemanticReduxFormField';
import ActionButtons from './ActionButtons';
import { booleanNormalization } from '../../../utilities/ValuesNormalizationOptions';
import {
  ageGroupOptions,
  genderOptions,
  intoxicationSignsOptions,
  observedVisibleDisclosedNotVisibleOptions,
  offensiveConductOptions,
  selfHarmSignsOptions,
  suicidalSignsOptions,
} from '../../../constants/SelectableListOptions';

class ClientInfoForm extends Component {
  changeFormSection = (bubbleUpValue) => {
    this.props.changeFormSection(bubbleUpValue);
  };

  cancelForm = () => {
    this.props.cancelForm();
  };

  render() {
    const { error, handleSubmit } = this.props;

    console.log('CLIENT INFO PROPS', this.props);
    if (error !== undefined) {
      console.log('ERROR._ERROR', error);
    }

    return (
      <Grid container>
        <Grid.Row>
          <Header as="h2" content="Client Information" />
          {/*Error: {JSON.stringify(error)}*/}
          <Form
            onSubmit={handleSubmit}
            size="large"
            className="incidentFormSegment"
            widths="equal"
          >
            <Grid columns={'equal'} stackable celled="internally">
              <Grid.Column>
                <ReduxField
                  name="gender"
                  component={SemanticReduxFormRadioGroup}
                  fieldLabel="Gender"
                  grouped={true}
                  requiredLabel={true}
                  radioButtonList={genderOptions}
                />
              </Grid.Column>
              <Grid.Column>
                <ReduxField
                  name="ageGroup"
                  component={SemanticReduxFormRadioGroup}
                  fieldLabel="Age Group"
                  grouped={true}
                  requiredLabel={true}
                  radioButtonList={ageGroupOptions}
                />
              </Grid.Column>
              <Grid.Column>
                <ReduxField
                  name="alone"
                  component={SemanticReduxFormRadioGroup}
                  normalize={booleanNormalization}
                  fieldLabel="Alone"
                  grouped={true}
                  requiredLabel={true}
                  trueFalseRadio={true}
                  radioButtonList={[
                    { value: 'true', label: 'Yes' },
                    { value: 'false', label: 'No' },
                  ]}
                />
              </Grid.Column>
              <Grid.Column>
                <ReduxField
                  name="intoxicationSigns"
                  component={NewSemanticReduxFormCheckbox}
                  fieldLabel="Intoxication Signs"
                  requiredAsterisk={true}
                  grouped={true}
                  widths={'equal'}
                  checkboxOptions={intoxicationSignsOptions}
                />
              </Grid.Column>
              <Grid.Column>
                <ReduxField
                  name="drugUseSigns"
                  component={NewSemanticReduxFormCheckbox}
                  fieldLabel="Drug Use Signs"
                  requiredAsterisk={true}
                  grouped={true}
                  widths={'equal'}
                  checkboxOptions={observedVisibleDisclosedNotVisibleOptions}
                />
              </Grid.Column>
            </Grid>
            <Divider />
            <Grid columns={'equal'} stackable celled="internally">
              <Grid.Column>
                <ReduxField
                  name="offensiveConduct"
                  component={NewSemanticReduxFormCheckbox}
                  fieldLabel="Offensive Conduct"
                  requiredAsterisk={true}
                  grouped={true}
                  widths={'equal'}
                  checkboxOptions={offensiveConductOptions}
                />
              </Grid.Column>
              <Grid.Column>
                <ReduxField
                  name="selfHarmSigns"
                  component={NewSemanticReduxFormCheckbox}
                  fieldLabel="Self Harm Signs"
                  requiredAsterisk={true}
                  grouped={true}
                  widths={'equal'}
                  checkboxOptions={selfHarmSignsOptions}
                />
              </Grid.Column>
              <Grid.Column>
                <ReduxField
                  name="suicidalSigns"
                  component={NewSemanticReduxFormCheckbox}
                  fieldLabel="Suicidal Signs"
                  requiredAsterisk={true}
                  grouped={true}
                  widths={'equal'}
                  checkboxOptions={suicidalSignsOptions}
                />
              </Grid.Column>
            </Grid>
            <Divider />
            <Grid columns={3} stackable celled="internally">
              <Grid.Column>
                <ReduxField
                  name="sexualAssault"
                  component={NewSemanticReduxFormCheckbox}
                  fieldLabel="Sexual Assault"
                  requiredAsterisk={true}
                  grouped={true}
                  widths={'equal'}
                  checkboxOptions={observedVisibleDisclosedNotVisibleOptions}
                />
              </Grid.Column>
              <Grid.Column>
                <ReduxField
                  name="physicalAssault"
                  component={NewSemanticReduxFormCheckbox}
                  fieldLabel="Physical Assault"
                  requiredAsterisk={true}
                  grouped={true}
                  widths={'equal'}
                  checkboxOptions={observedVisibleDisclosedNotVisibleOptions}
                />
              </Grid.Column>
              <Grid.Column>
                <ReduxField
                  name="domesticViolence"
                  component={NewSemanticReduxFormCheckbox}
                  fieldLabel="Domestic Violence Related"
                  requiredAsterisk={true}
                  grouped={true}
                  widths={'equal'}
                  checkboxOptions={observedVisibleDisclosedNotVisibleOptions}
                />
              </Grid.Column>
            </Grid>
            <Divider />
            <Form.Field label="Contact Information" />
            {/* The sections below are commented out as TKYF doesn't want to lose the work already done on implementing these fields. */}
            <Form.Group widths={2}>
              <ReduxField
                name="firstName"
                component={SemanticReduxFormInputField}
                // validate={alphabetical}
                fieldLabel="First Name"
                placeholder="First Name"
              />
              <ReduxField
                name="suburb"
                component={SemanticReduxFormInputField}
                // validate={alphabetical}
                fieldLabel="Suburb"
                placeholder="Suburb"
              />
              {/*<ReduxField*/}
              {/*name='lastName'*/}
              {/*component={SemanticReduxFormInputField}*/}
              {/*validate={alphabetical}*/}
              {/*fieldLabel='Last Name'*/}
              {/*placeholder='Last Name'*/}
              {/*/>*/}
            </Form.Group>
            {/*<Form.Group widths={2}>*/}
            {/*<ReduxField*/}
            {/*name='email'*/}
            {/*component={SemanticReduxFormInputField}*/}
            {/*validate={email}*/}
            {/*fieldLabel='Email'*/}
            {/*placeholder='Email Address'*/}
            {/*/>*/}
            {/*<ReduxField*/}
            {/*name='contactNumber'*/}
            {/*component={SemanticReduxFormInputField}*/}
            {/*validate={[phoneNumber]}*/}
            {/*fieldLabel='Contact Number'*/}
            {/*placeholder='Landline or Mobile Number'*/}
            {/*/>*/}
            {/*</Form.Group>*/}
            {/*<ReduxField*/}
            {/*name='suburb'*/}
            {/*component={SemanticReduxFormInputField}*/}
            {/*validate={alphabetical}*/}
            {/*fieldLabel='Suburb'*/}
            {/*placeholder='Suburb'*/}
            {/*/>*/}
            <Divider />

            <ActionButtons {...this.props} />
          </Form>
        </Grid.Row>
        <Grid.Row
        // Padding Row othewise bottom elements (buttons, tables) have no padding on mobile responsive mode
        />
      </Grid>
    );
  }
}

export default withReduxForm(ClientInfoForm);
