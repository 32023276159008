import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { initialize as initializeAction } from 'redux-form';
import _ from 'lodash';
import { DateTime } from 'luxon';

import { db } from '../../firebase/index';
import ClientFormContainer from './ClientFormContainer';
import createModel from '../../Models/client';

class ClientNew extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            clientList: []
        }
    }

    async componentDidMount() {
        window.scrollTo(0, 0);
        // console.log("PROPS", this.props.location.state.incidentId);
        if ( _.has(this.props.location.state, "incidentId") ) {
            console.log("Incident Id:", this.props.location.state.incidentId);

            const { initializeAction } = this.props;
            const { incidentId } = this.props.location.state;

            const incidentIdExists = await db.incidentFormExists(incidentId);
            if (incidentIdExists) {
                const incidentStartTime = await db.getStartTimeRefFromIncidentNodeWithIncidentFormId(incidentId);

                const getInitialState = () => {
                    let clientModel = createModel();
                    clientModel = {
                        ...clientModel,
                        createdDate: DateTime.local().valueOf(),
                        createdBy: this.props.auth.uid,
                        incidentId: incidentId,
                        site: this.props.profile.site,
                        startTime: incidentStartTime
                    };
                    return clientModel;
                };

                const data = getInitialState();
                initializeAction('client', data);
            } else {
                this.props.history.replace("/error", { error: "IncidentId doesn't exist on firebase.", from: this.props.match.url, incidentId: incidentId });
            }

        } else {
            this.props.history.replace("/error", { error: "Expecting Incident ID", from: this.props.match.url });
        }
    }

    cancelForm = () => {
        if (_.has(this.props.location.state, "incidentId")) {
            this.props.history.replace('/incident/edit', {incidentId: this.props.location.state.incidentId});
        } else {
            this.props.history.goBack();
        }
    };

    submitClientForm = async (formData) => {
        // console.log(formData); // client data to be submitted to firebase
        // push form data to clients/ node and get the push id back as client id
        const clientId = await db.getClientListRefFromClientNode().push(formData).key;
        // update the form to complete
        await db.getIncidentFormRefFromIncidentFormNodeWithIncidentFormId(formData.incidentId).update({ status: 'completed' });

        // update the {incidentId}/clientList with new clientId
        // create newClientList array
        const newClientListArray = await db.getClientListFromIncidentFormsWithIncidentFormid(formData.incidentId) || [];
        // push the clientId into newClientList array
        newClientListArray.push(clientId);
        // get reference to and update {incidentId}/clientList with newClientList array
        await db.getClientListRefFromIncidentFormsWithIncidentFormId(formData.incidentId).set(newClientListArray);
        this.props.history.push('/incident/edit/', { incidentId: formData.incidentId })
    };

    render() {
        return (
            <ClientFormContainer
                onSubmit={this.submitClientForm}
                isNew={true}
                cancelForm={this.cancelForm}
            />
        )
    }
}

const mapStateToProps = ({ auth, profile }) => {
    return {
        auth,
        profile
    }
};

export default withRouter(connect(mapStateToProps, {initializeAction: initializeAction})(ClientNew))
