import React from 'react';
import {Form, Segment} from 'semantic-ui-react';
import {connect} from 'react-redux';
import {DateTime} from 'luxon';
import {Field as ReduxField, reduxForm} from 'redux-form';

import {functions} from '../../../firebase/index';
import {createModel} from '../../../Models/generatedReportModel';
import {
    SemanticReduxFormDateInput,
    SemanticReduxFormInputField,
} from '../../../utilities/SemanticReduxFormField';

const validate = (values) => {
    const errors = {};
    if (!values.reportStartTimestamp) {
        errors.reportStartTimestamp = 'Required';
    }
    if (!values.reportEndTimestamp) {
        errors.reportEndTimestamp = 'Required';
    }
    if (values.reportStartTimestamp > values.reportEndTimestamp) {
        errors.reportStartTimestamp = 'Start Time must be before End Time';
        errors.reportEndTimestamp = 'End Time must be after Start Time';
    }
    if (values.reportStartTimestamp === values.reportEndTimestamp) {
        errors.reportStartTimestamp = 'Start Time must be before End Time';
        errors.reportEndTimestamp = 'End Time must be after Start Time';
    }
    return errors;
};

const INITAL_STATE = {
    formGenerating: false,
};

class ReportGenerator extends React.Component {
    constructor(props) {
        super(props);

        this.state = INITAL_STATE;
    }

    requestReportGeneration = (request) => {
        this.setState({
            formGenerating: true,
        });
        const callableFunction = functions.httpsCallable(
            'httpsGenerateReportWithDates'
        );
        callableFunction({reportGenerationRequest: request})
            .then((result) => {
                this.setState({
                    formGenerating: false,
                });
            })
            .catch((error) => {
                console.log('Error: ', error);
                this.setState({
                    formGenerating: false,
                });
            });
    };

    getInitialFormState = () => {
        const currentTime = DateTime.local();

        let reportGenerationFormModel = createModel();
        reportGenerationFormModel = {
            ...reportGenerationFormModel,
            createdDate: currentTime.valueOf(),
            createdBy:
                this.props.profile.firstName + ' ' + this.props.profile.lastName,
            authorizationUID: this.props.auth.uid,
            reportStartTimestamp: currentTime.startOf('day').valueOf(),
            reportEndTimestamp: currentTime
                .startOf('day')
                .plus({days: 1})
                .valueOf(),
        };
        return reportGenerationFormModel;
    };

    render() {
        return (
            <Segment clearing>
                <ReportGenerationForm
                    initialValues={this.getInitialFormState()}
                    onSubmit={this.requestReportGeneration}
                    loading={this.state.formGenerating}
                />
            </Segment>
        );
    }
}

class ReduxForm extends React.Component {
    render() {
        const {handleSubmit, loading} = this.props;

        return (
            <Form onSubmit={handleSubmit} loading={loading}>
                <Form.Group widths="equal">
                    <ReduxField
                        component={SemanticReduxFormDateInput}
                        name="reportStartTimestamp"
                        fieldLabel="Report Start Date"
                        enableTime={false}
                        displayFormat="l F J, Y"
                    />
                    <ReduxField
                        component={SemanticReduxFormDateInput}
                        name="reportEndTimestamp"
                        fieldLabel="Report End Date"
                        enableTime={false}
                        displayFormat="l F J, Y"
                    />
                </Form.Group>
                <Form.Group widths="equal">
                    <ReduxField
                        component={SemanticReduxFormInputField}
                        name="reportDescription"
                        placeholder="Type of report, Notes, etc..."
                        fieldLabel="Report Description"
                        type="text"
                    />
                </Form.Group>
                <Form.Button
                    loading={loading}
                    disabled={loading}
                    type="submit"
                    floated="right"
                    labelPosition="right"
                    icon="bolt"
                    color="blue"
                    content="Generate!"
                />
            </Form>
        );
    }
}

const ReportGenerationForm = reduxForm({
    form: 'reportGeneration',
    validate,
})(ReduxForm);

const mapStateToProps = ({auth, profile}) => {
    return {
        auth,
        profile,
    };
};

export default connect(mapStateToProps)(ReportGenerator);
