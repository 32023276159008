import React from 'react';
import { Header, Table, Button, Icon } from 'semantic-ui-react';
import _ from 'lodash'
import { Link } from 'react-router-dom';
import ConfirmDeleteModal from './ConfirmDeleteModal';
import { connect } from 'react-redux';
import { DateTime } from 'luxon';

import { db } from '../../firebase/index';

class DraftForms extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            incidentForms: [],
            confirmDeleteModalOpen: false,
            confirmDeleteFormId: '',
        };

        this.incidentFormsDatabaseReference = db.getRefOfIncidentFormsNode()
    }

    componentDidMount() {

        // get current time
        const currentTime = DateTime.local();

        // set up start and end query time variables
        let queryStartTime;
        let queryEndTime;

        // is what's the current hour?
        const currentHour = currentTime.hour;
        // if it's between 12am and 5:59:59am then
        if (currentHour >= 0 && currentHour < 6) {
            // set start time limit to 6pm of PREVIOUS day
            queryStartTime = currentTime.minus({days: 1}).startOf('day').plus({hours: 18});
            // set end time limit to 6am of CURRENT day
            queryEndTime = currentTime.startOf('day').plus({hours: 6});
        }
        // if it's between 6pm and 11:59:59pm then
        else if (currentHour >= 18 && currentHour <= 23) {
            // set start time limit to 6pm of CURRENT day
            queryStartTime = currentTime.startOf('day').plus({hours: 18});
            // set end time limit to 6am of NEXT day
            queryEndTime = currentTime.plus({days: 1}).startOf('day').plus({hours: 6});
        }
        // if it's outside of the 6pm - 6am window fetch between
        // 6pm-12am of PREVIOUS day and 12am-6am of CURRENT day (last operating night of data)
        else {
            queryStartTime = currentTime.minus({days: 1}).startOf('day').plus({hours: 18});
            queryEndTime = currentTime.startOf('day').plus({hours: 6});
        }

        // queryStartTime = DateTime.fromObject({day: 5, month: 4, year: 2018, hour: 6, minute: 0}); // custom time setting's for testing!
        // queryEndTime = DateTime.fromObject({day: 12, month: 4, year: 2018, hour: 6, minute: 0}); // custom time setting's for testing!

        this.incidentFormsDatabaseReference
            .orderByChild('startTime')
            .startAt(queryStartTime.valueOf())
            .endAt(queryEndTime.valueOf())
            .on('child_added', (snapshot) => {
                const prevIncidentForms = _.clone(this.state.incidentForms);
                const snapshotData = snapshot.val();
                if (snapshotData.createdBy === this.props.auth.uid) {
                    // Unshift, unlike push, inserts the newest objects at the very start of the array
                    // prevIncidentForms.splice(0, 0, {formKey: snapshot.key, formData: snapshot.val()});
                    prevIncidentForms.unshift({formKey: snapshot.key, formData: snapshotData});
                }
                this.setState({
                    incidentForms: prevIncidentForms
                });
            });
        this.incidentFormsDatabaseReference
            .on('child_removed', (snapshot) => {
                const prevIncidentForms = _.clone(this.state.incidentForms);
                const filteredPrevIncidentForm = prevIncidentForms.filter(form => {
                    return form.formKey !== snapshot.key
                });
                this.setState({
                    incidentForms: filteredPrevIncidentForm
                })
            });
    }

    componentWillUnmount() {
        this.incidentFormsDatabaseReference.off()
    }

    openConfirmDeleteModal = (incidentFormToDelete) => {
        this.setState({
            confirmDeleteModalOpen: true,
            confirmDeleteFormId: incidentFormToDelete
        })
    };

    closeModal = () => {
        this.setState({
            confirmDeleteModalOpen: false,
            confirmDeleteFormId: ''
        })
    };

    generateDataRows = () => {
        if (!_.isEmpty(this.state.incidentForms)) {
            return _.map(this.state.incidentForms, (form) => {
                return (
                    <Table.Row
                        key={form.formKey}
                        error={form.formData.status === 'draft'} // paint table row red if form is still draft
                    >
                        <Table.Cell>{DateTime.fromMillis(form.formData.startTime).toFormat("ccc MMMM d, h:mm a")}</Table.Cell>
                        <Table.Cell>{_.truncate(form.formData.incidentDescription, { length: 100, omission: '...' }) || "(No Incident Description)"}</Table.Cell>
                        <Table.Cell>{form.formData.location.address}</Table.Cell>
                        <Table.Cell>{form.formData.clientList === undefined ? 0 : form.formData.clientList.length}</Table.Cell>
                        <Table.Cell textAlign='center'>
                            <Button.Group widths={3}>
                                <Button
                                    animated='vertical' color='blue' as={Link} to={{pathname: '/incident/view/', state: { incidentId: form.formKey} }} >
                                    <Button.Content visible>View</Button.Content>
                                    <Button.Content hidden><Icon name='eye' /></Button.Content>
                                </Button>
                                <Button animated='vertical' color='yellow' as={Link} to={{pathname: '/incident/edit/', state: { incidentId: form.formKey, editFrom: '/myforms' } }} >
                                    <Button.Content visible>Edit</Button.Content>
                                    <Button.Content hidden><Icon name='pencil' /></Button.Content>
                                </Button>
                                <Button animated='vertical' color='red' onClick={() => {this.openConfirmDeleteModal(form.formKey)}} >
                                    <Button.Content visible>Delete</Button.Content>
                                    <Button.Content hidden><Icon name='trash' /></Button.Content>
                                </Button>
                            </Button.Group>
                        </Table.Cell>
                    </Table.Row>
                )
            })
        } else {
            return (
                <Table.Row>
                    <Table.Cell colSpan={5} textAlign='center' content='No Draft Forms to show!' />
                </Table.Row>
            )
        }
    };

    render() {
        return (
            <div>
                <Header as='h2'>Draft Forms</Header>
                <p>Below is a list of the Quick Forms you have created so far during your current shift. They are still missing data such as client information and incident information. Please complete them!</p>
                <Table striped size='large'>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Start Date/Time</Table.HeaderCell>
                            <Table.HeaderCell>Description</Table.HeaderCell>
                            <Table.HeaderCell>Location</Table.HeaderCell>
                            <Table.HeaderCell>Clients</Table.HeaderCell>
                            <Table.HeaderCell width={4}/>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {this.generateDataRows()}
                    </Table.Body>
                </Table>
                <ConfirmDeleteModal
                    modalOpen={this.state.confirmDeleteModalOpen}
                    incidentFormToDelete={this.state.confirmDeleteFormId}
                    closeConfirmDeleteModal={this.closeModal}
                    rerouteToDraftFormsFlag={false}
                />
            </div>
        )
    }

}

const mapStateToProps = ({ auth, profile }) => {
    return {
        auth,
        profile
    }
};

export default connect(
    mapStateToProps
)(DraftForms)