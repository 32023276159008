import React from 'react';
import { Button, Responsive } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';

import { db } from '../../firebase/index';

class IncidentFormSubmitButtons extends React.Component {

    static propTypes = {
        editFrom: PropTypes.string,
        handleSubmit: PropTypes.func.isRequired,
        incidentId: PropTypes.string.isRequired,
        submitting: PropTypes.bool,
    };

    submitIncident = async (formData) => {
        const { history, auth, incidentId, editFrom } = this.props;

        const startTimeDateTime = DateTime.fromMillis(formData.startTime);
        const endTimeDateTime = DateTime.fromMillis(formData.endTime);
        const incidentDurationObject = endTimeDateTime.diff(startTimeDateTime);
        formData.duration = incidentDurationObject.valueOf();

        await db.getIncidentFormRefFromIncidentFormNodeWithIncidentFormId(incidentId).update(formData);
        await db.getLastActiveFromUsersNodeWithUserUID(auth.uid).set(DateTime.local().valueOf());

        if (editFrom) {
            history.replace(editFrom, { incidentId: incidentId });
        } else {
            history.replace('/myforms');
        }
    };

    cancelClick = () => {
        // const { history, editFrom, incidentId } = this.props;
        //
        // if (editFrom) {
        //     history.replace(editFrom, { incidentId: incidentId });
        // } else {
        //     history.replace('/myforms');
        // }
        this.props.history.goBack();
    };

    render() {
        const { submitting, handleSubmit } = this.props;

            return (
                <div>
                    <Responsive minWidth={320} maxWidth={767}>
                        <Button
                            color='red'
                            icon='undo alternate'
                            size='large'
                            type='button'
                            disabled={submitting}
                            loading={submitting}
                            onClick={this.cancelClick}
                        />
                        <Button
                            floated='right'
                            color='green'
                            icon='save'
                            size='large'
                            type='button'
                            disabled={submitting}
                            loading={submitting}
                            onClick={handleSubmit(this.submitIncident)}
                        />
                    </Responsive>
                    <Responsive minWidth={768}>
                        <Button
                            color='red'
                            icon='undo alternate'
                            labelPosition='left'
                            content='Cancel Edits'
                            size='large'
                            type='button'
                            disabled={submitting}
                            loading={submitting}
                            onClick={this.cancelClick}
                        />
                        <Button
                            floated='right'
                            color='green'
                            icon='save'
                            labelPosition='left'
                            content='Submit Form'
                            size='large'
                            type='button'
                            disabled={submitting}
                            loading={submitting}
                            onClick={handleSubmit(this.submitIncident)}
                        />
                    </Responsive>
                </div>
            )
        }
    // }

}

const mapStateToProps = ({ auth }) => {
    return {
        auth
    }
};

export default withRouter(connect(mapStateToProps)(IncidentFormSubmitButtons));