import React, { useState } from "react";
import { Button, Segment, Form, Modal } from "semantic-ui-react";
import { reduxForm, Field as ReduxField, getFormValues } from "redux-form";
import { connect } from "react-redux";
import { compose } from "redux";

import {
  periodTypeOptions,
  quarterlyReportTypeOptions,
} from "../../../constants/SelectableListOptions";
import {
  getReportingPeriod,
  getYearOptions,
} from "../../../utilities/ReportTypeDateUtilities";
import {
  SemanticReduxFormInputField,
  SemanticReduxFormSelect,
} from "../../../utilities/SemanticReduxFormField";
import validateQuarterlyReportGeneratorForm from "../../../formValidators/quarterlyReportsGeneratorForm";
import { functions } from "../../../firebase/index";

function modalAlertAction(state, action) {
  switch (action.type) {
    case "OPEN_MODAL":
      return { open: true, dimmer: action.dimmer };
    case "CLOSE_MODAL":
      return { open: false };
    default:
      throw new Error();
  }
}

const QuarterlyReportGenerator = () => {
  const [formGenerating, setFormGenerating] = useState(false);
  const [state, dispatch] = React.useReducer(modalAlertAction, {
    open: false,
    dimmer: undefined,
  });
  const { open, dimmer } = state;

  const requestQuarterlyReportGeneration = (data) => {
    setFormGenerating(true);
    console.log("Request quarterly report generation: ", data);
    const callableFunction = functions.httpsCallable(
      "httpsGenerateQuarterlyReport"
    );
    callableFunction({ reportGenerationRequest: data })
      .then((result) => {
        if (result.data == null) {
          dispatch({ type: "OPEN_MODAL" });
        }

        console.log("Result from generateQuarterlyReport", result);
        setFormGenerating(false);
      })
      .catch((error) => {
        console.log("Error", error);
        setFormGenerating(false);
      });
  };

  return (
    <Segment clearing>
      <QuarterlyReportGenerationForm
        initialValue={{}}
        onSubmit={requestQuarterlyReportGeneration}
        loading={formGenerating}
      />
      <Modal
        dimmer={dimmer}
        open={open}
        onClose={() => dispatch({ type: "CLOSE_MODAL" })}
      >
        <Modal.Header>Selected Report does not exist!</Modal.Header>
        <Modal.Content>Please select a new report to generate.</Modal.Content>
        <Modal.Actions>
          <Button negative onClick={() => dispatch({ type: "CLOSE_MODAL" })}>
            Close
          </Button>
        </Modal.Actions>
      </Modal>
    </Segment>
  );
};

const ReduxForm = ({ handleSubmit, values, loading }) => {
  return (
    <Form onSubmit={handleSubmit} loading={loading}>
      <Form.Group widths="equal">
        <ReduxField
          component={SemanticReduxFormSelect}
          name="reportType"
          fieldLabel="Report Type"
          selectionOptions={quarterlyReportTypeOptions}
          required={true}
          placeholder="Please Select a Report Type"
        />
      </Form.Group>
      <Form.Group widths="equal">
        <ReduxField
          component={SemanticReduxFormSelect}
          name="periodType"
          fieldLabel="Period Type"
          selectionOptions={periodTypeOptions}
          required={true}
          placeholder="Please Select a Period Type"
        />
        <ReduxField
          component={SemanticReduxFormSelect}
          name="year"
          fieldLabel="Reporting Year"
          selectionOptions={getYearOptions()}
          required={true}
          placeholder="Year"
        />
        <ReduxField
          component={SemanticReduxFormSelect}
          name="reportingPeriod"
          fieldLabel="Period"
          selectionOptions={
            !!values
              ? getReportingPeriod(values.periodType, values.year)
              : // when values is not available, will display this set of options
                // when it's only either periodType or year that is null, getReportionPeriod()
                // will return the same dropdown option as it's still the only valid options.
                [
                  {
                    key: null,
                    text: "Please Select a Reporting Type and Year!",
                    value: null,
                  },
                ]
          }
          required={true}
          placeholder="Please Select a Period Type and Year"
        />
      </Form.Group>
      <Form.Group widths="equal">
        <ReduxField
          component={SemanticReduxFormInputField}
          name="description"
          fieldLabel="Report Description"
          type="text"
          width={16}
        />
      </Form.Group>
      <Form.Button
        loading={loading}
        disabled={loading}
        floated="right"
        content="Generate!"
        color="blue"
      />
    </Form>
  );
};

const QuarterlyReportGenerationForm = compose(
  reduxForm({
    form: "quarterly",
    validate: validateQuarterlyReportGeneratorForm,
  }),
  connect((state) => ({
    values: getFormValues("quarterly")(state),
  }))
)(ReduxForm);

export default QuarterlyReportGenerator;
