import React, { Component } from 'react';
import { Grid, Header } from 'semantic-ui-react';
import { connect } from 'react-redux';

import StatisticSegment from './StatisticSegment';
import { functions } from '../../firebase/index';
import { db as fbDb } from '../../firebase/firebase';

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dashboardResult: {
        peopleAssisted: 0,
        drugsIntoxicated: 0,
        alone: 0,
        sexualAssaultRisk: 0,
        deEscalatedViolence: 0,
        welfareChecks: 0,
        reconnections: 0,
        escorted: 0,
        firstAid: 0,
        volunteersHours: 0,
        volunteers: 0,
      },
      loadingValues: false,
    };
  }

  async componentDidMount() {
    this.setState({
      loadingValues: true,
    });

    try {
      const generateDashboardInfo = functions.httpsCallable(
        'httpsGenerateDashboardInfo'
      );
      const dashboardData = await fbDb.ref().child('dashboardInfoStats').get();
      this.setState({
        dashboardResult: dashboardData.val(),
        loadingValues: false,
      });
      const result = await generateDashboardInfo();
      if (!result) {
        console.log('Error calling cloud function httpsGenerateDashboardInfo');
      }
    } catch (error) {
      console.log('Error: ', error);
    }
    this.setState({
      loadingValues: false,
    });
  }

  render() {
    const dashboardResult = this.state.dashboardResult;

    return (
      <Grid container>
        <Grid.Row>
          <Header as='h1' className='sectionHeader'>
            Welcome to our Dashboard!
          </Header>
        </Grid.Row>
        <Grid.Row centered>This is the difference we've made so far!</Grid.Row>
        <Grid.Row centered>
          <Grid container stackable centered>
            <StatisticSegment
              iconName='users'
              topLabel='People Assisted'
              iconColor='green'
              number={dashboardResult?.peopleAssisted ?? 0} // added because react complains that property is empty (when it specified as required) during data fetching
              loading={this.state.loadingValues}
            />
            <StatisticSegment
              iconName='beer'
              topLabel='Drugs and/or Intoxicated'
              iconColor='yellow'
              number={dashboardResult?.drugsIntoxicated ?? 0}
              loading={this.state.loadingValues}
            />
            <StatisticSegment
              iconName='male'
              topLabel='Alone'
              iconColor='blue'
              number={dashboardResult?.alone ?? 0}
              loading={this.state.loadingValues}
            />
            <StatisticSegment
              iconName='exclamation triangle'
              topLabel='Sexual Assault Risk'
              iconColor='orange'
              number={dashboardResult?.sexualAssaultRisk ?? 0}
              loading={this.state.loadingValues}
            />
            <StatisticSegment
              iconName='shield alternate'
              topLabel='De-escalated Violence'
              iconColor='purple'
              number={dashboardResult?.deEscalatedViolence ?? 0}
              loading={this.state.loadingValues}
            />
            <StatisticSegment
              iconName='clipboard list'
              topLabel='Welfare Checks'
              iconColor='olive'
              number={dashboardResult?.welfareChecks ?? 0}
              loading={this.state.loadingValues}
            />
            <StatisticSegment
              iconName='handshake'
              topLabel='Reconnections'
              iconColor='teal'
              number={dashboardResult?.reconnections ?? 0}
              loading={this.state.loadingValues}
            />
            <StatisticSegment
              iconName='shipping fast'
              topLabel='Escorted'
              iconColor='violet'
              number={dashboardResult?.escorted ?? 0}
              loading={this.state.loadingValues}
            />
            <StatisticSegment
              iconName='first aid'
              topLabel='First Aid'
              iconColor='red'
              number={dashboardResult?.firstAid ?? 0}
              loading={this.state.loadingValues}
            />
            <StatisticSegment
              iconName='clock'
              topLabel='Volunteer Hours'
              iconColor='pink'
              number={dashboardResult?.volunteersHours ?? 0}
              loading={this.state.loadingValues}
            />
            <StatisticSegment
              iconName='hand paper'
              topLabel='Volunteer Shifts'
              iconColor='brown'
              number={dashboardResult?.volunteers ?? 0}
              loading={this.state.loadingValues}
            />
          </Grid>
        </Grid.Row>
        <Grid.Row
        // Padding Row otherwise bottom elements (buttons, tables) have no padding on mobile responsive mode
        />
      </Grid>
    );
  }
}

const mapStateToProps = ({ auth, profile }) => {
  return {
    auth,
    profile,
  };
};

export default connect(mapStateToProps)(Dashboard);
