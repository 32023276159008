import * as PaginateTypes from '../actions/paginateTypes';

const initalState = {
  newestId: null,
  oldestId: null,
  prevCursorId: null,
  nextCursorId: null,
  hasPrevPage: false,
  hasNextPage: false,
  pageIndex: 1,
};

const paginateReducer = (state = initalState, action) => {
  switch (action.type) {
    case PaginateTypes.PAGINATE_UPDATE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default paginateReducer;
