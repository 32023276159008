export default {
    programTotals: {
        name: "Program Totals",
        description: "Reports that sum up the program's totals in a specified period",
        url: "/reports/programTotals",
    },
    quarterly: {
        name: "Quarterly Reports",
        description: "Reports that are generated every quarter based on correlated data between clients and incidents",
        url: "/reports/quarterly",
    }
}