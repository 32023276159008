// reporting types
export const PROGRAM_TOTALS = "PROGRAM_TOTALS";
export const QUARTERLY = "QUARTERLY";

// reporting period types
export const PERIOD_MONTHLY = "PERIOD_MONTHLY";
export const PERIOD_QUARTERLY = "PERIOD_QUARTERLY";

// quarterly reporting types
export const INTOX_DRUG_AFFECTED_BY_CATEGORY = "INTOX_DRUG_AFFECTED_BY_CATEGORY";
export const ESCORTED_PHYSICALLY_REFERRED_TO_SERVICES = "ESCORTED_PHYSICALLY_REFERRED_TO_SERVICES";
export const ESCORTED_PHYSICALLY_REFERRED_BY_SERVICES = "ESCORTED_PHYSICALLY_REFERRED_BY_SERVICES";
export const RISK_REDUCED_DUE_TO_SK = "RISK_REDUCED_DUE_TO_SK";