import React, {Component} from 'react';
import {
    NewSemanticReduxFormCheckbox,
    SemanticReduxFormRadioGroup
} from '../../../utilities/SemanticReduxFormField'
import {Field as ReduxField} from 'redux-form'

import { Divider, Form, Grid, Header } from 'semantic-ui-react'
import ActionButtons from './ActionButtons'
import { withReduxForm } from './with-redux-form'
import {
    clientConsciousnessOptions,
    clientValuablesVisibilityOptions,
    clientLostPropertyOptions,
    sexualAssaultRiskOptions,
    physicalAssaultRiskOptions,
    injuryRiskOptions
} from '../../../constants/SelectableListOptions'
import { numberNormalization } from '../../../utilities/ValuesNormalizationOptions'
import { notNull } from '../../../utilities/ReduxFormValidators';

class RiskMinimizationForm extends Component {

    updateRiskMinimization = (event, data) => {
        event.preventDefault();
        this.setState({
            [data.name]: data.checked
        });
    };

    changeFormSection = (bubbleUpValue) => {
        this.props.changeFormSection(bubbleUpValue)
    };

    cancelForm = () => {
        this.props.cancelForm()
    };

    render() {
        const {
            handleSubmit
        } = this.props;

        return (
            <Grid container>
                <Grid.Row>
                    <Header as='h2' content='Risk Minimisation'/>

                    <Form
                        size='large'
                        className='incidentFormSegment'
                        onSubmit={handleSubmit} //handleSubmit is provided by redux-form ??
                    >
                        <ReduxField
                            name='physicalAssaultRisk'
                            component={SemanticReduxFormRadioGroup}
                            normalize={numberNormalization}
                            fieldLabel='Physical Assault Risk'
                            trueFalseRadio={false}
                            optionWidths={4}
                            radioButtonList={physicalAssaultRiskOptions}
                        />
                        <Divider/>
                        <ReduxField
                            name='sexualAssaultRisk'
                            component={SemanticReduxFormRadioGroup}
                            normalize={numberNormalization}
                            fieldLabel='Sexual Assault Risk'
                            trueFalseRadio={false}
                            optionWidths={4}
                            radioButtonList={sexualAssaultRiskOptions}
                        />
                        <Divider/>
                        <Form.Field label='Theft Risk'/>
                        <Grid stackable>
                            <Grid.Row columns='equal'>
                                <Grid.Column>
                                    <ReduxField
                                        name='clientConsciousness'
                                        component={SemanticReduxFormRadioGroup}
                                        validate={notNull}
                                        trueFalseRadio={false}
                                        fieldLabel='Client Consciousness'
                                        normalize={numberNormalization}
                                        grouped={true}
                                        radioButtonList={clientConsciousnessOptions}
                                    />
                                </Grid.Column>
                                <Grid.Column>
                                    <ReduxField
                                        name='clientValuablesVisibility'
                                        component={SemanticReduxFormRadioGroup}
                                        validate={notNull}
                                        trueFalseRadio={false}
                                        fieldLabel='Valuables Visibility'
                                        normalize={numberNormalization}
                                        grouped={true}
                                        radioButtonList={clientValuablesVisibilityOptions}
                                    />
                                </Grid.Column>
                                <Grid.Column>
                                    <ReduxField
                                        name='clientLostProperty'
                                        component={SemanticReduxFormRadioGroup}
                                        validate={notNull}
                                        trueFalseRadio={false}
                                        fieldLabel='Lost Property'
                                        normalize={numberNormalization}
                                        grouped={true}
                                        radioButtonList={clientLostPropertyOptions}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                        <Divider/>
                        <ReduxField
                            name='injury'
                            component={NewSemanticReduxFormCheckbox}
                            fieldLabel='Injury Risk'
                            requiredAsterisk={false}
                            grouped={false}
                            widths={4}
                            checkboxOptions={injuryRiskOptions}
                        />
                        <Divider />

                        <ActionButtons {...this.props}/>

                    </Form>
                </Grid.Row>
                <Grid.Row
                    // Padding Row othewise bottom elements (buttons, tables) have no padding on mobile responsive mode
                />
            </Grid>
        )
    }
}

export default withReduxForm(RiskMinimizationForm)
