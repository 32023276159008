import React from 'react';
import PropTypes from 'prop-types';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/material_blue.css';
import { DateTime } from 'luxon';
import {
  Form,
  Grid,
  Input,
  Modal,
  Button,
  Label,
  Dropdown,
  Segment,
  Statistic,
  Icon,
} from 'semantic-ui-react';
import _ from 'lodash';

import IncidentMapComponent from '../Components/MyForms/IncidentMapComponent';

// NEW STUFF TO USE
export class SemanticReduxFormDateInput extends React.Component {
  static propTypes = {
    enableTime: PropTypes.bool.isRequired,
    fieldLabel: PropTypes.string.isRequired,
    input: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired,
    displayFormat: PropTypes.string.isRequired,
  };

  onChange = (selectedDates, dateString, instance) => {    // below if for when the user clicks 'clear' on the native dateTime selector. Will automatically get current time again
    if (_.isEmpty(dateString)) {
      dateString = Math.floor(DateTime.local().valueOf() / 1000);
    }
    // alert(dateString);
    return this.props.input.onChange(dateString * 1000);
  };

  render() {
    const {
      displayFormat,
      enableTime,
      fieldLabel,
      input, // passed in from reduxForm. Needed for validation and clearing values with reset method
      meta: { touched, error, warning },
    } = this.props;

    return (
      <Form.Field>
        <label>
          {fieldLabel}
          {touched &&
            ((error && (
              <Label color="red" pointing="left" size="tiny">
                {error}
              </Label>
            )) ||
              (warning && (
                <Label color="yellow" pointing="left" size="tiny">
                  {warning}
                </Label>
              )))}
        </label>
        <Flatpickr
          data-alt-format={displayFormat}
          data-alt-input={true}
          data-enable-time={enableTime}
          data-default-hour={0}
          data-minute-increment={1}
          data-date-format="U"
          data-default-date="today"
          data-time_24hr={true}
          // data-disable-mobile={true} // uncomment to force use of flatpickr popup selector instead of native datetime picker
          value={input.value}
          onChange={this.onChange}
        />
      </Form.Field>
    );
  }
}

export class SemanticReduxFormInputField extends React.Component {
  onChange = (event) => {
    this.props.input.onChange(event.target.value);
  };

  render() {
    const {
      placeholder,
      type,
      fieldLabel,
      autoComplete,
      requiredField,
      width,
      input, // passed in from reduxForm. Needed for validation and clearing values with reset method
      meta: { touched, error, warning },
    } = this.props;
    return (
      <Form.Field
        required={requiredField}
        error={touched && !_.isEmpty(error)} // put here and not on input so the whole thing is detected as error
        width={width}
      >
        <label>
          {fieldLabel}
          {touched &&
            ((error && (
              <Label color="red" pointing="left" size="tiny">
                {error}
              </Label>
            )) ||
              (warning && (
                <Label color="yellow" pointing="left" size="tiny">
                  {warning}
                </Label>
              )))}
        </label>
        <input
          {...input}
          placeholder={placeholder}
          type={type}
          onChange={this.onChange}
          autoComplete={autoComplete}
        />
      </Form.Field>
    );
  }
}

export class SemanticReduxFormTextArea extends React.Component {
  handleChange = (event) => {
    this.props.input.onChange(event);
  };

  render() {
    const {
      input,
      meta: { touched, error, warning },
      fieldLabel,
      autoComplete,
      placeholder,
      requiredField,
      rows,
    } = this.props;

    return (
      <Form.Field
        required={requiredField}
        error={touched && !_.isEmpty(error)} // put here and not on input so the whole thing is detected as error
      >
        <label>
          {fieldLabel}
          {touched &&
            ((error && (
              <Label color="red" pointing="left" size="tiny">
                {error}
              </Label>
            )) ||
              (warning && (
                <Label color="yellow" pointing="left" size="tiny">
                  {warning}
                </Label>
              )))}
        </label>
        <textarea
          {...input}
          onChange={this.handleChange}
          autoComplete={autoComplete}
          placeholder={placeholder}
          rows={rows}
        />
      </Form.Field>
    );
  }
}

export class SemanticReduxFormSelect extends React.Component {
  // Slightly different because the <Dropdown /> is a semantic component and fires its event and data seperately
  // here we're passing the value straight into the form
  handleChange = (event, data) => {
    this.props.input.onChange(data.value);
  };

  render() {
    const {
      input, // passed from redux-forms
      fieldLabel, // label at the top of the dropdown field
      requiredField, // red asterisk to highlight required
      selectionOptions, // array of objects with { value: <string/num>, text: <string> }
      placeholder, // placeholder text for the field
      width,
      meta: {
        // passed from redux-forms
        touched,
        error,
        warning,
      },
    } = this.props;

    return (
      <Form.Field
        required={requiredField}
        error={touched && !_.isEmpty(error)} // put here and not on input so the whole thing is detected as error
        width={width}
      >
        <label>
          {fieldLabel}
          {touched &&
            ((error && (
              <Label color="red" pointing="left" size="tiny">
                {error}
              </Label>
            )) ||
              (warning && (
                <Label color="yellow" pointing="left" size="tiny">
                  {warning}
                </Label>
              )))}
        </label>
        <Dropdown
          {...input}
          selection
          options={selectionOptions}
          onChange={this.handleChange}
          placeholder={placeholder}
        />
      </Form.Field>
    );
  }
}

export class SemanticReduxFormRadioGroup extends React.Component {
  handleChange = (event, data) => {
    this.props.input.onChange(data.value);
  };

  // To handle Radio Yes/No buttons with true/false values.
  handleTrueFalseChecked = (options) => {
    if (this.props.trueFalseRadio) {
      return options.value === this.props.input.value.toString();
    }
    return options.value === this.props.input.value;
  };

  render() {
    const {
      input,
      fieldLabel,
      requiredLabel,
      radioButtonList,
      optionWidths, // use either optionWidths (with specified width of generated radio elements) or grouped not both
      grouped,
      meta: { touched, error, warning },
    } = this.props;
    return (
      <Form.Field error={touched && !!error}>
        <Form.Field required={requiredLabel} key={input.name}>
          <label>
            {fieldLabel}
            {touched &&
              ((error && (
                <Label color="red" pointing="left" size="tiny">
                  {error}
                </Label>
              )) ||
                (warning && (
                  <Label color="yellow" pointing="left" size="tiny">
                    {warning}
                  </Label>
                )))}
          </label>
        </Form.Field>
        <Form.Group
          grouped={grouped}
          widths={optionWidths}
          key={input.name + '.group'}
        >
          {radioButtonList.map((options) => {
            return (
              <Form.Radio
                {...input}
                key={options.label}
                label={options.label}
                value={options.value}
                onChange={this.handleChange}
                checked={this.handleTrueFalseChecked(options)}
              />
            );
          })}
        </Form.Group>
      </Form.Field>
    );
  }
}

export class SemanticReduxFormCheckbox extends React.Component {
  handleChange = (event, data) => {
    this.props.input.onChange(data.checked);
  };

  render() {
    const { input, checkboxLabel, width } = this.props;
    return (
      <Form.Checkbox
        label={checkboxLabel}
        checked={input.value === '' ? false : input.value}
        onChange={this.handleChange}
        width={width}
      />
    );
  }
}

export class NewSemanticReduxFormCheckbox extends React.Component {
  handleChange = (event, data) => {
    const newFormValue = {
      ...this.props.input.value,
      [data.name]: data.checked,
    };
    // console.log("NEW INPUT VALUE", newFormValue);
    this.props.input.onBlur(newFormValue);
    this.props.input.onChange(newFormValue);
  };

  generateCheckboxes = (optionsList) => {
    if (optionsList.length > 0) {
      return optionsList.map((option) => {
        return (
          <Form.Checkbox
            key={option.name}
            label={option.label}
            checked={this.props.input.value[option.name]}
            onChange={this.handleChange}
            name={option.name}
          />
        );
      });
    } else {
      return <div>No Options!</div>;
    }
  };

  render() {
    const {
      fieldLabel,
      requiredAsterisk, // if you need a red asterisk to mark whether a user is required to fill in the field
      grouped, // if you want the checkboxes to render vertically on top of each other specify this as true
      widths, // how many semantic-ui grid columns you want the checkboxes spread out over. Can also take value of 'equal' which spread them evenly
      checkboxOptions,
      secondRowOptions, // semantic-ui doesn't reflow checkbox/radio button element properly if you want them to appear in two rows.
      meta: { touched, error },
    } = this.props;

    return (
      <Form.Field
        error={touched && !!error} // put here and not on input so the whole thing is detected as error
      >
        <Form.Field
          required={requiredAsterisk}
          /* this Form.Field component is put here so there is 'spacing' between the form label and the options below */
        >
          <label>
            {fieldLabel}
            {touched && error && (
              <Label color="red" pointing="left" size="tiny">
                {error}
              </Label>
            )}
          </label>
        </Form.Field>
        <Form.Group grouped={grouped} widths={widths}>
          {this.generateCheckboxes(checkboxOptions)}
        </Form.Group>
        {
          // this is terrible. Don't do this if possible. But there's too many checkboxes to fit into one row...
          secondRowOptions && (
            <Form.Group grouped={grouped} widths={widths}>
              {this.generateCheckboxes(secondRowOptions)}
            </Form.Group>
          )
        }
      </Form.Field>
    );
  }
}

export class SemanticReduxFormNumberField extends React.Component {
  handleChange = (event, data) => {
    let updatedValue = this.props.input.value;

    if (data.name === 'increment') {
      updatedValue++;
    } else if (data.name === 'decrement') {
      // prevents adding negative clients
      if (updatedValue > 0) {
        updatedValue--;
      }
    }
    this.props.input.onChange(updatedValue);
  };

  render() {
    return (
      <Grid.Column mobile={16} tablet={8} computer={4} textAlign="center">
        <Segment basic={this.props.basicSegment} color={this.props.color}>
          <Statistic>
            <Statistic.Label>{this.props.fieldLabel}</Statistic.Label>
            <Statistic.Value>
              {this.props.icon !== null && (
                <Icon
                  name={this.props.icon}
                  size="small"
                  className="assistanceRenderedIcon"
                />
              )}
              {this.props.input.value}
            </Statistic.Value>
          </Statistic>
          <Button.Group fluid>
            <Button
              type="button"
              color="red"
              icon="minus"
              name="decrement"
              onClick={this.handleChange}
            />
            <Button
              type="button"
              color="green"
              icon="plus"
              name="increment"
              onClick={this.handleChange}
            />
          </Button.Group>
        </Segment>
      </Grid.Column>
    );
  }
}

// OLD STUFF TO REBUILD

// const formatLabel = (labelText, error, touched, required) => {
//   let formattedLabel = (
//     <span>
//       {labelText}
//       {required ? '*' : ''}
//     </span>
//   );
//   if (touched && error) {
//     return (
//       <span>
//         {formattedLabel} (<i style={{ color: 'red' }}>{error}</i>)
//       </span>
//     );
//   } else {
//     return formattedLabel;
//   }
// };

export const SemanticReduxInputField = ({
  input,
  type,
  label,
  placeholder,
  meta: { touched, error, warning },
  as: As = Input,
  required,
  ...props
}) => {
  function handleChange(e, { value }) {
    // console.log("SemanticReduxInputField", value);
    return input.onChange(value);
  }

  //   const formattedLabel = formatLabel(label, error, touched, required); // TODO: Implement this for form validation

  return (
    <Form.Field>
      <As
        {...props}
        {...input}
        value={input.value}
        type={type}
        label={label}
        placeholder={placeholder}
        onChange={handleChange}
      />
    </Form.Field>
  );
};

export class LocationInput extends React.Component {
  static propTypes = {
    value: PropTypes.any.isRequired,
    label: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {
      description: '',
      address: '',
      draft: true,
      open: false,
    };
  }

  handleChange = ({ value }) => {
    const newValue = value;
    return this.props.onChange(null, { value: newValue });
  };

  onSemanticInputAddressChange = (event, data) => {
    const value = {
      address: data.value,
      latitude: 0,
      longitude: 0,
    };
    this.handleChange({ value: value });
  };

  onMapComponentAddressChange = ({ latitude, longitude, address }) => {
    const value = {
      address: address,
      latitude: latitude,
      longitude: longitude,
    };
    this.setState({ mapLocation: value });
  };

  showMap = (dimmer) => () => this.setState({ dimmer, open: true });
  cancelMap = () => {
    this.setState({ open: false, mapLocation: null });
  };
  saveMap = () => {
    const value = this.state.mapLocation || {
      address: '',
      latitude: 0,
      longitude: 0,
    };
    this.handleChange({ value: value });
    this.setState({ open: false, mapLocation: null });
  };

  render() {
    const { open, dimmer } = this.state;
    const { label, placeholder } = this.props;

    const { address } = this.props.value;
    return (
      <div>
        <Form.Input
          key={'input-field'}
          action={
            <Button
              type={'button'}
              icon="location arrow"
              color="blue"
              onClick={this.showMap(true)}
            />
          }
          icon="marker"
          iconPosition="left"
          placeholder={placeholder}
          label={label}
          name="incidentAddress"
          value={address ? address : ''}
          onChange={this.onSemanticInputAddressChange}
        />

        <Modal dimmer={dimmer} open={open} onClose={this.closeMap}>
          <Modal.Header>Select Location of Incident</Modal.Header>
          <IncidentMapComponent
            value={this.props.value}
            onAddressResolve={this.onMapComponentAddressChange}
            onLocationSelect={this.onMapComponentAddressChange}
          />
          <Modal.Actions>
            <Button
              negative
              type={'button'}
              icon="remove"
              labelPosition="right"
              content="Cancel"
              onClick={this.cancelMap}
            />
            <Button
              positive
              type={'button'}
              icon="checkmark"
              labelPosition="right"
              content="Confirm"
              onClick={this.saveMap}
            />
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}
