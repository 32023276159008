import React, { Component } from 'react';

import { Divider, Form, Grid, Header } from 'semantic-ui-react';
import { Field as ReduxField } from 'redux-form';
import { NewSemanticReduxFormCheckbox } from '../../../utilities/SemanticReduxFormField';
import ActionButtons from './ActionButtons';
import { withReduxForm } from './with-redux-form';
import {
  reconnectionOptions,
  directionsOptions,
  transportInformationOptions,
  escortedToOptions,
  safeSpaceEscortOptions,
} from '../../../constants/SelectableListOptions';

class BasicSupportForm extends Component {
  changeFormSection = (bubbleUpValue) => {
    this.props.changeFormSection(bubbleUpValue);
  };

  cancelForm = () => {
    this.props.cancelForm();
  };

  render() {
    const { handleSubmit } = this.props;

    return (
      <Grid container>
        <Grid.Row>
          <Header as="h2" content="Basic Support" />
          <Form
            onSubmit={handleSubmit}
            size="large"
            className="incidentFormSegment"
          >
            <ReduxField
              name="reconnection"
              component={NewSemanticReduxFormCheckbox}
              fieldLabel="Reconnection"
              requiredAsterisk={false}
              grouped={false}
              widths={4}
              checkboxOptions={reconnectionOptions}
            />
            <Divider />
            <ReduxField
              name="directions"
              component={NewSemanticReduxFormCheckbox}
              fieldLabel="Directions"
              requiredAsterisk={false}
              grouped={false}
              widths={4}
              checkboxOptions={directionsOptions}
            />
            <Divider />
            <ReduxField
              name="transportInformation"
              component={NewSemanticReduxFormCheckbox}
              fieldLabel="Transport Information"
              requiredAsterisk={false}
              grouped={false}
              widths={4}
              checkboxOptions={transportInformationOptions}
            />
            <Divider />
            <ReduxField
              name="escortedTo"
              component={NewSemanticReduxFormCheckbox}
              fieldLabel="Escort"
              requiredAsterisk={false}
              grouped={false}
              widths={4}
              checkboxOptions={escortedToOptions}
            />
            <Divider />
            <ReduxField
              name="safeSpace"
              component={NewSemanticReduxFormCheckbox}
              fieldLabel="Safe Space"
              requiredAsterisk={false}
              grouped={false}
              widths={4}
              checkboxOptions={safeSpaceEscortOptions}
            />
            <Divider />

            <ActionButtons {...this.props} />
          </Form>
        </Grid.Row>
        <Grid.Row
        // Padding Row othewise bottom elements (buttons, tables) have no padding on mobile responsive mode
        />
      </Grid>
    );
  }
}

export default withReduxForm(BasicSupportForm);
