import React from 'react';
import { Grid, Header, List, Segment } from 'semantic-ui-react';
import { connect } from 'react-redux';
import * as actions from '../../actions/index';
import ProfileEditModal from './ProfileEditModal';
import _ from 'lodash';

class Profile extends React.Component {
    triggerModal = () =>
        this.setState({
            editModalOpen: !this.state.editModalOpen,
        });

    render() {
        const { firstName, lastName, userLevel, email, site } =
            this.props.profile;

        return (
            <Grid container>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Header as='h1' className='sectionHeader'>
                            My Profile
                        </Header>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row centered>
                    <Grid.Column
                        computer={8}
                        largeScreen={8}
                        tablet={12}
                        mobile={16}
                    >
                        <Segment padded color='blue'>
                            <Header>User Details</Header>
                            <List relaxed>
                                <List.Item>
                                    <List.Icon
                                        name='user'
                                        size='big'
                                        verticalAlign='middle'
                                        className='profileUserDetailIcons'
                                    />
                                    <List.Content>
                                        <List.Header>Name</List.Header>
                                        <List.Description>
                                            {firstName} {lastName}
                                        </List.Description>
                                    </List.Content>
                                </List.Item>
                                <List.Item>
                                    <List.Icon
                                        name='drivers license'
                                        size='big'
                                        verticalAlign='middle'
                                        className='profileUserDetailIcons'
                                    />
                                    <List.Content>
                                        <List.Header>User Level</List.Header>
                                        <List.Description>
                                            {_.startCase(userLevel)}
                                        </List.Description>
                                    </List.Content>
                                </List.Item>
                                <List.Item>
                                    <List.Icon
                                        name='mail'
                                        size='big'
                                        verticalAlign='middle'
                                        className='profileUserDetailIcons'
                                    />
                                    <List.Content>
                                        <List.Header>Email</List.Header>
                                        <List.Description>
                                            {email}
                                        </List.Description>
                                    </List.Content>
                                </List.Item>
                                <List.Item>
                                    <List.Icon
                                        name='marker'
                                        size='big'
                                        verticalAlign='middle'
                                        className='profileUserDetailIcons'
                                    />
                                    <List.Content>
                                        <List.Header>My SafeBase</List.Header>
                                        <List.Description>
                                            {_.startCase(site)}
                                        </List.Description>
                                    </List.Content>
                                </List.Item>
                            </List>
                        </Segment>
                        <ProfileEditModal />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row
                // extra row for spacing
                />
            </Grid>
        );
    }
}

const mapStateToProps = ({ auth, profile }) => {
    return {
        auth,
        profile,
    };
};

export default connect(mapStateToProps, actions)(Profile);
