import streetKindLogo from "../../../assets/street-kind-logo-black.svg";
import _, {capitalize} from "lodash";
import {matchValues} from "../../../utilities/ViewerUtils";
import {
    ageGroupOptions, clientConsciousnessOptions, clientLostPropertyOptions, clientValuablesVisibilityOptions,
    genderOptions, labelOptions,
    physicalAssaultRiskOptions,
    sexualAssaultRiskOptions
} from "../../../constants/SelectableListOptions";
import React from "react";
import moment from "moment/moment";
import {Image} from "semantic-ui-react";

const IncidentPDFContent = ({formData, incidentClientsData}) => {

    const displayDate = (timestamp) => moment(timestamp).format("ddd, Do MMM YYYY, h:mm A");
    const displayList = (items) => {
        if (Array.isArray(items)) {
            return items.filter(Boolean).join(', ') || 'None';
        } else if (typeof items === 'object' && items !== null) {
            return (Object.keys(items).filter(key => items[key]).map(label => labelOptions[label] ?? capitalize(label)).join(', ')) || 'No';
        } else {
            return 'None';
        }
    };

    // Using plain html and css to render inside PDF

    return (
        <div style={{fontFamily: 'Arial, sans-serif', fontSize: '15px', padding: '15px', width: '100%'}}>
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px'}}>
                <h1 style={{
                    color: '#a1cd63',
                    fontSize: '28px',
                    margin: 0,
                    fontFamily: 'Lato,Helvetica Neue,Arial,Helvetica,sans-serif'
                }}>Incident Report</h1>
                <Image src={streetKindLogo} alt="StreetKind Logo" style={{width: '100px'}}/>
            </div>
            <div style={{
                backgroundColor: '#ffffff',
                padding: '20px',
                marginBottom: '20px',
                borderRadius: '4px',
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
            }}>
                <h2 style={{color: '#484848', fontSize: '20px', marginBottom: '10px'}}>Incident Details</h2>
                <table style={{width: '100%', borderCollapse: 'collapse'}}>
                    <tbody>
                    <tr>
                        <td style={{padding: '6px', textAlign: "start", borderBottom: '1px solid #e0e0e0'}}><strong>Team Leader Name</strong></td>
                        <td style={{
                            padding: '6px',
                            textAlign: "start",
                            borderBottom: '1px solid #e0e0e0'
                        }}>{formData.teamLeaderName || 'N/A'}</td>
                    </tr>
                    <tr>
                        <td style={{padding: '6px', textAlign: "start", borderBottom: '1px solid #e0e0e0'}}>
                            <strong>Site</strong></td>
                        <td style={{
                            padding: '6px',
                            textAlign: "start",
                            borderBottom: '1px solid #e0e0e0'
                        }}>{_.startCase(formData.site) || 'N/A'}</td>
                    </tr>
                    <tr>
                        <td style={{padding: '6px', textAlign: "start", borderBottom: '1px solid #e0e0e0'}}><strong>Incident
                            Location</strong></td>
                        <td style={{
                            padding: '6px',
                            textAlign: "start",
                            borderBottom: '1px solid #e0e0e0'
                        }}>{_.get(formData, 'location.address', 'N/A')}</td>
                    </tr>
                    <tr>
                        <td style={{padding: '6px', textAlign: "start", borderBottom: '1px solid #e0e0e0'}}><strong>Start Time</strong></td>
                        <td style={{
                            padding: '6px',
                            textAlign: "start",
                            borderBottom: '1px solid #e0e0e0'
                        }}>{formData.startTime ? displayDate(formData.startTime) : 'N/A'}</td>
                    </tr>
                    <tr>
                        <td style={{padding: '6px', textAlign: "start", borderBottom: '1px solid #e0e0e0'}}><strong>End Time</strong></td>
                        <td style={{
                            padding: '6px',
                            textAlign: "start",
                            borderBottom: '1px solid #e0e0e0'
                        }}>{formData.startTime ? displayDate(formData.endTime) : 'N/A'}</td>
                    </tr>
                    <tr>
                        <td style={{padding: '6px', textAlign: "start", borderBottom: '1px solid #e0e0e0'}}><strong>Encountered By</strong></td>
                        <td style={{
                            padding: '6px',
                            textAlign: "start",
                            borderBottom: '1px solid #e0e0e0'
                        }}>{displayList(formData.encounteredBy)}</td>
                    </tr>
                    <tr>
                        <td style={{padding: '6px', textAlign: "start", borderBottom: '1px solid #e0e0e0'}}><strong>Other Services</strong></td>
                        <td style={{
                            padding: '6px',
                            textAlign: "start",
                            borderBottom: '1px solid #e0e0e0'
                        }}>{displayList(formData.otherServicesInvolved)}</td>
                    </tr>
                    <tr>
                        <td style={{padding: '6px', textAlign: "start", borderBottom: '1px solid #e0e0e0'}}><strong>Incident Description</strong></td>
                        <td style={{
                            padding: '6px',
                            textAlign: "start",
                            borderBottom: '1px solid #e0e0e0'
                        }}>{formData.incidentDescription}</td>
                    </tr>
                    <tr>
                        <td style={{padding: '6px', textAlign: "start"}}><strong>Incident Outcome</strong></td>
                        <td style={{
                            padding: '6px',
                            textAlign: "start"
                        }}>{formData.incidentOutcome}</td>
                    </tr>
                    </tbody>
                </table>
            </div>

            {incidentClientsData.map((client, index) => (
                <div key={index} style={{
                    backgroundColor: '#ffffff',
                    padding: '20px',
                    marginBottom: '20px',
                    borderRadius: '4px',
                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                    pageBreakBefore: index > 0 ? 'always' : 'auto'
                }}>
                <h2 style={{color: '#484848', fontSize: '20px', marginBottom: '10px'}}>Client - {
                    (client.clientData.firstName || client.clientData.lastName) ? capitalize(`${client.clientData.firstName} ${client.clientData.lastName}`) : 'Unknown'}</h2>
                    <table style={{width: '100%', borderCollapse: 'collapse'}}>
                        <tbody style={{width: '100%'}}>
                        <tr>
                            <td style={{
                                width: '50%',
                                padding: '6px',
                                textAlign: "start",
                                verticalAlign: 'top',
                                borderBottom: '1px solid #e0e0e0'
                            }}>
                                <table style={{width: '100%', borderCollapse: 'collapse'}}>
                                    <tbody>
                                    <tr>
                                        <td style={{padding: '6px', textAlign: "start"}}><strong>Gender</strong></td>
                                        <td style={{
                                            padding: '6px',
                                            textAlign: "start"
                                        }}>{matchValues(client.clientData.gender, genderOptions)}</td>
                                    </tr>
                                    <tr>
                                        <td style={{padding: '6px', textAlign: "start"}}><strong>Age Group</strong></td>
                                        <td style={{
                                            padding: '6px',
                                            textAlign: "start"
                                        }}>{matchValues(client.clientData.ageGroup, ageGroupOptions)}</td>
                                    </tr>
                                    <tr>
                                        <td style={{padding: '6px', textAlign: "start", alignContent: 'flex-start'}}>
                                            <strong>Contact
                                                Number</strong></td>
                                        <td style={{
                                            padding: '6px',
                                            textAlign: "start"
                                        }}>{client.clientData.contactNumber || '-'}</td>
                                    </tr>
                                    <tr>
                                        <td style={{padding: '6px', textAlign: "start"}}><strong>Email</strong></td>
                                        <td style={{
                                            padding: '6px',
                                            textAlign: "start"
                                        }}>{client.clientData.email || '-'}</td>
                                    </tr>
                                    <tr>
                                        <td style={{padding: '6px', textAlign: "start"}}><strong>Suburb</strong></td>
                                        <td style={{
                                            padding: '6px',
                                            textAlign: "start"
                                        }}>{capitalize(client.clientData.suburb) || '-'}</td>
                                    </tr>
                                    <tr>
                                        <td style={{padding: '6px', textAlign: "start"}}><strong>Alone</strong></td>
                                        <td style={{
                                            padding: '6px',
                                            textAlign: "start"
                                        }}>{client.clientData.alone ? 'Yes' : 'No'}</td>
                                    </tr>
                                    <tr>
                                        <td style={{padding: '6px', textAlign: "start"}}><strong>Drug Use Signs</strong>
                                        </td>
                                        <td style={{
                                            padding: '6px',
                                            textAlign: "start"
                                        }}>{displayList(client.clientData.drugUseSigns)}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </td>
                            <td style={{
                                width: '50%',
                                padding: '6px', textAlign: "start",
                                verticalAlign: 'top',
                                borderBottom: '1px solid #e0e0e0'
                            }}>
                                <table style={{width: '100%', borderCollapse: 'collapse'}}>
                                    <tbody>
                                    <tr>
                                        <td style={{padding: '6px', textAlign: "start"}}><strong>Intoxication
                                            Signs</strong></td>
                                        <td style={{
                                            padding: '6px',
                                            textAlign: "start"
                                        }}>{displayList(client.clientData.intoxicationSigns)}</td>
                                    </tr>
                                    <tr>
                                        <td style={{padding: '6px', textAlign: "start"}}><strong>Offensive
                                            Behaviour</strong></td>
                                        <td style={{
                                            padding: '6px',
                                            textAlign: "start"
                                        }}>{displayList(client.clientData.offensiveBehaviour)}</td>
                                    </tr>
                                    <tr>
                                        <td style={{padding: '6px', textAlign: "start"}}><strong>Self Harm
                                            Signs</strong></td>
                                        <td style={{
                                            padding: '6px',
                                            textAlign: "start"
                                        }}>{displayList(client.clientData.selfHarmSigns)}</td>
                                    </tr>
                                    <tr>
                                        <td style={{padding: '6px', textAlign: "start"}}><strong>Suicidal Signs</strong>
                                        </td>
                                        <td style={{
                                            padding: '6px',
                                            textAlign: "start"
                                        }}>{displayList(client.clientData.suicidalSigns)}</td>
                                    </tr>
                                    <tr>
                                        <td style={{padding: '6px', textAlign: "start"}}><strong>Sexual Assault</strong>
                                        </td>
                                        <td style={{
                                            padding: '6px',
                                            textAlign: "start"
                                        }}>{displayList(client.clientData.sexualAssault)}</td>
                                    </tr>
                                    <tr>
                                        <td style={{padding: '6px', textAlign: "start"}}><strong>Physical Assault</strong></td>
                                        <td style={{
                                            padding: '6px',
                                            textAlign: "start"
                                        }}>{displayList(client.clientData.physicalAssault)}</td>
                                    </tr>
                                    <tr>
                                        <td style={{padding: '6px', textAlign: "start"}}><strong>Domestic Violence</strong></td>
                                        <td style={{
                                            padding: '6px',
                                            textAlign: "start"
                                        }}>{displayList(client.clientData.domesticViolence)}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        </tbody>
                    </table>

                    <div style={{borderTop: '1px solid #e0e0e0', margin: '20px 0'}}></div>

                    <table style={{width: '100%', borderCollapse: 'collapse'}}>
                        <tbody>
                        <tr>
                            <td style={{width: '50%', padding: '6px', textAlign: "start", verticalAlign: 'top'}}>
                                <table style={{width: '100%', borderCollapse: 'collapse'}}>
                                    <tbody>
                                    <tr>
                                        <td style={{padding: '6px', textAlign: "start"}}><strong>Reconnection Support</strong></td>
                                        <td style={{
                                            padding: '6px',
                                            textAlign: "start"
                                        }}>{displayList(client.clientData.reconnection)}</td>
                                    </tr>
                                    <tr>
                                        <td style={{padding: '6px', textAlign: "start"}}><strong>Direction Support</strong></td>
                                        <td style={{
                                            padding: '6px',
                                            textAlign: "start"
                                        }}>{displayList(client.clientData.directions)}</td>
                                    </tr>
                                    <tr>
                                        <td style={{padding: '6px', textAlign: "start"}}><strong>Transport Information
                                            Support</strong></td>
                                        <td style={{
                                            padding: '6px',
                                            textAlign: "start"
                                        }}>{displayList(client.clientData.transportInformation)}</td>
                                    </tr>
                                    <tr>
                                        <td style={{padding: '6px', textAlign: "start"}}><strong>Escorted To
                                            Support</strong></td>
                                        <td style={{
                                            padding: '6px',
                                            textAlign: "start"
                                        }}>{displayList(client.clientData.escortedTo)}</td>
                                    </tr>
                                    <tr>
                                        <td style={{padding: '6px', textAlign: "start"}}><strong>SafeBase
                                            Support</strong></td>
                                        <td style={{
                                            padding: '6px',
                                            textAlign: "start"
                                        }}>{displayList(client.clientData.safeSpace)}</td>
                                    </tr>
                                    <tr>
                                        <td style={{padding: '6px', textAlign: "start"}}><strong>Basic Aid</strong></td>
                                        <td style={{
                                            padding: '6px',
                                            textAlign: "start"
                                        }}>{displayList(client.clientData.basicAid)}</td>
                                    </tr>
                                    <tr>
                                        <td style={{padding: '6px', textAlign: "start"}}><strong>Additional Aid</strong>
                                        </td>
                                        <td style={{
                                            padding: '6px',
                                            textAlign: "start"
                                        }}>{displayList(client.clientData.additionalAid)}</td>
                                    </tr>
                                    <tr>
                                        <td style={{padding: '6px', textAlign: "start"}}><strong>Emergency Services
                                            Called</strong></td>
                                        <td style={{
                                            padding: '6px',
                                            textAlign: "start"
                                        }}>{displayList(client.clientData.emergencyServicesCalled)}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </td>
                            <td style={{width: '50%', padding: '6px', textAlign: "start", verticalAlign: 'top'}}>
                                <table style={{width: '100%', borderCollapse: 'collapse'}}>
                                    <tbody>
                                    <tr>
                                        <td style={{padding: '6px', textAlign: "start"}}><strong>Physical Assault Risk</strong></td>
                                        <td style={{
                                            padding: '6px',
                                            textAlign: "start"
                                        }}>{matchValues(client.clientData.physicalAssaultRisk, physicalAssaultRiskOptions)}</td>
                                    </tr>
                                    <tr>
                                        <td style={{padding: '6px', textAlign: "start"}}><strong>Sexual Assault Risk</strong></td>
                                        <td style={{
                                            padding: '6px',
                                            textAlign: "start"
                                        }}>{matchValues(client.clientData.sexualAssaultRisk, sexualAssaultRiskOptions)}</td>
                                    </tr>
                                    <tr>
                                        <td style={{padding: '6px', textAlign: "start"}}><strong>Client
                                            Consciousness</strong></td>
                                        <td style={{
                                            padding: '6px',
                                            textAlign: "start"
                                        }}>{matchValues(client.clientData.clientConsciousness, clientConsciousnessOptions)}</td>
                                    </tr>
                                    <tr>
                                        <td style={{padding: '6px', textAlign: "start"}}><strong>Client Valuables
                                            Visibility</strong></td>
                                        <td style={{
                                            padding: '6px',
                                            textAlign: "start"
                                        }}>{matchValues(client.clientData.clientValuablesVisibility, clientValuablesVisibilityOptions)}</td>
                                    </tr>
                                    <tr>
                                        <td style={{padding: '6px', textAlign: "start"}}><strong>Client Lost
                                            Property</strong></td>
                                        <td style={{
                                            padding: '6px',
                                            textAlign: "start"
                                        }}>{matchValues(client.clientData.clientLostProperty, clientLostPropertyOptions)}</td>
                                    </tr>
                                    <tr>
                                        <td style={{padding: '6px', textAlign: "start"}}><strong>Injury Risk
                                            Minimised</strong></td>
                                        <td style={{
                                            padding: '6px',
                                            textAlign: "start"
                                        }}>{displayList(client.clientData.injury)}</td>
                                    </tr>
                                    <tr>
                                        <td style={{padding: '6px', textAlign: "start"}}><strong>Social Services
                                            Involved</strong></td>
                                        <td style={{
                                            padding: '6px',
                                            textAlign: "start"
                                        }}>{displayList(client.clientData.clientServiceReferrals)}</td>
                                    </tr>
                                    <tr>
                                        <td style={{padding: '6px', textAlign: "start"}}><strong>Social Services
                                            Contacted</strong></td>
                                        <td style={{
                                            padding: '6px',
                                            textAlign: "start"
                                        }}>{client.clientData.serviceInformation.contactedService ? 'Yes' : 'No'}</td>
                                    </tr>
                                    <tr>
                                        <td style={{padding: '6px', textAlign: "start"}}><strong>Social Services Info
                                            Provided</strong></td>
                                        <td style={{
                                            padding: '6px',
                                            textAlign: "start"
                                        }}>{client.clientData.serviceInformation.infoProvided ? 'Yes' : 'No'}</td>
                                    </tr>
                                    <tr>
                                        <td style={{padding: '6px', textAlign: "start"}}><strong>Welfare Check</strong>
                                        </td>
                                        <td style={{
                                            padding: '6px',
                                            textAlign: "start"
                                        }}>{client.clientData.welfareCheck ? 'Yes' : 'No'}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            ))}
        </div>
    )
}

export default IncidentPDFContent
