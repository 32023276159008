import React, { Component } from 'react';
import { Container, Divider, Header } from 'semantic-ui-react';
import UsersList from '../UsersList';
import withAuthorization from '../../hocs/withAuthorization';
import AllForms from "./AllForms/AllForms";

class Index extends Component {
  render() {
    return (
      <Container>
        <Header as="h1" className="sectionHeader">
          Administrator
        </Header>
        <AllForms isAdmin />
        <Divider />
        <UsersList />
      </Container>
    );
  }
}

export default withAuthorization('admin')(Index);
