import React from 'react';
import { Card, Button, Icon } from "semantic-ui-react";
import { withRouter } from "react-router-dom";

export const ReportTypeCard = ({ reportingTypeName, description, url, history }) => {
    return (
        <Card color='blue' raised>
            <Card.Content>
                <Card.Header>{reportingTypeName}</Card.Header>
                <Card.Description>
                    {description}
                </Card.Description>
            </Card.Content>
            <Card.Content extra>
                <Button floated='right' circular color='blue' onClick={() => history.push(url)}>Go! <Icon name='arrow right' /></Button>
            </Card.Content>
        </Card>
    )
};

export default withRouter(ReportTypeCard);