import React, { Component } from 'react';
import { Button, Modal } from 'semantic-ui-react';
import PropTypes from 'prop-types';

// causing an error when deleting:
// Can't perform a React state update on an unmounted component.
// This is a no-op, but it indicates a memory leak in your application.
// To fix, cancel all subscriptions and asynchronous tasks in the componentWillUnmount method.
export default class ConfirmDeleteReportModal extends Component {
  state = { deleting: false };

  handleDelete = async () => {
    const { reportsRef, reportId } = this.props;

    this.setState({
      deleting: true,
    });

    await reportsRef
      .child(reportId)
      .remove(() => {
        this.setState({
          deleting: false,
        });
        this.props.handleCancel();
      })
      .catch((error) => {
        console.log(
          'There was an error while attempting to delete this report!',
          error
        );
        this.setState({
          deleting: false,
        });
      });
  };

  render() {
    return (
      <Modal open={this.props.modalOpen} size="small">
        <Modal.Header>Confirm Delete Report?</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <p>Are you sure you wish to delete this report?</p>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            negative
            type="button"
            icon="remove"
            labelPosition="right"
            content="Cancel"
            onClick={this.props.handleCancel}
          />
          <Button
            positive
            type="button"
            icon="checkmark"
            content="Confirm"
            loading={this.state.deleting}
            onClick={this.handleDelete}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

ConfirmDeleteReportModal.propTypes = {
  handleCancel: PropTypes.func.isRequired,
  modalOpen: PropTypes.bool.isRequired,
  reportId: PropTypes.string,
};
