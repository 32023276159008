export default (values) => {
    const errors = {};
    if (!values.teamLeaderName) {
        errors.teamLeaderName = 'Required'
    }
    if (!values.startTime) {
        errors.startTime = 'Required'
    }
    if (!values.endTime) {
        errors.endTime = 'Required'
    }
    if (values.startTime > values.endTime) {
        errors.startTime = 'Incident Start Time must be before Incident End Time';
        errors.endTime = 'Incident End Time must be after Incident Start Time';
    }
    if (!values.site) {
        errors.site = 'Required'
    }
    if (!values.incidentDescription) {
        errors.incidentDescription = 'Required'
    }
    if (!values.incidentOutcome) {
        errors.incidentOutcome = 'Required'
    }
    return errors;
}