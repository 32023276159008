import React from 'react'
import { initialize as initializeFormAction, reset} from 'redux-form'
import { DateTime } from 'luxon'
import { connect } from 'react-redux';

import { db } from '../../../firebase/index';
import createModel from '../../../Models/incident';
import QuickForm from './QuickForm';

class QuickFormComponent extends React.Component {

    componentDidMount() {

        const initializeQuickFormDataModel = () => {
            // pull firstName and lastName from auth prop
            const { uid } = this.props.auth;
            const { firstName, lastName, site } = this.props.profile;

            // console.log(this.props.auth);

            // prepopulate model with data and return it to be put into reduxForm
            let incidentModel = createModel();
            const currentTime = DateTime.local();
            incidentModel = {
                ...incidentModel,
                createdDate: currentTime.valueOf(),
                createdBy: uid,
                teamLeaderName: firstName + ' ' + lastName,
                startTime: currentTime.valueOf(),
                endTime: currentTime.plus({minutes: 5}).valueOf(),
                site: site
            };

            console.log("incidentModel", incidentModel);
            return incidentModel ;
        };

        this.props.initializeFormAction('incident', initializeQuickFormDataModel())
    }

    onSubmit = async (data) => {
        console.log("QuickForm onSubmit data ", data);
        // this.props.onSubmit(data); // from MyForms.js
        await db.getRefOfIncidentFormsNode().push(data);
        // update the lastActive of the user
        await db.getLastActiveFromUsersNodeWithUserUID(this.props.auth.uid).set(DateTime.local().valueOf());
        this.props.reset('incident');
    };

    render() {
        window.scrollTo(0, 0);
        return (
            <QuickForm onSubmit={this.onSubmit} />
        )
    }
}

const mapStateToProps = ({ auth, profile }) => {
    return {
        auth,
        profile
    }
};

export default connect(mapStateToProps, { initializeFormAction: initializeFormAction, reset: reset })(QuickFormComponent)
