import { db } from './firebase';
import { MONTHLY_QUARTERLY_REPORTS } from "../utilities/DataNodeConstants";

// TODO: Replace all magic strings with values cound in DataNodeConstants.js
export const getRefOfIncidentFormsNode = () => {
    return db.ref('incidentForms/');
};

export const getRefOfVolunteerHoursFormsNode = () => {
    return db.ref('volunteerHoursForm/')
}
export const getRefOfVolunteerHoursFormsNodeWithVolunteerHourFormId = (volunteerHourFormId) => {
    return db.ref('volunteerHoursForm/' + volunteerHourFormId);
}

export const getRefOfVolunteerHoursNode = () => {
    return db.ref('volunteerHours/')
}

export const getIncidentFormRefFromIncidentFormNodeWithIncidentFormId = (incidentFormId) => {
    return db.ref('incidentForms/' + incidentFormId);
};

export const incidentFormExists = async (incidentFormId)=> {
    return await db.ref('incidentForms/' + incidentFormId).once('value').then(snapshot => { return snapshot.exists() })
};

export const getStartTimeRefFromIncidentNodeWithIncidentFormId = async (incidentFormId) => {
    const startTime = await db.ref('incidentForms/' + incidentFormId + '/startTime/').once("value").then(snapshot => { return snapshot.val()});
    console.log("StartTime", startTime);
    return startTime;
};

export const getClientListFromIncidentFormsWithIncidentFormid = async (incidentFormId) => {
    return await db.ref('incidentForms/' + incidentFormId + '/clientList').once("value").then(snapshot => { return snapshot.val() });
};

export const getClientListRefFromIncidentFormsWithIncidentFormId = (incidentFormId) => {
    return db.ref('incidentForms/' + incidentFormId + '/clientList');
};

export const getClientListRefWithIncidentFormId = (incidentFormId) => {
    return db.ref('clients/').orderByChild('incidentId').equalTo(incidentFormId);
};

export const getClientListRefFromClientNode = () => {
    return db.ref('clients/');
};

export const clientFormExists = async (clientFormId) => {
    return await db.ref('clients/' + clientFormId).once('value').then(snapshot => { return snapshot.exists() });
};

export const getClientFormFromClientNodeWithClientFormId = async (clientFormId) => {
    return await db.ref('clients/' + clientFormId).once('value').then(snapshot => { return snapshot.val() });
};

export const getClientRefFromClientNodeWithClientFormId = (clientFormId) => {
    return db.ref('clients/' + clientFormId);
};

export const getLastActiveFromUsersNodeWithUserUID = (uid) => {
    return db.ref('users/' + uid + '/lastActive');
};

export const getUserFromUsersNodeWithUserId = (uid) => {
    return db.ref('users/' + uid);
};

export const getRefOfUsersNode = () => {
    return db.ref('users/');
};

export const getAllFromReportsNode = () => {
    return db.ref('reports/');
};

export const getAllFromQuarterlyReportsNode = () => {
    return db.ref(MONTHLY_QUARTERLY_REPORTS);
};

export const getRefOfVolunteerFormsNode = () => {
    return db.ref('volunteerForms/');
};

export const getRefOfSafeSpaceFormsNode = () => {
    return db.ref('safeSpaceForms/');
};

export const getRefOfSocialServicesNode = () => {
    return db.ref('socialServices/');
};

export const getProfileFromUsersNodeWithUid = (uid) => {
    return db.ref('users/' + uid).once('value').then(snapshot => { return snapshot.val() });
};
