import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Modal, Button, GridColumn, Grid} from 'semantic-ui-react';
import moment from 'moment';
import {Margin, Resolution, usePDF} from "react-to-pdf";
import IncidentPDFContent from "./IncidentPDFContent";
import _ from "lodash";

const ShareModal = ({ modalOpen, closeShareModal, formData, incidentClientsData }) => {
    const [loading, setLoading] = useState(false);

    const { toPDF, targetRef } = usePDF({
        filename: `Street Kind ${_.startCase(formData.site)} ${moment(formData.startTime).format('YYYY-MM-DD')}`,
        resolution: Resolution.HIGH,
        method: 'save',
        page: {
            margin: {
                left: Margin.LARGE,
                right: Margin.LARGE,
                top: Margin.MEDIUM,
                bottom: Margin.MEDIUM,
            },
            format: 'A4',
            orientation: 'portrait',
        },
        overrides: {
            pdf: {
                orientation: 'portrait',
                unit: 'px',
                format: 'a4',
                compress: true,
                precision: 2,
                putOnlyUsedFonts: true,
                floatPrecision: 'smart',
            },
            canvas: {
                pagesplit: true
            }
        },
    });

    return (
        <Modal open={modalOpen} size='large' closeIcon onClose={closeShareModal}>
            <Modal.Header>
                <Grid>
                    <GridColumn>
                        <Button
                            type={"button"}
                            icon='download'
                            labelPosition='right'
                            content="Download"
                            color="blue"
                            onClick={() => {
                                setLoading(true);
                                toPDF();
                                setTimeout(() => setLoading(false), 2000);
                            }}
                            loading={loading}
                            disabled={loading}
                        />
                    </GridColumn>
                </Grid>
            </Modal.Header>
            <Modal.Content>
                <div ref={targetRef}>
                    <IncidentPDFContent formData={formData} incidentClientsData={incidentClientsData} />
                </div>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    positive
                    type={"button"}
                    icon='checkmark'
                    labelPosition='right'
                    content="Done"
                    onClick={closeShareModal}
                />
            </Modal.Actions>
        </Modal>
    );
};

ShareModal.propTypes = {
    modalOpen: PropTypes.bool.isRequired,
    closeShareModal: PropTypes.func.isRequired,
    formData: PropTypes.object.isRequired,
    incidentClientsData: PropTypes.array.isRequired
};

export default ShareModal;
