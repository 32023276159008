import React, { Component } from 'react';
import { Menu, Sidebar, Icon } from 'semantic-ui-react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import * as actions from '../../actions/index';

class Index extends Component {
    render() {
        return (
            <Sidebar
                as={Menu}
                animation='push'
                width='thin'
                visible={this.props.visible}
                icon='labeled'
                vertical
                inverted
            >
                <Menu.Item
                    name='home'
                    onClick={() => {
                        this.props.visibilityToggle();
                    }}
                    as={Link}
                    to='/'
                >
                    <Icon name='home' />
                    Home
                </Menu.Item>
                <Menu.Item
                    name='myforms'
                    onClick={() => {
                        this.props.visibilityToggle();
                    }}
                    as={Link}
                    to='/myforms'
                >
                    <Icon name='tasks' />
                    Quick Form
                </Menu.Item>
                <Menu.Item
                    name='incident'
                    onClick={() => {
                        this.props.visibilityToggle();
                    }}
                    as={Link}
                    to='/incident/new'
                >
                    <Icon name='edit' />
                    Incident Form
                </Menu.Item>
                <Menu.Item
                    name='safeBase'
                    onClick={() => {
                        this.props.visibilityToggle();
                    }}
                    as={Link}
                    to='/safebase'
                >
                    <Icon name='shield alternate' />
                    Safe Base Form
                </Menu.Item>
                <Menu.Item
                    name='myProfile'
                    onClick={() => {
                        this.props.visibilityToggle();
                    }}
                    as={Link}
                    to='/profile'
                >
                    <Icon name='user circle' />
                    My Profile
                </Menu.Item>
                <Menu.Item
                    name='socialServices'
                    onClick={() => {
                        this.props.visibilityToggle();
                    }}
                    as={Link}
                    to='/socialservices'
                >
                    <Icon name='call square' />
                    Social Services
                </Menu.Item>
                {this.props.profile.userLevel === 'administrator' && (
                    <Menu.Item
                        name='admin'
                        onClick={() => {
                            this.props.visibilityToggle();
                        }}
                        as={Link}
                        to='/admin'
                    >
                        <Icon name='id badge' />
                        Administrator
                    </Menu.Item>
                )}
                {this.props.profile.userLevel === 'teamLeader' && (
                    <Menu.Item
                        name='incidents'
                        onClick={() => {
                            this.props.visibilityToggle();
                        }}
                        as={Link}
                        to='/incidents'
                    >
                        <Icon name='clipboard list' />
                        Incidents
                    </Menu.Item>
                )}
                {this.props.profile.userLevel === 'administrator' && (
                    <Menu.Item
                        name='reporting'
                        onClick={() => {
                            this.props.visibilityToggle();
                        }}
                        as={Link}
                        to='/reports'
                    >
                        <Icon name='file alternate outline' />
                        Reports
                    </Menu.Item>
                )}
                {this.props.profile.userLevel === 'administrator' && (
                    <Menu.Item
                        name='volunteerHours'
                        onClick={() => {
                            this.props.visibilityToggle();
                        }}
                        as={Link}
                        to='/volunteerHours'
                    >
                        <Icon name='clock outline' />
                        Volunteer Hours
                    </Menu.Item>
                )}
                <Menu.Item
                    name='about'
                    onClick={() => {
                        this.props.visibilityToggle();
                    }}
                    as={Link}
                    to='/about'
                >
                    <Icon name='info' />
                    About
                </Menu.Item>
                <Menu.Item name='logout' onClick={() => this.props.logout()}>
                    <Icon name='log out' />
                    Log out
                </Menu.Item>
            </Sidebar>
        );
    }
}
const mapStateToProps = ({ auth, profile }) => {
    return {
        auth,
        profile,
    };
};

export default compose(connect(mapStateToProps, actions), withRouter)(Index);
