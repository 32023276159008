import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { DateTime } from 'luxon';
import { initialize as initializeAction } from 'redux-form';
import _ from 'lodash';

import ClientFormContainer from './ClientFormContainer';
import { db } from '../../firebase/';

class ClientEdit extends React.Component {

    async componentDidMount() {
        window.scrollTo(0, 0);

        if ( _.has(this.props.location.state, "clientId")) {
            const { clientId } = this.props.location.state;
            const { initializeAction } = this.props;

            const clientIdExists = await db.clientFormExists(clientId);
            console.log("Client Id Exists?", clientIdExists);
            if (clientIdExists) {
                const clientFormData = await db.getClientFormFromClientNodeWithClientFormId(clientId);
                initializeAction('client', clientFormData);
            } else {
                this.props.history.replace("/error", { error: "ClientId doesn't exist on firebase.", from: this.props.match.url, clientId: clientId });
            }
        } else {
            this.props.history.replace("/error", { error: "Expecting Client ID", from: this.props.match.url });
        }
    }

    cancelForm = () => {
        this.props.history.goBack();
    };

    submitClientForm = async (formData) => {
        // console.log("Client Edit Data: ", data);
        formData.editedBy = this.props.auth.uid;
        formData.editedDate = DateTime.local().valueOf();

        const { clientId } = this.props.location.state;
        const clientReference = db.getClientRefFromClientNodeWithClientFormId(clientId);
        await clientReference.update(formData);
        this.props.history.push('/incident/edit', { incidentId: formData.incidentId });
    };

    render() {
        return (
            <ClientFormContainer
                onSubmit={this.submitClientForm}
                isNew={false}
                cancelForm={this.cancelForm}
            />
        )
    }
}

const mapStateToProps = ({ auth, profile }) => {
    return {
        auth,
        profile
    }
};

export default withRouter(connect(mapStateToProps, {initializeAction: initializeAction})(ClientEdit))