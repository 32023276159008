import React from 'react'
import { Modal, Button } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import { db } from '../../firebase/index';
import _ from 'lodash'

class ConfirmDeleteModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            deletingStatus: false
        }
    }

    confirmDeleteModal = () => {
        // Insert Firebase delete call here
        this.setState({deletingStatus: true});
        if(this.props.setRefresh){
            this.props.setRefresh((prevRefresh) => !prevRefresh);
        }
        const formPromise = db.getIncidentFormRefFromIncidentFormNodeWithIncidentFormId(this.props.incidentFormToDelete);
        formPromise.remove()
            .then(() => {
                const clientsIncidentRef = db.getClientListRefFromClientNode();
                clientsIncidentRef
                    .orderByChild('incidentId')
                    .equalTo(this.props.incidentFormToDelete)
                    .once('value', clientListSnapshot => {
                        clientListSnapshot.forEach(client => {
                            const clientRef = db.getClientRefFromClientNodeWithClientFormId(client.key);
                            clientRef.remove()
                                .then(result => {
                                    console.log("DELETE CLIENT " + client.key + " WITH INCIDENT ID " + this.props.incidentFormToDelete + " COMPLETED");
                                })
                                .catch(error => {
                                    console.log("ERROR EXECUTING CLIENT DELETE BY INCIDENTID: " + this.props.incidentFormToDelete);
                                    console.log("ERROR CODE: " + error.code);
                                    console.log("ERROR MESSAGE: " + error.message);
                                })
                        })
                    }).then(() => {
                        console.log("" + this.props.incidentFormToDelete + " has been removed");
                        if (this.props.rerouteToDraftFormsFlag) {
                            this.props.rerouteToDraftForms();
                        } else {
                            this.props.closeConfirmDeleteModal();
                            this.setState({deletingStatus: false});
                            if(this.props.setRefresh){
                                this.props.setRefresh((prevRefresh) => !prevRefresh);
                            }
                        }
                    }).catch((error) => {
                        console.log("ERROR FETCHING CLIENT LIST BY INCIDENT ID: " + this.props.incidentFormToDelete);
                        console.log("ERROR CODE: " + error.code);
                        console.log("ERROR MESSAGE: " + error.message);
                    });
            })
            .catch((error) => {
                console.log("Failed to remove " + this.props.incidentFormToDelete);
                console.log("confirmModalDelete Error Code: " + error.code);
                console.log("confirmModalDelete Error Message: " + error.message);
            })
    };

    render() {
        return (
            <Modal
                open={this.props.modalOpen}
                size='small'
            >
                <Modal.Header>Confirm Delete Incident Form?</Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <p>Are you sure you wish to delete this form?</p>
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        negative
                        type={"button"}
                        icon='remove'
                        labelPosition='right'
                        content='Cancel'
                        onClick={() => this.props.closeConfirmDeleteModal()}
                    />
                    <Button
                        positive
                        type={"button"}
                        icon='checkmark'
                        labelPosition='right'
                        content="Confirm"
                        loading={this.state.deletingStatus}
                        onClick={this.confirmDeleteModal}
                    />
                </Modal.Actions>
            </Modal>
        )
    }
}

ConfirmDeleteModal.propTypes = {
    modalOpen:PropTypes.bool.isRequired,
    incidentFormToDelete: PropTypes.string.isRequired,
    closeConfirmDeleteModal: PropTypes.func.isRequired,
    rerouteToDraftFormsFlag: PropTypes.bool.isRequired,
    setRefresh: PropTypes.func,
};

const mapStateToProps = (state, ownProps) => {
    return {
        rerouteToDraftForms: () => {
            const from = _.get(ownProps, "location.state.from", "/");
            ownProps.history.push(from);
        }
    }
};

export default withRouter(connect(mapStateToProps)(ConfirmDeleteModal))
