import { applyMiddleware, compose, createStore } from 'redux';
import reducers from '../reducers';
import reduxThunk from 'redux-thunk';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
    reducers,
    {},
    composeEnhancers(
        applyMiddleware(reduxThunk), // will add in redux thunk later
        // This shit causes it to not work in mobile apps, comment out before deploying, uncomment for development if you wanna see the redux store
        // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(), // put here so we can inspect the redux state in browser. can remove later.
    )
);

export default store;
