import React from 'react'
import { Grid } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import AssistanceRenderedSegment from './AssistanceRenderedSegment'

class AssistanceRenderedComponent extends React.Component {

    render() {
        const { values } = this.props;

        return (
            <Grid container stackable centered >
                <AssistanceRenderedSegment title='Requested Directions' iconName='map outline' value={values.directions || 0} />
                <AssistanceRenderedSegment title='Bus Information' iconName='bus' value={values.bus || 0} />
                <AssistanceRenderedSegment title='Train Information' iconName='train' value={values.train || 0} />
                <AssistanceRenderedSegment title='Taxi Information' iconName='taxi' value={values.taxi || 0} />
                <AssistanceRenderedSegment title='Charged Devices' iconName='lightning' value={values.deviceCharge || 0} />
                <AssistanceRenderedSegment title='Family Reconnect' iconName='phone' value={values.familyReconnect || 0}/>
            </Grid>
        )
    }
}

AssistanceRenderedComponent.propTypes = {
    values: PropTypes.object.isRequired
};

export default AssistanceRenderedComponent