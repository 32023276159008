import React from 'react';
import { Modal, Button } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { initialize as initializeFormAction, reduxForm} from 'redux-form';
import { DateTime } from 'luxon';

import { updateProfile } from '../../actions/index';
import { db } from '../../firebase/index';
import ProfileForm from './ProfileForm';

class ProfileEditModal extends React.Component {

    constructor(props) {
        super(props);

        console.log(this.props);

        this.state = {
            userModel: this.props.userModel,
            modalOpen: false
        }
    }

    submitUserEdit = async (formData) => {
        console.log("FORM DATA:", formData);
        const { uid } = this.props.auth;
        let newUserModel;
        newUserModel = {
            ...formData,
            editedBy: uid,
            editedDate: DateTime.local().valueOf()
        };
        console.log("NEW USER MODEL", newUserModel);
        this.userRef = db.getUserFromUsersNodeWithUserId(uid);
        await this.userRef.update(newUserModel);
        this.props.updateProfile(uid);
        this.triggerModal();
    };

    triggerModal = () => {
        this.setState({ modalOpen: !this.state.modalOpen })
    };

    render() {

        const { submitting } = this.props; // these come from redux-form HOC

        return (
            <Modal
                dimmer
                trigger={
                    <Button
                        type="button"
                        onClick={this.triggerModal}
                        content="Edit My Details"
                        primary
                        icon="pencil"
                        floated="right" />
                }
                open={this.state.modalOpen}
            >
                <Modal.Header>Edit My Details</Modal.Header>
                <Modal.Content>
                    <ProfileForm onSubmit={this.submitUserEdit} initialValues={this.props.profile}/>
                </Modal.Content>
                <Modal.Actions>
                    <Button negative icon='remove' labelPosition='right' content="Cancel" onClick={this.triggerModal} />
                    <Button positive icon='checkmark' labelPosition='right' content="Save" form="profileForm" type="submit" loading={submitting}/>
                </Modal.Actions>
            </Modal>
        )
    }
}

const mapStateToProps = ({ auth, profile }) => {
    return {
        auth,
        profile
    }
};

export default connect(mapStateToProps, { updateProfile, initializeFormAction: initializeFormAction} )(reduxForm({ form: 'profileForm' })(ProfileEditModal))