import React from 'react'
import { Grid } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import ClientSegment from "./ClientSegment";

class ClientsViewerComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            activeIndex: 0,
        }
    }

    generateClients = () => {
        let accordionIndex = 0;
        return _.map(this.props.incidentClientsData, (clientKeyValuePair) => {
            accordionIndex++;
            return <ClientSegment
                key={clientKeyValuePair.clientKey}
                accordionIndex={accordionIndex}
                activeIndex={this.state.activeIndex}
                clientKey={clientKeyValuePair.clientKey}
                clientData={clientKeyValuePair.clientData}
                handleClick={this.handleClick}
            />
        })
    };

    handleClick = (event, accordionProps) => {
        const { index } = accordionProps;
        const { activeIndex } = this.state;
        const newIndex = activeIndex === index ? -1 : index;

        this.setState({ activeIndex: newIndex })
    };

    render() {
        return (
            <Grid.Row>
                {this.generateClients()}
            </Grid.Row>
        )
    }
}

ClientsViewerComponent.propTypes = {
    incidentFormId: PropTypes.string.isRequired,
    incidentClientsData: PropTypes.array.isRequired
};
export default ClientsViewerComponent