import React from 'react'
import { Grid, Header, Icon, Segment, Statistic } from 'semantic-ui-react'
import PropTypes from 'prop-types'

class PeopleCountComponent extends React.Component {

    render() {
        const {
            iconName,
            iconColour,
            headerContent,
            segmentColour,
            values
        } = this.props;

        return (
            <Grid.Row>
                <Grid.Column>
                    <Segment color={segmentColour}>
                        <Header as='h3'>
                            <Icon name={iconName} color={iconColour} />
                            <Header.Content>{headerContent}</Header.Content>
                        </Header>
                        <Grid
                            divided
                            stackable /*could added 'divided' property for a dividing line between count numbers. Side effect is there's a small grey line on the left of the segment when in mobile mode*/
                        >
                            <Grid.Row>
                                <Grid.Column mobile={16} tablet={8} computer={4} textAlign='center'>
                                    <Segment basic>
                                        <Statistic>
                                            <Statistic.Label>Under 18</Statistic.Label>
                                            <Statistic.Value>{values.lessThan18 || 0}</Statistic.Value>
                                        </Statistic>
                                    </Segment>
                                </Grid.Column>
                                <Grid.Column mobile={16} tablet={8} computer={4} textAlign='center'>
                                    <Segment basic>
                                        <Statistic>
                                            <Statistic.Label>From 18 to 25</Statistic.Label>
                                            <Statistic.Value>{values.from18to25 || 0}</Statistic.Value>
                                        </Statistic>
                                    </Segment>
                                </Grid.Column>
                                <Grid.Column mobile={16} tablet={8} computer={4} textAlign='center'>
                                    <Segment basic>
                                        <Statistic>
                                            <Statistic.Label>From 26 to 39</Statistic.Label>
                                            <Statistic.Value>{values.from26to39 || 0}</Statistic.Value>
                                        </Statistic>
                                    </Segment>
                                </Grid.Column>
                                <Grid.Column mobile={16} tablet={8} computer={4} textAlign='center'>
                                    <Segment basic>
                                        <Statistic>
                                            <Statistic.Label>Over 40</Statistic.Label>
                                            <Statistic.Value>{values.over40 || 0}</Statistic.Value>
                                        </Statistic>
                                    </Segment>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Segment>
                </Grid.Column>
            </Grid.Row>
        )
    }
}

PeopleCountComponent.propTypes = {
    iconName: PropTypes.string.isRequired,
    iconColour: PropTypes.string.isRequired,
    headerContent: PropTypes.string.isRequired,
    segmentColour: PropTypes.string.isRequired,
    values: PropTypes.object.isRequired
};

export default PeopleCountComponent
