import React, { Component } from 'react';
import { Menu, Grid, Icon, Image } from 'semantic-ui-react';
import streetKindLogo from '../../assets/street-kind-logo-black.svg';

class MenuBar extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    onMenuSelect = (event, data) => {
        console.log('Toggle Visibility!');
        this.props.visibilityToggle();
    };

    render() {
        return (
            <Menu size='huge' attached='top'>
                <Menu.Item name='sideBar' onClick={this.onMenuSelect}>
                    <Icon name='bars' size='large' />
                    Menu
                </Menu.Item>
                <Grid container>
                    <Grid.Column>
                        <Image
                            src={streetKindLogo}
                            size='tiny'
                            centered
                            className='menuBarLogo'
                        />
                    </Grid.Column>
                </Grid>
            </Menu>
        );
    }
}
export default MenuBar;
