import React from 'react';
import { Container, Header } from 'semantic-ui-react';
import ReportGenerator from "./ReportGenerator";
import withAuthorization from "../../../hocs/withAuthorization";
import {ProgramTotalsReports} from "./ProgramTotalsReports";

const ProgramTotals = () => (
    <Container>
        <Header as='h1'>Program Totals Reports</Header>
        <Header as='h2' className='sectionHeader'>Reports Generator</Header>
        <ReportGenerator />
        <Header as='h2'>Reports List</Header>
        <ProgramTotalsReports />
    </Container>
);

export default withAuthorization('admin')(ProgramTotals);
