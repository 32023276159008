import React, {Component} from 'react';
import { connect } from 'react-redux';
import { initialize as initializeAction } from 'redux-form';
import { DateTime } from 'luxon';
import { Loader } from 'semantic-ui-react';
import _ from 'lodash';

import IncidentForm from './IncidentForm';
import createModel from '../../Models/incident';
import { db } from '../../firebase/index';

const INITIAL_STATE = {
    incidentId: ""
};

class IncidentNew extends Component {

    constructor(props) {
        super(props);

        this.state = INITIAL_STATE;
    }

    async componentDidMount() {
        window.scrollTo(0, 0);

        const incidentId = await db.getRefOfIncidentFormsNode().push().key;
        console.log("DID MOUNT INCIDENT PUSH ID", incidentId);

        this.setState({
            incidentId: incidentId
        });

        const getInitialState = () => {
            // pull firstName and lastName from userModel prop
            const { uid } = this.props.auth;
            const { firstName, lastName, site } = this.props.profile;

            // prepopulate model with data and return it to be put into reduxForm
            let incidentModel = createModel();
            const currentTime = DateTime.local();
            console.log("CURRENT TIME IN NEW INCIDENT: ", currentTime.valueOf());
            console.log("CURRENT TIME IN NEW INCIDENT: ", currentTime.toISO());
            incidentModel = {
                ...incidentModel,
                createdDate: currentTime.valueOf(),
                createdBy: uid,
                site: site,
                teamLeaderName: firstName + ' ' + lastName,
                startTime: currentTime.valueOf(),
                endTime: currentTime.plus({minutes: 5}).valueOf(),
            };
            return incidentModel;
        };

        this.props.initializeAction('incident', getInitialState());
    };

    render() {
        if (!_.isEmpty(this.state.incidentId)) {
            return (
                <div>
                    <IncidentForm
                        isNew={true}
                        incidentId={this.state.incidentId}
                    />
                </div>
            )
        } else {
            return (
                <Loader active inline content='Loading...' />
            )
        }
    }
}

const mapStateToProps = ({ auth, profile}) => {
    return {
        auth,
        profile
    }
};

export default connect(mapStateToProps, {initializeAction: initializeAction})(IncidentNew);
