import React from 'react';
import { Form } from 'semantic-ui-react';
import { Field as ReduxField, reduxForm } from 'redux-form';
import {
    SemanticReduxFormInputField,
    SemanticReduxFormSelect,
} from '../../utilities/SemanticReduxFormField';
import { safeSpaceOptions } from '../../constants/SelectableListOptions';

class ProfileForm extends React.Component {
    render() {
        const { handleSubmit } = this.props; // from reduxForm() hoc

        return (
            <Form
                onSubmit={handleSubmit}
                id='profileForm' // this id is required as the name of the form. The submit button (further below) uses this form name in its form attribute to say which form to submit.
            >
                <ReduxField
                    name='firstName'
                    component={SemanticReduxFormInputField}
                    fieldLabel='First Name'
                    placeholder='First Name'
                />
                <ReduxField
                    name='lastName'
                    component={SemanticReduxFormInputField}
                    fieldLabel='Last Name'
                    placeholder='Last Name'
                />
                <ReduxField
                    name='site'
                    component={SemanticReduxFormSelect}
                    fieldLabel='My SafeBase'
                    placeholder='Safe Base Location'
                    selectionOptions={safeSpaceOptions}
                />
            </Form>
        );
    }
}

export default reduxForm({
    form: 'profileForm',
})(ProfileForm);
