import React from 'react'
import {Responsive, Dropdown, Menu, Header} from 'semantic-ui-react'

import {IncidentSections} from './IncidentSectionsEnum'

const IncidentSectionSwitch = ({activeSection, changeFormSection}) => {
    const options = [
        {
            key: IncidentSections.CLIENT,
            value: IncidentSections.CLIENT,
            name: IncidentSections.CLIENT,
            text: 'Client Information',
            content: <Header icon='user' content='Client Information'
                             subheader='Client condition, contact information'/>,
            onClick: () => changeFormSection(IncidentSections.CLIENT)
        },
        {
            key: IncidentSections.BASIC_SUPPORT,
            value: IncidentSections.BASIC_SUPPORT,
            name: IncidentSections.BASIC_SUPPORT,
            text: 'Basic Support',
            content: <Header icon='question circle' content='Basic Support' subheader='Basic help, directions given'/>,
            onClick: () => changeFormSection(IncidentSections.BASIC_SUPPORT)
        },
        {
            key: IncidentSections.HEALTH_SUPPORT,
            value: IncidentSections.HEALTH_SUPPORT,
            name: IncidentSections.HEALTH_SUPPORT,
            text: 'Health & Emergency Support',
            content: <Header icon='first aid' content='Health & Emergency Support' subheader='First aid, well-being, emergency support'/>,
            onClick: () => changeFormSection(IncidentSections.HEALTH_SUPPORT)
        },
        {
            key: IncidentSections.RISK_MIN,
            value: IncidentSections.RISK_MIN,
            name: IncidentSections.RISK_MIN,
            text: 'Risk Minimization',
            content: <Header icon='exclamation triangle' content='Risk Minimization'
                             subheader='Risks minimized (violence, unconciousness, etc)'/>,
            onClick: () => changeFormSection(IncidentSections.RISK_MIN)
        },
        {
            key: IncidentSections.SERVICE_REF,
            value: IncidentSections.SERVICE_REF,
            name: IncidentSections.SERVICE_REF,
            text: 'Services Referred',
            content: <Header icon='call square' content='Services Referred'
                             subheader='Support services referred or contacted'/>,
            onClick: () => changeFormSection(IncidentSections.SERVICE_REF)
        }
    ];

    return [
        <Responsive key={0}
                    {...Responsive.onlyMobile}
                    as={Dropdown}
                    placeholder='Sections'
                    selection
                    options={options}
                    fluid
                    value={activeSection}/>,

        <Responsive key={1}
                    {...Responsive.onlyTablet}
                    as={Dropdown}
                    placeholder='Sections'
                    selection
                    options={options}
                    fluid
                    value={activeSection}/>,

        <Responsive key={2}
                    {...Responsive.onlyComputer}
                    as={Menu}
                    icon='labeled'
                    fluid
                    widths={5}
                    color='blue'>
            <Menu.Item
                name='clientInfo'
                active={activeSection === 'clientInfo'}
                onClick={() => changeFormSection(IncidentSections.CLIENT)}
                content='Client Information'
                icon='user'
            />
            <Menu.Item
                name='basicSupport'
                active={activeSection === 'basicSupport'}
                onClick={() => changeFormSection(IncidentSections.BASIC_SUPPORT)}
                content='Basic Support'
                icon='question circle'
            />
            <Menu.Item
                name='healthSupport'
                active={activeSection === 'healthSupport'}
                onClick={() => changeFormSection(IncidentSections.HEALTH_SUPPORT)}
                content='Health & Emergency Support'
                icon='first aid'
            />
            <Menu.Item
                name='riskMinimization'
                active={activeSection === 'riskMinimization'}
                onClick={() => changeFormSection(IncidentSections.RISK_MIN)}
                content='Risk Minimization'
                icon='exclamation triangle'
            />
            <Menu.Item
                name='servicesReferred'
                active={activeSection === 'servicesReferred'}
                onClick={() => changeFormSection(IncidentSections.SERVICE_REF)}
                content='Services Referred'
                icon='call square'
            />
        </Responsive>
    ]
};

export default IncidentSectionSwitch