import React from 'react';
import { Grid, Statistic, Icon, Segment } from 'semantic-ui-react';
import PropTypes from "prop-types";

class AssistanceRenderedSegment extends React.Component {
    render() {
        return (
            <Grid.Column mobile={16} tablet={8} computer={4} textAlign='center'>
                <Segment color='green'>
                    <Statistic>
                        <Statistic.Label>{this.props.title}</Statistic.Label>
                        <Statistic.Value>
                            <Icon name={this.props.iconName} size='small' className='assistanceRenderedIcon'/>
                            {this.props.value}
                        </Statistic.Value>
                    </Statistic>
                </Segment>
            </Grid.Column>
        )
    }
}

AssistanceRenderedSegment.propTypes = {
    title: PropTypes.string.isRequired,
    iconName: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired
};

export default AssistanceRenderedSegment