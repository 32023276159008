import React from 'react';
import { Form } from 'semantic-ui-react';
import { Field as ReduxField, reduxForm } from 'redux-form';
import { SemanticReduxFormInputField, SemanticReduxFormSelect } from '../../../utilities/SemanticReduxFormField';
import { safeSpaceOptions, userLevelOptions } from '../../../constants/SelectableListOptions';

class AddUserForm extends React.Component {
    render() {
        const { handleSubmit } = this.props;

        return (
            <Form
                onSubmit={handleSubmit}
                id="addUserForm"
            >
                <ReduxField
                    name='firstName'
                    component={SemanticReduxFormInputField}
                    fieldLabel='First Name'
                    placeholder='First Name'
                    type='text'
                />
                <ReduxField
                    name='lastName'
                    component={SemanticReduxFormInputField}
                    fieldLabel='Last Name'
                    placeholder='Last Name'
                    type='text'
                />
                <ReduxField
                    name='email'
                    component={SemanticReduxFormInputField}
                    fieldLabel='Email'
                    placeholder='Email'
                    type='text'
                />
                <ReduxField
                    name='password'
                    component={SemanticReduxFormInputField}
                    fieldLabel='Password'
                    placeholder='Password'
                    type='text'
                />
                <ReduxField
                    name='passwordConfirm'
                    component={SemanticReduxFormInputField}
                    fieldLabel='Confirm Password'
                    placeholder='Password Again'
                    type='text'
                />
                <ReduxField
                    component={SemanticReduxFormSelect}
                    fieldLabel='Base Site'
                    placeholder='Site Name'
                    name='site'
                    selectionOptions={safeSpaceOptions}
                />
                <ReduxField
                    component={SemanticReduxFormSelect}
                    fieldLabel='User Level'
                    placeholder='User Level'
                    name='userLevel'
                    selectionOptions={userLevelOptions}
                />
            </Form>
        )
    }
}

export default reduxForm({
    form: 'addUserForm'
})(AddUserForm);
