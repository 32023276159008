import React from 'react'
import PropTypes from 'prop-types'

import {IncidentSections} from './IncidentSectionsEnum'
import ClientInfo from './ClientFormSections/ClientInfo'
import BasicSupport from './ClientFormSections/BasicSupport'
import HealthSupport from './ClientFormSections/HealthSupport'
import RiskMinimization from './ClientFormSections/RiskMinimization'
import ServicesReferred from './ClientFormSections/ServicesReferred'

class ClientFormComponent extends React.Component {

    onSubmit = (values) => {
        this.props.onSubmit(values)
    };

    componentDidUpdate(prevProps) {
        // needed so that when someone moves from section to section, the view would return to the top of the screen
        if (this.props.activeSection !== prevProps.activeSection) {
            window.scrollTo(0, 0);
        }
    }

    render() {
        const { changeFormSection, activeSection, cancelForm } = this.props;

        switch (activeSection) {
            default:
                console.error("Unknown active state");
            // fallthrough
            case IncidentSections.CLIENT:
                return <ClientInfo
                    changeFormSection={changeFormSection}
                    nextSection={IncidentSections.BASIC_SUPPORT}
                    onSubmit={this.onSubmit}
                    cancelForm={cancelForm}
                />;
            case IncidentSections.BASIC_SUPPORT:
                return <BasicSupport
                    changeFormSection={changeFormSection}
                    prevSection={IncidentSections.CLIENT}
                    nextSection={IncidentSections.HEALTH_SUPPORT}
                    onSubmit={this.onSubmit}
                    cancelForm={cancelForm}
                />;
            case IncidentSections.HEALTH_SUPPORT:
                return <HealthSupport
                    changeFormSection={changeFormSection}
                    prevSection={IncidentSections.BASIC_SUPPORT}
                    nextSection={IncidentSections.RISK_MIN}
                    onSubmit={this.onSubmit}
                    cancelForm={cancelForm}
                />;
            case IncidentSections.RISK_MIN:
                return <RiskMinimization
                    changeFormSection={changeFormSection}
                    prevSection={IncidentSections.HEALTH_SUPPORT}
                    nextSection={IncidentSections.SERVICE_REF}
                    onSubmit={this.onSubmit}
                    cancelForm={cancelForm}
                />;
            case IncidentSections.SERVICE_REF:
                return <ServicesReferred
                    changeFormSection={changeFormSection}
                    prevSection={IncidentSections.RISK_MIN}
                    onSubmit={this.onSubmit}
                    cancelForm={cancelForm}
                />;
        }
    }
}

ClientFormComponent.propTypes = {
    activeSection: PropTypes.string.isRequired,
    changeFormSection: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    cancelForm: PropTypes.func.isRequired,
};

export default ClientFormComponent
