import React from 'react';
import { Button, Divider, Grid, Header, Icon, List, Segment, Responsive } from 'semantic-ui-react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import _ from 'lodash';

import ResponsiveHorizontalList from './ResponsiveHorizontalList';
import ConfirmDeleteModal from '../../MyForms/ConfirmDeleteModal';
import ShareModal from './ShareModal';
import ClientsViewerComponent from './ClientsViewerComponent';

class IncidentFormDisplayComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            confirmDeleteModalOpen: false,
            shareModalOpen: false,
            confirmDeleteFormId: ''
        }
    }

    goBack = () => {
        this.props.history.goBack();
    };

    openConfirmDeleteModal = (incidentFormToDelete) => {
        this.setState({
            confirmDeleteModalOpen: true,
            confirmDeleteFormId: incidentFormToDelete
        })
    };

    closeConfirmDeleteModal = () => {
        this.setState({
            confirmDeleteModalOpen: false,
            confirmDeleteFormId: ''
        })
    };

    openShareReport = () => {
        this.setState({
            shareModalOpen: true
        })
    };

    closeShareModal = () => {
        this.setState({
            shareModalOpen: false
        })
    };

    render() {
        const { formData, incidentClientsData, incidentId } = this.props;

        return (
            <Grid container>
                <Grid.Row>
                    <Header as='h1'>View Incident Form</Header>
                </Grid.Row>
                <Grid.Row stretched>
                    <Segment color='blue'>
                        <Header as='h2' floated='left'>
                            <Icon name='pencil'/>
                            <Header.Content>
                                Incident Details
                                <Header.Subheader>
                                    Incident Created Time: {_.isInteger(formData.createdDate)
                                        ? moment(formData.createdDate).format("ddd Do MMMM, h:mm A")
                                        : 'No Data'}
                                </Header.Subheader>
                            </Header.Content>
                        </Header>
                        <Header as='h2' floated='right'>
                            <Header.Content>
                                Incident Form Key
                                <Header.Subheader>{incidentId}</Header.Subheader>
                            </Header.Content>
                        </Header>
                        <Divider clearing hidden />
                        <Grid columns='equal' stackable>
                            <Grid.Row>
                                <Grid.Column>
                                    <List size='large'>
                                        <List.Item>
                                            <List.Header>Team Leader</List.Header>
                                            <List.Description>
                                                {_.isEmpty(formData.teamLeaderName)
                                                    ? 'No Data'
                                                    : formData.teamLeaderName}
                                            </List.Description>
                                        </List.Item>
                                    </List>
                                </Grid.Column>
                                <Grid.Column>
                                    <List size='large'>
                                        <List.Item>
                                            <List.Header>Base Site</List.Header>
                                            <List.Description>
                                                {_.isEmpty(formData.site)
                                                    ? 'No Data'
                                                    : _.startCase(formData.site)}
                                            </List.Description>
                                        </List.Item>
                                    </List>
                                </Grid.Column>
                                <Grid.Column>
                                    <List size='large'>
                                        <List.Item>
                                            <List.Header>Address</List.Header>
                                            <List.Description>
                                                {_.isEmpty(_.get(formData, 'location.address', ''))
                                                    ? 'No Data'
                                                    : _.get(formData, 'location.address', '')}
                                            </List.Description>
                                        </List.Item>
                                    </List>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <List size='large'>
                                        <List.Item>
                                            <List.Header>Start Time</List.Header>
                                            <List.Description>
                                                {_.isInteger(formData.startTime)
                                                    ? moment(formData.startTime).format("ddd Do MMMM, h:mm A")
                                                    : 'No Data'}
                                            </List.Description>
                                        </List.Item>
                                    </List>
                                </Grid.Column>
                                <Grid.Column>
                                    <List size='large'>
                                        <List.Item>
                                            <List.Header>End Time</List.Header>
                                            <List.Description>
                                                {_.isInteger(formData.endTime)
                                                    ? moment(formData.endTime).format("ddd Do MMMM, h:mm A")
                                                    : 'No Data'}
                                            </List.Description>
                                        </List.Item>
                                    </List>
                                </Grid.Column>
                                <Grid.Column>
                                    <List size='large'>
                                        <List.Item>
                                            <List.Header>Duration</List.Header>
                                            <List.Description>
                                                {_.lt(formData.endTime, formData.startTime)
                                                    ? 'No Data'
                                                    : moment.duration(moment(formData.endTime).diff(moment(formData.startTime))).humanize()}
                                            </List.Description>
                                        </List.Item>
                                    </List>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <ResponsiveHorizontalList
                                        listHeaderName='Incident Referred By'
                                        listObject={_.get(formData, 'encounteredBy', {})}
                                    />
                                </Grid.Column>
                                <Grid.Column>
                                    <ResponsiveHorizontalList
                                        listHeaderName='Other Services Referred'
                                        listObject={_.get(formData, 'otherServicesInvolved', {})}/>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <List size='large'>
                                        <List.Item>
                                            <List.Header>Incident Description</List.Header>
                                            <List.Description>
                                                {_.isEmpty(formData.incidentDescription)
                                                    ? 'No Data'
                                                    : formData.incidentDescription}
                                            </List.Description>
                                        </List.Item>
                                    </List>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <List size='large'>
                                        <List.Item>
                                            <List.Header>Incident Outcome</List.Header>
                                            <List.Description>
                                                {_.isEmpty(formData.incidentOutcome)
                                                    ? 'No Data'
                                                    : formData.incidentOutcome}
                                            </List.Description>
                                        </List.Item>
                                    </List>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Segment>
                </Grid.Row>
                <ClientsViewerComponent incidentFormId={incidentId} incidentClientsData={incidentClientsData}/>
                <Grid.Row centered>
                    <Responsive
                        // for small mobile
                        minWidth={320}
                        maxWidth={767}
                    >
                        <Button size='big' color='black' icon='arrow left' circular onClick={this.goBack} />
                        <Button size='big' color='yellow' icon='pencil' circular as={Link}
                                to={{pathname: '/incident/edit/', state: { incidentId: incidentId, from: '/incident/view' } }} />
                        <Button size='big' color='red' icon='trash' circular onClick={() => {
                            this.openConfirmDeleteModal(incidentId)
                        }}/>
                        <Button size='big' color='green' icon='share alternate' circular onClick={this.openShareReport}/>
                    </Responsive>
                    <Responsive
                        // for tablet and larger
                        minWidth={768}
                        maxWidth={3840}>
                        <Button color='black' icon='arrow left' content='My Forms' labelPosition='left' onClick={this.goBack} />
                        <Button color='yellow' icon='pencil' content='Edit' labelPosition='left' as={Link}
                                to={{pathname: '/incident/edit/', state: { incidentId: incidentId, editFrom: '/incident/view' } }} />
                        <Button color='red' icon='trash' content='Delete' labelPosition='left' onClick={() => {
                            this.openConfirmDeleteModal(incidentId)
                        }}/>
                        <Button color='green' icon='share alternate' content='Share' labelPosition='left' onClick={this.openShareReport}/>
                    </Responsive>
                </Grid.Row>
                <Grid.Row
                    // Buffer Row for when viewing on mobile...
                />
                <ConfirmDeleteModal
                    modalOpen={this.state.confirmDeleteModalOpen}
                    incidentFormToDelete={this.state.confirmDeleteFormId}
                    closeConfirmDeleteModal={this.closeConfirmDeleteModal}
                    rerouteToDraftFormsFlag={true}
                />
                <ShareModal
                    modalOpen={this.state.shareModalOpen}
                    closeShareModal={this.closeShareModal}
                    formData={this.props.formData}
                    incidentClientsData={this.props.incidentClientsData}
                />
            </Grid>
        )
    }
}

IncidentFormDisplayComponent.propTypes = {
    formData: PropTypes.object.isRequired,
    incidentClientsData: PropTypes.array.isRequired,
    incidentId: PropTypes.string.isRequired
};

export default withRouter(IncidentFormDisplayComponent);
