import {AUTH_USER_CLEAR, AUTH_USER_UPDATED} from '../actions/types';

export default function(state = null, action) {
    switch(action.type) {
        case AUTH_USER_UPDATED:
            return action.payload || null;
        case AUTH_USER_CLEAR:
            return null;
        default:
            return state;
    }
}