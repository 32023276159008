import React from 'react';
import { Container, Header } from 'semantic-ui-react';

const About = () => {
    // Version is

    // Full hash of the commit
    console.log("5e37689e2a43284b524d7b5106834c99bd380191");

    // VersionNumber.Release Date (YYYY/MM/DD)
    return (
        <Container>
            <Header as='h1' content='About Page' />
            <p>Version 2.7.20191112</p>
        </Container>
    )
};

export default About
