import {reduxForm} from 'redux-form'

import validate from '../../../Models/client-validator'

const config = {
    form: 'client',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    // enableReinitialize: true,
    validate: validate
};

export const withReduxForm = (Page) =>{
    return reduxForm(config)(Page)
};
