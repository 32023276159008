import React from 'react'
import { Header, Grid } from 'semantic-ui-react'
import ServiceCardEditModal from './ServiceCardEditModal'
import SocialServicesCardList from './SocialServicesCardList'
import { db } from '../../firebase/index';
class SocialServices extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            socialServiceModel: {},
            socialServiceEditModalOpen: false,
            socialServicesList: {}
        };
    }

    componentWillMount() {
        db.getRefOfSocialServicesNode()
            .once('value')
            .then(snapshot => {
                this.setState({
                    socialServicesList: snapshot.val()
                });
            })
    }

    triggerModal = () => this.setState({
        socialServiceEditModalOpen: !this.state.socialServiceEditModalOpen
    });

    render() {
        return (
            <div>
                <Grid container>
                    <Grid.Row>
                        <Header as='h1' className='sectionHeader'>Social Services</Header>
                    </Grid.Row>
                    <SocialServicesCardList socialServicesList={this.state.socialServicesList}/>
                    {/*<Grid.Row centered>*/}
                        {/*<Grid.Column computer={8} largeScreen={8} tablet={12} mobile={16}>*/}
                            {/*<Button onClick={this.triggerModal} content='Edit My Details' primary icon='pencil' floated='right'/>*/}
                        {/*</Grid.Column>*/}
                    {/*</Grid.Row>*/}
                    <Grid.Row
                        // Buffer Row
                    />
                </Grid>
                <ServiceCardEditModal socialServiceEditModalOpen={this.state.socialServiceEditModalOpen} triggerModal={this.triggerModal} socialServiceModel={this.state.socialServiceModel}/>
            </div>
        )
    }
}

export default SocialServices