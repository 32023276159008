import React from 'react';
import { Grid, Segment, Header, Button } from 'semantic-ui-react';
import { Link } from "react-router-dom";

const NoMatch = (props) => {
    return(
        <Grid className='minHeightInPusher' centered verticalAlign='middle'>
            <Grid.Column mobile={14} tablet={12} computer={6}>
                <Segment color='blue' raised padded clearing>
                    <Header as='h1'>Whoops!</Header>
                    <p>Looks like you've wandered into a place you weren't supposed to!</p>
                    {
                        props.location.state &&
                            <div>
                                <p>Please send this to the developer!</p>
                                <pre>{JSON.stringify(props.location.state, null, 2)}</pre>
                            </div>
                    }
                    <p>Do you want to go back to the dashboard?</p>
                    <Button as={Link} replace to={{pathname: '/dashboard'}} floated='right' color='blue' content='Yes, please!' />
                </Segment>
            </Grid.Column>
        </Grid>
    )
};

export default NoMatch;