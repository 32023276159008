import React from 'react';
import { Form } from 'semantic-ui-react';
import { Field as ReduxField } from 'redux-form';
import { SemanticReduxFormCheckbox } from '../../../utilities/SemanticReduxFormField';

/*
 * This is the EncounteredBy section used by Incident Form Component.
 * This must be wrapped with a redux <FormSection name={parentSectionName} > component in
 * order to use it.
 */
export default class EncounteredBy extends React.Component {
    render() {
        return (
            <div>
                <Form.Group widths={7}>
                    <ReduxField
                        name='tkAmbassador'
                        component={SemanticReduxFormCheckbox}
                        checkboxLabel='SK Ambassador'
                    />
                    <ReduxField
                        name='self'
                        component={SemanticReduxFormCheckbox}
                        checkboxLabel='Self'
                    />
                    <ReduxField
                        name='friend'
                        component={SemanticReduxFormCheckbox}
                        checkboxLabel='Friend'
                    />
                    <ReduxField
                        name='generalPublic'
                        component={SemanticReduxFormCheckbox}
                        checkboxLabel='General Public'
                    />
                    <ReduxField
                        name='rangers'
                        component={SemanticReduxFormCheckbox}
                        checkboxLabel='Rangers'
                    />
                    <ReduxField
                        name='venueSecurity'
                        component={SemanticReduxFormCheckbox}
                        checkboxLabel='Venue Security'
                    />
                    <ReduxField
                        name='transportStaff'
                        component={SemanticReduxFormCheckbox}
                        checkboxLabel='Transport Staff'
                    />
                </Form.Group>
                <Form.Group widths={7}>
                    <ReduxField
                        name='police'
                        component={SemanticReduxFormCheckbox}
                        checkboxLabel='Police'
                    />
                    <ReduxField
                        name='ambulance'
                        component={SemanticReduxFormCheckbox}
                        checkboxLabel='Ambulance'
                    />
                    <ReduxField
                        name='fireRescue'
                        component={SemanticReduxFormCheckbox}
                        checkboxLabel='Fire Rescue'
                    />
                    <ReduxField
                        name='cctv'
                        component={SemanticReduxFormCheckbox}
                        checkboxLabel='CCTV'
                    />
                    <ReduxField
                        name='other'
                        component={SemanticReduxFormCheckbox}
                        checkboxLabel='Other'
                    />
                </Form.Group>
            </div>
        );
    }
}
