import React from 'react'
import PropTypes from 'prop-types'

import {compose, withProps} from "recompose"
import {withScriptjs, withGoogleMap, GoogleMap, Marker} from "react-google-maps"

const MapComponent = compose(
    withProps({
        googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyA0mdJv9RMnow7fTCvttJkrNJOhPH8xTTo&v=3.exp&libraries=geometry,drawing,places",
        loadingElement: <div style={{height: `100%`}}/>,
        containerElement: <div style={{height: `400px`}}/>,
        mapElement: <div style={{height: `100%`}}/>,
    }),
    withScriptjs,
    withGoogleMap
)((props) =>
    <GoogleMap
        defaultZoom={15}
        defaultCenter={props.defaultCenter || {lat: -33.872, lng: 151.207}}
        defaultOptions={
            {
                gestureHandling: "greedy",
                streetViewControl: false,
                styles:
                    [{
                        featureType: 'poi',
                        elementType: 'labels.text',
                        stylers: [{ visibility: 'off' }] // Turn off text labels for all other POI icons
                    },
                    {
                        featureType: 'poi.business',
                        stylers: [{ visibility: 'off' }]  // Turn off business icons.
                    }]
            }
        }
        onClick={props.onMapClick}
        streetViewControl={false}
    >
        {props.isMarkerShown && <Marker position={{lat: props.latitude, lng: props.longitude}}/>}
    </GoogleMap>
)

class IncidentMapComponent extends React.PureComponent {

    static propTypes = {
        onLocationSelect: PropTypes.func.isRequired,
        onAddressResolve: PropTypes.func.isRequired,
    }

    constructor(props, context) {
        super(props, context)

        const {latitude, longitude} = props.value;


        // This is a HACK!!!!
        const isNewLocation = latitude === 0 && longitude === 0
        const defaultCenter = isNewLocation ? {lat: -33.872, lng: 151.207} : {lat: latitude, lng: longitude}

        this.state = {
            value: props.value,
            isMarkerShown: isNewLocation ? false : true,
            defaultCenter: defaultCenter
        }
    }

    componentDidMount() {
        console.log("trying to get location...?");
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(position => {
                const pos = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                };
                console.log(pos.lat);
                console.log(pos.lng);
            });
        } else {
            console.log("can't get your location my dude...");
        }
    }

    handleMapClick = (event) => {
        const coordinates = event.latLng;
        const latitude = coordinates.lat();
        const longitude = coordinates.lng();
        const apiKey = 'AIzaSyA0mdJv9RMnow7fTCvttJkrNJOhPH8xTTo';

        const {onLocationSelect, onAddressResolve} = this.props;

        this.setState({
            value: {
                latitude: latitude,
                longitude: longitude,
            },
            isMarkerShown: true
        });

        onLocationSelect && onLocationSelect({latitude, longitude});

        fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`)
            .then(data => data.json())
            .then(data => {
                const addressString = data.results[0].formatted_address;
                onAddressResolve && onAddressResolve({
                    latitude: latitude,
                    longitude: longitude,
                    address: addressString,
                })
            });
    }

    render() {

        console.log("this.props.value", this.props.value);
        console.log("this.state", this.state);
        let value = this.state.value;
        const {latitude, longitude} = value;


        return (
            <MapComponent
                isMarkerShown={this.state.isMarkerShown}
                onMapClick={this.handleMapClick}
                longitude={longitude}
                latitude={latitude}
                defaultCenter={this.state.defaultCenter}
            />
        )
    }

}

export default IncidentMapComponent