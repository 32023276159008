import React from 'react';
import { Container, Divider, Header } from 'semantic-ui-react';
import withAuthorization from '../../hocs/withAuthorization';
import AllForms from "../Admin/AllForms/AllForms";
import {connect} from "react-redux";

const AllIncidents = (props) => {
    const { profile } = props;
    return (
        <Container>
            <Header as="h1" className="sectionHeader">
                Incidents
            </Header>
            <AllForms overwriteTeamLeader={profile.firstName + " " + profile.lastName}/>
            <Divider />
        </Container>
    );
};

const mapStateToProps = ({ auth, profile }) => {
    return {
        auth,
        profile,
    };
};

export default withAuthorization('teamLeader')(connect(mapStateToProps)(AllIncidents));
