import React from 'react'
import PropTypes from 'prop-types'
import { List } from 'semantic-ui-react'
import _ from 'lodash'

class FixedValueDisplay extends React.Component {
    static propTypes = {
        value: PropTypes.number.isRequired,
        valueList: PropTypes.array.isRequired,
        headerElement: PropTypes.string.isRequired
    };

    getValueMessage = () => {
        if (_.isNumber(this.props.value)) {
            return this.props.valueList.map(valueItem => {
                if (this.props.value === valueItem.value) {
                    return valueItem.label;
                }
                return '';
            })
        } else {
            return 'No Data';
        }
    };

    render() {
        return (
            <List size='large'>
                <List.Item>
                    <List.Header>{this.props.headerElement}</List.Header>
                    <List.Description>
                    {this.getValueMessage()}
                    </List.Description>
                </List.Item>
            </List>
        )
    }
}
export default FixedValueDisplay