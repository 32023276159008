import React, { Component, useEffect, useState } from 'react';
import { Button, Dimmer, Loader, Table } from 'semantic-ui-react';
import { DateTime } from 'luxon';

import { db } from '../../../firebase';
import ConfirmDeleteReportModal from '../ConfirmDeleteReportModal';

export const ProgramTotalsReports = () => {
  const reportsRef = db.getAllFromReportsNode();

  const [reportSnapshots, setReportSnapshots] = useState([]);
  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);
  const [reportToDeleteId, setReportToDeleteId] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      await fetchReportsDataSnapshot();
      setLoading(false);
      return () => {
        console.log('unmount ProgramTotalsReports');
        unregisterReportsRef();
      };
    })();
  }, []);

  useEffect(() => {
    (async() => {
      await registerReportsAddedOrRemoved();
    })();
  }, [])

  const unregisterReportsRef = () => {
    reportsRef.off();
  };

  const fetchReportsDataSnapshot = async () => {
    const tempReportDataSnapshot = [];
    const reportDataSnapshot = await reportsRef
      .orderByChild('createdDate')
      .once('value');
    if (reportDataSnapshot.exists()) {
      reportDataSnapshot.forEach((snapshot) => {
        tempReportDataSnapshot.push(snapshot);
      });
    }
    // firebase query return ascending createdDate order
    // we want descending order (from latest date)
    tempReportDataSnapshot.reverse();
    setReportSnapshots(tempReportDataSnapshot);
  };

  const registerReportsAddedOrRemoved = async () => {
    await reportsRef.on('value', async (snapshot, prevKey) => {
      await fetchReportsDataSnapshot();
    });
  };


  const handleModalCancel = () => {
    setConfirmDeleteModalOpen(!confirmDeleteModalOpen);
    setReportToDeleteId(null);
  };

  const generateReportRows = () => {
    if (loading) {
      return (
        <Table.Row>
          <Table.Cell colSpan={6} textAlign="center" verticalAlign="middle">
            <Loader active inline content="Loading..." />
          </Table.Cell>
        </Table.Row>
      );
    } else if (reportSnapshots.length === 0) {
      return (
        <Table.Row>
          <Table.Cell textAlign="center" colSpan={6}>
            Currently no spreadsheets to show...
          </Table.Cell>
        </Table.Row>
      );
    } else {
      return reportSnapshots.map((report) => {
        const {
          createdDate,
          reportStartTimestamp,
          reportEndTimestamp,
          reportDescription,
          createdBy,
          reportUrl,
        } = report.val();

        return (
          <Table.Row key={report.key}>
            <Table.Cell>
              {createdDate &&
                DateTime.fromMillis(createdDate).toFormat("EEE',' LLL d y")}
            </Table.Cell>
            <Table.Cell>
              {reportStartTimestamp &&
                DateTime.fromMillis(reportStartTimestamp).toFormat(
                  "EEE',' LLL d y"
                )}
            </Table.Cell>
            <Table.Cell>
              {reportEndTimestamp &&
                DateTime.fromMillis(reportEndTimestamp).toFormat(
                  "EEE',' LLL d y"
                )}
            </Table.Cell>
            <Table.Cell>
              {reportDescription || 'Description not found!'}
            </Table.Cell>
            <Table.Cell>{createdBy || 'Created By not found!'}</Table.Cell>
            <Table.Cell>
              <Button.Group floated="right">
                <Button
                  color="green"
                  icon="file excel outline"
                  labelPosition="left"
                  content="View"
                  onClick={() => {
                    window.open(reportUrl, '_blank');
                  }}
                />
                <Button
                  color="red"
                  icon="trash"
                  labelPosition="left"
                  content="Delete"
                  onClick={() => {
                    setReportToDeleteId(report.key);
                    setConfirmDeleteModalOpen(true);
                  }}
                />
              </Button.Group>
            </Table.Cell>
          </Table.Row>
        );
      });
    }
  };

  return (
    <>
      <Table striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Create Date</Table.HeaderCell>
            <Table.HeaderCell>Start Date</Table.HeaderCell>
            <Table.HeaderCell>End Date</Table.HeaderCell>
            <Table.HeaderCell>Description</Table.HeaderCell>
            <Table.HeaderCell>Created By</Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>{generateReportRows()}</Table.Body>
      </Table>
      <ConfirmDeleteReportModal
        handleCancel={handleModalCancel}
        modalOpen={confirmDeleteModalOpen}
        reportId={reportToDeleteId}
        reportsRef={reportsRef}
      />
    </>
  );
};
