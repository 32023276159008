import React from 'react';
import {
  Button,
  Container,
  Dimmer,
  Grid,
  Header,
  List,
  Icon,
  Loader,
} from 'semantic-ui-react';
import { DateTime } from 'luxon';
import _ from 'lodash';

import { db, functions } from '../../firebase';
import { db as fbDb } from '../../firebase/firebase';
import { withRouter } from 'react-router-dom';
import ToggleAdminModal from './ToggleAdmin';

const INITIAL_STATE = {
  userProfile: {},
  loading: false,
};

class ViewUser extends React.Component {
  constructor(props) {
    super(props);

    this.state = INITIAL_STATE;
    this.userProfileRef = null;
  }

  pushToAdmin = () => {
    this.props.history.goBack();
  };

  componentDidMount() {
    this.setState({ loading: true });
    const { userId } = this.props.match.params;
    if (userId) {
      console.log('UserId', userId);
      this.userProfileRef = db.getUserFromUsersNodeWithUserId(userId);
      this.userProfileRef.on('value', (dataSnapshot) => {
        if (dataSnapshot.exists()) {
          this.setState({
            userProfile: dataSnapshot.val(),
            loading: false,
          });
        } else {
          // kick user to dashboard if could not find what the droi... uhh i mean, profile they were looking for
          this.props.history.replace('/error');
        }
      });
    } else {
      // no userId provided in the url!
      this.props.history.replace('/error');
    }
  }

  unauthenticateUser = async (userId) => {
    const callableFunction = functions.httpsCallable('httpsDeleteUser');
    try {
      await callableFunction({ userId: userId });
    } catch (error) {
      console.log('ERROR DELETING USER!', error);
    }
  };

  handleUserDeleted = async () => {
    const { userId } = this.props.match.params;
    console.log('DELETING USER', userId);

    if (userId) {
      await fbDb.ref('users/' + userId).update(
        {
          accountStatus: 'inactive',
        },
        (error) => {
          if (error) {
            console.log('DISABLED USER FAILED.');
          } else {
            console.log('DISABLED USER SUCCESSFULLY');
          }
        }
      );
      await this.unauthenticateUser(userId);
    }
  };

  componentWillUnmount() {
    // kill the user profile event listener when component is umounted (routed to error url)
    this.userProfileRef.off();
  }

  render() {
    if (!this.state.loading) {
      const {
        firstName,
        lastName,
        email,
        site,
        userLevel,
        lastActive,
        accountStatus,
      } = this.state.userProfile;
      return (
        <Container>
          <Header
            className="sectionHeader"
            as="h1"
            content="View User Profile"
          />
          <Grid relaxed stackable>
            <Grid.Row as={List} columns={3}>
              <Grid.Column as={List.Item}>
                <List.Icon name="user" size="large" verticalAlign="middle" />
                <List.Content>
                  <List.Header>Name</List.Header>
                  <List.Description>
                    {firstName} {lastName}
                  </List.Description>
                </List.Content>
              </Grid.Column>
              <Grid.Column as={List.Item}>
                <List.Icon name="mail" size="large" verticalAlign="middle" />
                <List.Content>
                  <List.Header>Email</List.Header>
                  <List.Description>{email}</List.Description>
                </List.Content>
              </Grid.Column>
              <Grid.Column as={List.Item}>
                <List.Icon
                  name="map marker"
                  size="large"
                  verticalAlign="middle"
                />
                <List.Content>
                  <List.Header>Safe Base</List.Header>
                  <List.Description>{_.startCase(site)}</List.Description>
                </List.Content>
              </Grid.Column>
              <Grid.Column as={List.Item}>
                <List.Icon name="id card" size="large" verticalAlign="middle" />
                <List.Content>
                  <List.Header>User Level</List.Header>
                  <List.Description>{_.startCase(userLevel)}</List.Description>
                </List.Content>
              </Grid.Column>
              <Grid.Column as={List.Item}>
                <List.Icon name="clock" size="large" verticalAlign="middle" />
                <List.Content>
                  <List.Header>Last Active</List.Header>
                  <List.Description>
                    {DateTime.fromMillis(Math.floor(lastActive)).toLocaleString(
                      DateTime.DATETIME_FULL
                    ) || 'No Data'}
                  </List.Description>
                </List.Content>
              </Grid.Column>
              <Grid.Column as={List.Item}>
                <List.Icon name="unlock" size="large" verticalAlign="middle" />
                <List.Content>
                  <List.Header>Account Status</List.Header>
                  <List.Description>
                    {_.capitalize(accountStatus)}
                  </List.Description>
                </List.Content>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row centered>
              <Button.Group as={Button.Group} widths={4}>
                <Button // Did not use as={Link} as this causes the <Button> to be rendered as an <a> and the text in the button will be shifted up
                  animated="vertical"
                  color="black"
                  onClick={this.pushToAdmin}
                >
                  <Button.Content visible>Back</Button.Content>
                  <Button.Content hidden>
                    <Icon name="undo" />
                  </Button.Content>
                </Button>
                <ToggleAdminModal
                  userId={this.props.match.params.userId}
                  userLevel={userLevel || ''}
                  firstName={firstName || ''}
                  lastName={lastName || ''}
                />
                <Button animated="vertical" color="blue">
                  <Button.Content visible>Reset Email</Button.Content>
                  <Button.Content hidden>
                    <Icon name="mail" />
                  </Button.Content>
                </Button>
                <Button
                  onClick={this.handleUserDeleted}
                  animated="vertical"
                  color="red"
                >
                  <Button.Content visible>Delete User</Button.Content>
                  <Button.Content hidden>
                    <Icon name="ban" />
                  </Button.Content>
                </Button>
              </Button.Group>
            </Grid.Row>
            <Grid.Row />
          </Grid>
        </Container>
      );
    } else {
      return (
        <Dimmer active>
          <Loader>Loading Profile...</Loader>
        </Dimmer>
      );
    }
  }
}

export default withRouter(ViewUser);
