import React, {Component} from 'react'
import { connect } from 'react-redux'
import { initialize as initializeAction } from 'redux-form'
import _ from 'lodash';

import IncidentForm from './IncidentForm'
import { db } from '../../firebase/index';
import {Redirect} from "react-router-dom";

class IncidentEdit extends Component {

    async componentDidMount() {
        window.scrollTo(0, 0);

        if ( _.has(this.props.location.state, "incidentId") ) {
            const { initializeAction } = this.props;
            const { incidentId } = this.props.location.state;

            const incidentIdExists = await db.incidentFormExists(incidentId);
            if (incidentIdExists) {
                const formReference = db.getIncidentFormRefFromIncidentFormNodeWithIncidentFormId(incidentId);
                formReference.once('value').then(snapshot => {
                    // const data = _.omit(formData.val(), 'clientList');
                    const formData = snapshot.val();
                    // console.log("Edit Form data: ", data);
                    initializeAction("incident", formData)
                })
            } else {
                this.props.history.replace("/error", { error: "IncidentId doesn't exist on firebase.", from: this.props.match.url, incidentId: incidentId });
            }
        } else {
            this.props.history.replace("/error", { error: "Expecting Client ID", from: this.props.match.url });
        }
    };

    render() {
        // check to make sure that react router has an incidentId before attempting to fetch
        // the incident and render. This should really be a HOC...
        if ( _.has(this.props.location.state, "incidentId") ) {
            return (
                <div>
                    <IncidentForm
                        isNew={false}
                        incidentId={this.props.location.state.incidentId}
                        editFrom={this.props.location.state.editFrom || null}
                    />
                </div>
            )
        } else {
            return <Redirect to={{ pathname: '/error', state: { error: "Expecting Incident ID and From", from: this.props.match.url }} } />
        }
    }
}

const mapStateToProps = ({ auth, profile }) => {
    return {
        auth,
        profile
    }
};

// 'initializeAction' is a redux action creator provided by "redux-form" library.
// by executing this function, the form data will be cleared and filled with the provided data.
export default connect(mapStateToProps, {initializeAction: initializeAction})(IncidentEdit)
