import React from 'react';
import { Container, Header, Card } from 'semantic-ui-react';
import _ from 'lodash';

import withAuthorization from '../../hocs/withAuthorization';
import ReportTypeCard from './ReportTypeCard';
import reportingTypes from './reportingTypes';

const Reports = () => {
    return (
        <Container>
            <Header as='h1' className='sectionHeader'>Reports</Header>
            <Card.Group centered>
                { _.map(reportingTypes, (value, key) => (
                    <ReportTypeCard
                        key={key}
                        reportingTypeName={value.name}
                        description={value.description}
                        url={value.url}
                    />
                )) }
            </Card.Group>
        </Container>
    )
};
export default withAuthorization('admin')(Reports)